import * as Sentry from '@sentry/react';
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    Sentry.withScope(scope => {
      scope.setExtras(error);
      Sentry.captureException(error);
      this.setState({
        hasError: true,
        error,
        info,
      });
    });
    this.setState({
      hasError: true,
      error,
      info,
    });
  }

  render() {
    const { hasError, error, info } = this.state;
    const { children, component: Component } = this.props;

    if (hasError) {
      if (error) {
        console.log('Error - ', error.toString());
      }

      console.log('Error Info', info);
      console.log('Component Stack - ', info.componentStack);

      Sentry.captureException(error);
      return <Component />;
    }
    return children;
  }
}

export default ErrorBoundary;
