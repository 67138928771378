export const GET_WALLET_TRANSACTIONS = 'WALLET/GET_WALLET_TRANSACTIONS';
export const GET_WALLET_TRANSACTIONS_SUCCESSFUL =
  'WALLET/GET_WALLET_TRANSACTIONS_SUCCESSFUL';
export const GET_WALLET_TRANSACTIONS_FAILURE =
  'WALLET/GET_WALLET_TRANSACTIONS_FAILURE';
export const RELOAD_WALLET_TRANSACTIONS = 'WALLET/RELOAD_WALLET_TRANSACTIONS';
export const RELOAD_WALLET_TRANSACTIONS_SUCCESSFUL =
  'WALLET/RELOAD_WALLET_TRANSACTIONS_SUCCESSFUL';
export const RELOAD_WALLET_TRANSACTIONS_FAILURE =
  'WALLET/RELOAD_WALLET_TRANSACTIONS_FAILURE';
export const SET_WALLET_LOADING_STATE = 'WALLET/SET_WALLET_LOADING_STATE';
