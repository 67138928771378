import 'whatwg-fetch';
import { sanitizeStringifiedObject } from './helpers';
import { API_BASE_URL } from 'utils/constants';
import { IS_PRODUCTION } from './constants';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
const parseJSON = (response) => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

export default function request(
  url,
  options = {},
  callback = r => r,
  credentials = true
) {
  if (credentials || options?.credentials === 'include') {
    options.credentials = 'include';
  }
  if(options?.body && options?.method === "POST") {
    options.body = sanitizeStringifiedObject(options.body);
  }

  // debugger;
  options.headers = {
    ...options.headers,
    apiVersion: '5',
  };

  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .then(callback);
}

export const getRandomKey = async object => {
  let url = encodeURI(`${API_BASE_URL}/rest/user/random`);
  let requestBody = JSON.stringify({...object});
  try {
    const response = await request(url, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        apiVersion: '5',
      },
      body: requestBody,
    });
    return response;
  } catch (error) {
    if (IS_PRODUCTION) {
      console.log(error);
    }
    return null;
  }
};

export const handleEncryption = (data, publicKey) => {
  if(window?.JSEncrypt) {
    const { JSEncrypt } = window;
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(publicKey, 'base64');
    return jsEncrypt.encrypt(data);
  }
}