import * as Sentry from '@sentry/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userState = useSelector(({ user }) => user?.userState);
  const profile = useSelector(({ shop }) => shop?.profile);
  const { isAuthenticated, isLoggedOut, isSigningIn } = userState;
  const shopUrl = userState?.url || profile?.url || '/';

  const logoutRedirection = () => {
    window.location.href = shopUrl;
  }

  return (
    <Route
      {...rest}
      render={routeProps => {
        try {
          if (Component) {
            if (userState?.url) {
              if (isAuthenticated && isSigningIn) {
                window.location.href = localStorage ? localStorage.getItem('prevRoute') : userState?.url || profile?.url || '/';
                return null;
              }
              if (isAuthenticated) {
                return <Component {...rest} {...routeProps} />;
              }
              if (!isAuthenticated && isLoggedOut) {
                // return <Redirect to={shopUrl} />;
                logoutRedirection();
              }

              if (!isAuthenticated && !isLoggedOut) {
                return (
                  <Redirect
                    to={{
                      pathname: '/signin',
                      state: { from: routeProps.location },
                    }}
                  />
                );
              }
            }
            // else {
            //   return (
            //     <Redirect
            //       to={{
            //         pathname: '/signin',
            //         state: { from: routeProps.location },
            //       }}
            //     />
            //   );
            // }
          }
        } catch (ex) {
          console.log('exception: ->', ex);
          Sentry.captureException(ex);
        }
      }}
    />
  );
};

export default PrivateRoute;
