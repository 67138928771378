import styled from 'styled-components';
import { IconButton } from 'ui-components/Buttons';
import { Flex, MediaQ } from 'ui-utils';

export const Wrapper = styled.div`
  position: relative;
  align-self: flex-end;
  width: 100%;
  background: white;
  border-radius: 0px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  overflow: hidden;
  ${MediaQ('768px')`
    align-self: center;
    width: 45%;
    max-width: 50%;
    border-radius: 4px;
  `}
`;

export const ModalTitle = styled.div`
  ${Flex('row nowrap', 'center', 'space-between')};
  padding: 0.5rem;
  p {
    font-size: 1rem;
    color: #2e2e2e;
  }
  button {
    padding: 0;
    text-decoration: underline;
    font-size: 14px;
    border: none;
    cursor: pointer;
  }
`;

export const CartCardWrapper = styled.div``;

export const ModalCta = styled.div`
  padding: 0.5rem;
  button {
    height: 48px;
  }
  ${MediaQ('768px')`
    padding: 0;
    button {
        border-radius: 0;
    }
  `}
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  ${Flex('row nowrap', 'flex-start', 'flex-end')}
`;
