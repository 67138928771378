// @flow

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  *,::before,::after {
    box-sizing: border-box;
    font-family: "Open Sans",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  
  html {
    font-size: 16px;
    width: 100%;
  }

  // ::-webkit-scrollbar {
  //   width: 0px; /* remove scrollbar space */
  //   background: transparent; /* optional: just make scrollbar invisible */
  //   -ms-overflow-style: none;
  // }
    
  // optional: show position indicator in red */
  // ::-webkit-scrollbar-thumb {
  //   background: #FF0000;
  // }

  :-webkit-autofill {
    background: white;
  }

  body {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
    font-family: "Open Sans",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    width: 100%;
    overflow-x: hidden;
  }

  p,h1,h2,h3,h4,h5,h6,a,button {
    margin: 0;
  }

  a {
      color: #2e2e2e;
      text-decoration: none;
      &:hover {
          text-decoration: none;
      }
      &:visited {
          color: #2e2e2e;
      }
  }

  li {
      list-style-type: none;
  }
  li.list-disc{
    list-style-type: disc;
  }
 

  ul {
      padding: 0;
  }

  button {
    background: none;
    border: 1px solid #ddd;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #9397a3;
    font-size: 12px;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #9397a3;
    font-size: 12px;
  }

  ::placeholder {
    color: #9397a3;
    font-size: 12px;
  }

  @keyframes placeHolderShimmer{
    0%{
        background-position: -400px 0;
    }
    100%{
        background-position: 400px 0;
    }
  }

  @keyframes spin{
    0%{
      transform: rotate(0deg);
      transition: transform 300ms ease-in-out;
    }
    50% {
      transform: rotate(180deg);
    }
    100%{
      transform: rotate(360deg);
    }
  }

  
  .animated-background {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
  }

  .fluid-height {
    height: 100%;
  }

  .fluid-width {
    width: 100%;
  }
  
  .flex {
    display: flex;
    flex-direction: row;
  }

  .flex-row {
    flex-direction: row;
  }

  .flex-col {
    flex-direction: column;
  }

  .centered {
    justify-content: center;
    align-items: center;
  }

  .end {
    justify-content: flex-end;
  }

  .start {
    justify-content: flex-start;
  }

  .top {
    align-items: flex-start;
  }
  
  .bottom {
    align-items: flex-end;
  }

  .relative {
    position: relative;
  }
  
  .page-wrapper {
    padding-top: ${props => props.theme.layout.headerHeight};
  }

  .checkout-page-wrapper {
    padding: ${props => props.theme.layout.headerHeight} 0;
  }

  #appMain {
    height: 100%;
  }

  .no-padding {
    padding: 0;
  }

  `;
