export const kbLayout = {
    default: [
      "q w e r t y u i o p",
      "a s d f g h j k l",
      "{shift} z x c v b n m {bksp}",
      "{alt} , {space} . {enter}"
    ],
    shift: [
      "Q W E R T Y U I O P",
      "A S D F G H J K L ",
      "{shiftactivated} Z X C V B N M {bksp}",
      "{alt} , {space} . {enter}"
    ],
    alt: [
      "1 2 3 4 5 6 7 8 9 0",
      `@ # $ & * ( ) ' "`,
      "% - + = / ; : ! ? {bksp}",
      "{default} , {space} . {enter}"
    ],
  };
  
  export const kbDisplay = {
    "{alt}": "?123",
    "{shift}": "⇧",
    "{shiftactivated}": "⇧",
    "{enter}": "↵",
    "{bksp}": "⌫",
    "{altright}": ".?123",
    "{downkeyboard}": "🞃",
    "{space}": " ",
    "{default}": "ABC",
    "{back}": "⇦"
  };