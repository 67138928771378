import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Flex, MediaQ } from 'ui-utils/index';

export const Wrapper = styled.nav`
  ${tw`
    w-full h-bottomNav bg-white max-w-4xl fixed top-0 mx-auto border-b border-solid border-gray-200 z-toast
  `};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  
  ${MediaQ('768px')`
  `}
  .container-fluid {
    height: 100%;
    margin: 0;
  }
  .NavigationLoading {
    height: 100%;
    // ${Flex('row nowrap', 'center', 'center')}
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
  margin: 0 auto;
  ${Flex('row nowrap', 'center', 'space-between')}
  position:relative;
`;

export const HeaderButtons = styled.div`
  ${tw`min-w-48px ml-2`}
  width: auto;
  position: relative;
  height: 100%;
  ${Flex('row nowrap', 'center', 'flex-end')}
`;
