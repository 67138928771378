export const GET_ORDERS = 'ORDERS/GET_ORDERS';
export const GET_ORDERS_SUCCESSFUL = 'ORDERS/GET_ORDERS_SUCCESSFUL';
export const GET_ORDERS_FAILURE = 'ORDERS/GET_ORDERS_FAILURE';
export const RELOAD_ORDERS = 'ORDERS/RELOAD_ORDERS';
export const RELOAD_ORDERS_SUCCESSFUL = 'ORDERS/RELOAD_ORDERS_SUCCESSFUL';
export const RELOAD_ORDERS_FAILURE = 'ORDERS/RELOAD_ORDERS_FAILURE';
export const SET_ORDERS_LOADING_STATE = 'ORDERS/SET_ORDERS_LOADING_STATE';
export const GET_ORDER_DETAILS = 'ORDERS/GET_ORDER_DETAILS';
export const GET_ORDER_DETAILS_SUCCESSFUL =
  'ORDERS/GET_ORDER_DETAILS_SUCCESSFUL';
export const GET_ORDER_DETAILS_FAILURE = 'ORDERS/GET_ORDER_DETAILS_FAILURE';
export const SET_ORDER_HELP_DETAILS = 'ORDERS/SET_ORDER_HELP_DETAILS';
export const SET_ORDER_HELP_PROBLEM = 'ORDERS/SET_ORDER_HELP_PROBLEM';
export const GET_CANCEL_ORDER_REASONS = 'ORDERS/GET_CANCEL_ORDER_REASONS';
export const GET_CANCEL_ORDER_REASONS_SUCCESSFUL =
  'ORDERS/GET_CANCEL_ORDER_REASONS';
export const GET_CANCEL_ORDER_REASONS_FAILURE =
  'ORDERS/GET_CANCEL_ORDER_REASONS';
export const GET_RETURN_ORDER_REASONS = 'ORDERS/GET_RETURN_ORDER_REASONS';
export const GET_RETURN_ORDER_REASONS_SUCCESSFUL =
  'ORDERS/GET_RETURN_ORDER_REASONS';
export const GET_RETURN_ORDER_REASONS_FAILURE =
  'ORDERS/GET_RETURN_ORDER_REASONS';
export const CANCEL_ORDER = 'ORDERS/CANCEL_ORDER';
export const UPLOAD_RETURN_ORDER_IMAGE = 'ORDERS/UPLOAD_RETURN_ORDER_IMAGE';
export const DELETE_RETURN_ORDER_IMAGE = 'ORDERS/DELETE_RETURN_ORDER_IMAGE';
export const ADD_RETURN_IMAGE = 'ORDERS/ADD_RETURN_IMAGE';
export const DELETE_RETURN_IMAGE = 'ORDERS/DELETE_RETURN_IMAGE';
export const CLEAR_RETURN_IMAGE = 'ORDERS/CLEAR_RETURN_IMAGE';
export const SET_IMAGE_LOADING_STATE = 'ORDERS/SET_IMAGE_LOADING_STATE';
export const GET_BANK_DETAILS = 'ORDERS/GET_BANK_DETAILS';
export const SET_BANK_DETAILS = 'ORDERS/GET_BANK_DETAILS';
export const SAVE_COD_REFUND_DETAILS = 'ORDERS/SAVE_COD_REFUND_DETAILS';
export const RETURN_ORDER = 'ORDERS/RETURN_ORDER';
export const RETURN_ORDER_POPUP_TOGGLE = 'ORDERS/RETURN_ORDER_POPUP_TOGGLE';
