import * as Sentry from '@sentry/react';
import { setHomeLoadingState } from 'config/actions';
// import { analytics } from 'config/firebase';
import { loadUserState } from 'config/sagas';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { loadCategoriesSuccess } from 'screens/Categories/actions';
// import { URLSearchParams } from 'url';
import {
  API_BASE_URL,
  HOMEPAGE_STATES,
  LOAD_HOME_PAGE_FEED_SUCCESS,
  LOAD_MAIN_SHOP_FAILED,
  LOAD_NEW_SHOP,
  RELOAD_HOME_PAGE_FEED,
  RELOAD_HOME_PAGE_FEED_SUCCESS,
  UPDATE_HOME_PAGE_FEED_PRODUCT,
  UPDATE_HOME_PAGE_FEED_PRODUCT_SUCCESS,
  UPDATE_SHOPLANDING_PRODUCTS,
  UPDATE_SHOPLANDING_PRODUCTS_SUCCESS,
} from 'utils/constants';
import {
  getPage,
  getPageLoadSpeed,
  getRequestOptionsForPage,
} from 'utils/helpers';
import request from 'utils/request';

// import { homepageStates } from '.';

function* getFlickstreeSession({ clickId, partnerId, shopId }) {
  yield console.log({ clickId, partnerId, shopId });

  const URL = `${API_BASE_URL}/rest/flickstree/session`;
  const body = JSON.stringify({
    shopId: parseInt(shopId, 10),
    clickId,
    partnerId,
  });

  try {
    const response = yield call(request, URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    console.log(response);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
}

function* loadNewShop({ payload }) {
  // debugger;
  yield put(setHomeLoadingState({ homeLoadingState: 'loading' }));

  const { history, params } = payload;

  try {
    const feedRequestOptions = yield call(getRequestOptionsForPage, 'shop', {
      pageNo: payload.homeFeedPageNo || 1,
      sortBy: 'recency',
      shopName: params.shopName,
      token: params.token,
      virtualOffset: params.virtualOffset,
      shopUrl: params.shopUrl,
    });

    const FETCH_FEED_URL = `${API_BASE_URL}${feedRequestOptions.url}`;

    // Home Feed api call
    const apiStartTime = yield call(Date.now);
    const feedResponse = yield call(request, FETCH_FEED_URL, {
      ...feedRequestOptions,
      method: 'GET',
    });
    const apiStopTime = yield call(Date.now);
    const shopListingApiDuration = apiStopTime - apiStartTime;

    console.log(
      `shopListingApi Duration `,
      `${shopListingApiDuration / 1000}s`
    );

    yield put(setHomeLoadingState({ homeLoadingState: 'loaded' }));

    const { data: feedData, status: feedStatus, redirectUrl } = feedResponse;

    const updatedShopData = {};

    if (feedStatus === 'success') {
      updatedShopData.homeFeedProducts = feedData.resellingProducts;
      updatedShopData.homeFeedPageNo = 1;
      updatedShopData.homeFeedTotalProducts = feedData.totalProducts;
      updatedShopData.profile = feedData?.shop;
      updatedShopData.shop = feedData?.shop;
      updatedShopData.banners = feedData.banners;
      updatedShopData.cashBack = feedData.cashBack;
      updatedShopData.stores = feedData.stores;
      if (feedData.resellingProducts.length > 0) {
        updatedShopData.isScrollCompleted = false;
      } else {
        updatedShopData.isScrollCompleted = false;
      }

      if (feedData.hasNextPage) {
        updatedShopData.isScrollCompleted = false;
      } else {
        updatedShopData.isScrollCompleted = true;
      }

      if (feedData.virtualOffset) {
        updatedShopData.virtualOffset = feedData.virtualOffset;
      }

      if (params.scrollTop !== 0) {
        updatedShopData.scrollTop = params.scrollTop;
      } else {
        updatedShopData.scrollTop = 0;
      }

      const page = yield call(getPage, window.location);
      if (feedData.shop.url && page === 'newShop') {
        yield call(history.replace, `${feedData.shop.url}${window?.location?.search ?? ''}`);
      }

      if (feedData.shop.url && page === 'search') {
        yield call(history.replace, `${feedData.shop.url}/categories`);
      }

      yield put({
        type: LOAD_HOME_PAGE_FEED_SUCCESS,
        payload: {
          shop: updatedShopData,
        },
      });
      yield put(loadCategoriesSuccess(feedData));

      yield* loadUserState({
        payload: { params: { shopName: '/', sortBy: 'recency' } },
      });

      if (params.click_id && params.partner_id && feedData?.shop?.id) {
        yield* getFlickstreeSession({
          clickId: params.click_id,
          partnerId: params.partner_id,
          shopId: feedData.shop.id,
        });
      }

      // Handle analytics events
      if (window.localStorage) {
        const lqid = window?.localStorage?.getItem('lqid') || '';
        localStorage.setItem('shopUrl', feedData.shop.url);

        if (feedData && feedData.shop) {
          const { shop } = feedData;
          const source = window.matchMedia('(display-mode: standalone)').matches
            ? 'pwa'
            : 'web';

          // yield call(window.clevertap.event.push, 'msp_shop_loaded', {
          //   version: '2',
          //   name: shop?.name,
          //   url: shop?.url,
          //   productCount: shop?.count,
          //   id: shop?.id,
          //   source,
          //   uniqueId: lqid,
          //   domain: window.location.origin,
          // });
        }
      }
    }

    if (window.pageSpeedTimer) {
      const duration = yield call(getPageLoadSpeed);
      window.pageSpeedTimer = null;

      // yield call(analytics.logEvent, 'msp_load_time', {
      //   shop: params.shopName,
      //   pageLoadDuration: Number(duration),
      //   shopListingApiDuration: shopListingApiDuration / 1000,
      //   didLoad: true,
      //   domain: window.location.origin,
      // });
    }

    if (redirectUrl) {
      yield call(history.replace, redirectUrl);
    }
  } catch (error) {
    if (window.pageSpeedTimer) {
      const duration = getPageLoadSpeed();
      window.pageSpeedTimer = null;
      console.log({ duration });
      // yield call(analytics.logEvent, 'msp_load_time', {
      //   shop: params.shopName,
      //   duration,
      //   didLoad: false,
      //   domain: window.location.origin,
      // });
    }

    console.log(error);
    yield put({
      type: LOAD_MAIN_SHOP_FAILED,
    });
    Sentry.captureException(error);
    yield put(setHomeLoadingState({ homeLoadingState: 'loaded' }));
    // yield call(history.replace, { pathname: '/notfound' });
  }
}

function* reloadHomeFeed({ payload }) {
  // debugger;
  const params = {};
  params.pageNo = payload.homeFeedPageNo;
  params.shopName = payload.shopName;
  params.sortBy = 'recency';
  params.token = payload.token;
  params.virtualOffset = payload.virtualOffset;

  yield put(
    setHomeLoadingState({ homeLoadingState: HOMEPAGE_STATES.reloadingFeed })
  );

  const page = 'shop';

  const requestOptions = getRequestOptionsForPage(page, params);

  const FEED_URL = `${API_BASE_URL}${requestOptions.url}`;

  const feedResponse = yield call(request, FEED_URL, {
    ...requestOptions,
    method: 'GET',
  });

  // yield put(
  //   setHomeLoadingState({
  //     homeLoadingState: HOMEPAGE_STATES.loaded,
  //   })
  // );

  if (feedResponse.status === 'success') {
    yield put({
      type: RELOAD_HOME_PAGE_FEED_SUCCESS,
      payload: {
        shop: { ...feedResponse.data, totalProducts: payload?.totalProducts },
      },
    });
  }
}

function* updateHomeFeedProductUpdate({ payload }) {
  yield put({
    type: UPDATE_HOME_PAGE_FEED_PRODUCT_SUCCESS,
    payload,
  });
}

function* updateShopLandingProducts({ payload }) {
  yield put({
    type: UPDATE_SHOPLANDING_PRODUCTS_SUCCESS,
    payload,
  });
}

function* updateScrollPosition({ payload }) {
  // debugger;
  yield put({
    type: 'shop/UPDATE_SCROLL_POSITION_SUCCESS',
    payload,
  });
}

export function* homeNewSagaWatcher() {
  yield takeEvery(LOAD_NEW_SHOP, loadNewShop);
  yield takeEvery(RELOAD_HOME_PAGE_FEED, reloadHomeFeed);
  yield takeEvery(UPDATE_HOME_PAGE_FEED_PRODUCT, updateHomeFeedProductUpdate);
  yield takeEvery(UPDATE_SHOPLANDING_PRODUCTS, updateShopLandingProducts);
  yield takeLatest('shop/UPDATE_SCROLL_POSITION', updateScrollPosition);
}

// export function* watchNewLoadShopStateSaga() {
//   yield takeEvery(LOAD_NEW_SHOP, loadShop);
// }

// export function* watchReloadHomeFeed() {
//   yield takeEvery(RELOAD_HOME_PAGE_FEED, reloadHomeFeed);
// }

// export function* watchUpdateHomeFeedProductUpdate() {
//   yield takeEvery(UPDATE_HOME_PAGE_FEED_PRODUCT, updateHomeFeedProductUpdate);
// }

// export function* watchUpdateShopLandingProducts() {
//   yield takeEvery(UPDATE_SHOPLANDING_PRODUCTS, updateShopLandingProducts);
// }
