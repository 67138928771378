import * as Sentry from '@sentry/react';
import {
  loadShopStateSuccessAction,
  setListingFirstLoadState,
  setLoadingState,
  showShopSidebarAction,
} from 'config/actions';
// import { analytics } from 'config/firebase';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  showAttributesFilterAction,
  showSortAction,
  toggleSidebarAction,
} from 'screens/Home/actions';
// import { URLSearchParams } from 'url';
import {
  API_BASE_URL,
  CLEAR_SHOP_DATA,
  CLEAR_SHOP_DATA_SUCCESS,
  LOAD_MAIN_SHOP,
  LOAD_MAIN_SHOP_FAILED,
  RELOAD_EXISTING_SHOP,
  RELOAD_MAIN_SHOP,
  RELOAD_MAIN_SHOP_FAILED,
  RELOAD_MAIN_SHOP_SUCCESS,
  SET_SHOP_SEARCH_TEXT,
  SET_SORT_BY,
  SET_SORT_BY_SUCCESS,
  TOGGLE_ATTRIBUTES_FILTER,
  TOGGLE_ATTRIBUTES_FILTER_SUCCESS,
  TOGGLE_SORT,
  TOGGLE_SORT_SUCCESS,
} from 'utils/constants';
import { getPage, getRequestOptionsForPage } from 'utils/helpers';
import request from 'utils/request';

function* loadShop({ payload }) {
  yield put(toggleSidebarAction(false));
  yield put(showAttributesFilterAction(false));
  yield put(showSortAction(false));

  // debugger;

  if (payload.doNotShowFacet) {
    yield put(setLoadingState({ loadingState: 'loadingWithoutFacet' }));
  } else {
    yield put(setLoadingState({ loadingState: 'loading' }));
  }

  const { history, params } = payload;

  try {
    const page = yield call(getPage, history.location);
    const { searchText } = params;
    const requestOptions = yield call(getRequestOptionsForPage, page, params);
    // debugger;
    const url = `${API_BASE_URL}${requestOptions.url}`;
    const shopStateData = yield call(request, url, {
      ...requestOptions,
      method: 'GET',
    });
    const { data, status } = shopStateData;

    if (searchText) {
      if (data && data.shop) {
        data.shop.searchText = searchText;
      }
    }

    console.log({ data });

    if (status === 'success') {
      yield put(setListingFirstLoadState({ listingFirstLoadState: true }));

      if (data?.shop?.searchText && data?.totalProducts === 0) {
        console.log('no results');
      }

      if (data?.resellingProducts?.length > 0) {
        yield put(setLoadingState({ loadingState: 'loaded' }));
      } else {
        yield put(setLoadingState({ loadingState: 'noProducts' }));
      }

      yield put(
        loadShopStateSuccessAction({
          shop: {
            ...data,
            resellingProducts: data?.resellingProducts,
          },
        })
      );
    } else {
      // console.log(page);
      if (page === 'share') {
        // use a selector to check if state exists in which case redirect to
      } else {
        yield put({
          type: LOAD_MAIN_SHOP_FAILED,
        });
        yield call(history.replace, { pathname: '/notfound' });
      }
    }
  } catch (error) {
    console.log(error);
    Sentry.captureException(new Error(error));
    yield put({
      type: LOAD_MAIN_SHOP_FAILED,
    });
    // Once data is loaded turn off loader & facets
    yield put(setLoadingState({ loadingState: 'loaded' }));
    yield call(history.replace, { pathname: '/notfound' });
  }
}

function* reloadShop({ payload }) {
  // console.log('Reloading Shop...');
  // debugger;
  yield put(setLoadingState({ loadingState: 'reloading' }));
  // yield put(toggleSidebarAction(false));
  // yield put(showAttributesFilterAction(false));
  // yield put(showSortAction(false));

  const { history, params } = payload;

  // Fetch shop data
  try {
    const page = yield call(getPage, history.location);
    const { searchText } = params;
    const requestOptions = yield call(getRequestOptionsForPage, page, params);
    const url = `${API_BASE_URL}${requestOptions.url}`;
    // debugger;
    const shopStateData = yield call(request, url, {
      ...requestOptions,
      method: 'GET',
    });

    const { data, status } = shopStateData;

    if (searchText) {
      if (data.shop) {
        data.shop.searchText = searchText;
      }
    }

    // Once data is loaded turn off loader & facets
    // yield put(showShopReloadingAction({ isShopReloading: false }));

    // yield put(setLoadingState({ loadingState: 'loaded' }));
    if (status === 'success') {
      yield put({
        type: RELOAD_MAIN_SHOP_SUCCESS,
        payload: {
          shop: data,
        },
      });
      yield put(setLoadingState({ loadingState: 'loaded' }));
      // if (window.localStorage) {
      //   const lqid = window?.localStorage?.getItem('lqid');
      //   if (lqid) {
      //     window.clevertap.event.push('msp shop reload - next page', {
      //       url: data?.shop?.url,
      //       name: data?.shop?.name,
      //       uniqueId: lqid,
      //     });
      //   }
      // }
    } else {
      yield put({
        type: RELOAD_MAIN_SHOP_FAILED,
      });
    }
  } catch (error) {
    // console.log(error);
    Sentry.captureException(new Error(error));
    // yield showShopLoadingAction({ isShopLoading: false });
    yield put(setLoadingState({ loadingState: 'loaded' }));
    yield put({
      type: LOAD_MAIN_SHOP_FAILED,
    });
  }
}

function* reloadExistingShop({ payload }) {
  // yield console.log('reloadExistingShop');
}

const stripCategoryName = url => {
  const [shopName] = url.split('/').slice(1);
  return `/${shopName}/shop/all`;
};

// Set Search Text and trigger page load
function* setSearchText({ payload }) {
  const { history, location, searchText } = payload;
  const page = getPage(location);
  const fixedSearchParams =
    page === 'shopAll'
      ? new URLSearchParams(location.search)
      : new URLSearchParams();
  if (fixedSearchParams.has('q')) {
    fixedSearchParams.set('q', searchText);
  } else {
    fixedSearchParams.append('q', searchText);
  }
  // if (page === 'categories') {
  //   console.log('~~~~~');
  //   console.log(location.pathname);
  //   console.log(fixedSearchParams.toString());
  // }

  // debugger;

  const options = {
    pathname: stripCategoryName(location.pathname),
    search: fixedSearchParams.toString(),
  };

  // if (window.localStorage) {
  //   const lqid = window?.localStorage?.getItem('lqid');
  //   if (lqid) {
  //     window.clevertap.event.push('msp search_fired', {
  //       searchText,
  //       uniqueId: lqid,
  //     });
  //   }
  // }

  // console.log(`LOG ->: function*setSearchText -> options`, options);
  // console.log(options);
  // yield call(history.push, options);
}

function* setNewSearchText({ payload }) {
  const { history, text, url } = payload;
  try {
    const page = yield call(getPage, history.location);

    const params = { searchText: text };

    const requestOptions = yield call(getRequestOptionsForPage, page, params);
    // call listing api with search text
    const requestUrl = `${API_BASE_URL}${url}/listing?type=shop&sortBy=recency&pageNo=1&q=styl&q=${text}`;
    const res = yield call(request, requestUrl, {
      ...requestOptions,
      method: 'GET',
    });
    // console.log(`LOG ->: function*setNewSearchText -> res`, res);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
}

function* toggleAttributesFilter({ payload }) {
  const { isShowAttributesFilter } = payload;

  // if (window.localStorage) {
  //   const lqid = window?.localStorage?.getItem('lqid');
  //   if (lqid) {
  //     window.clevertap.event.push('msp filters opened', {
  //       uniqueId: lqid,
  //     });
  //   }
  // }

  yield put({
    type: TOGGLE_ATTRIBUTES_FILTER_SUCCESS,
    payload: {
      isShowAttributesFilter,
    },
  });
}

function* toggleSort({ payload }) {
  const { isShowSort } = payload;

  // if (window.localStorage) {
  //   const lqid = window?.localStorage?.getItem('lqid');
  //   if (lqid) {
  //     window.clevertap.event.push('msp sort opened', {
  //       uniqueId: lqid,
  //     });
  //   }
  // }

  yield put({
    type: TOGGLE_SORT_SUCCESS,
    payload: {
      isShowSort,
    },
  });
}

function* setSortBy({ payload }) {
  const { sortBy } = payload;

  // if (window.localStorage) {
  //   const lqid = window?.localStorage?.getItem('lqid');
  //   if (lqid) {
  //     window.clevertap.event.push('msp sort by', {
  //       uniqueId: lqid,
  //       sortBy,
  //     });
  //   }
  // }

  yield put({
    type: SET_SORT_BY_SUCCESS,
    payload: {
      sortBy,
    },
  });
}

export function* clearShop() {
  yield put({
    type: CLEAR_SHOP_DATA_SUCCESS,
  });
}

export function* watchLoadShopStateSaga() {
  yield takeEvery(LOAD_MAIN_SHOP, loadShop);
  yield takeEvery(CLEAR_SHOP_DATA, clearShop);
}

export function* watchReloadShopStateSaga() {
  yield takeEvery(RELOAD_MAIN_SHOP, reloadShop);
}

export function* watchReloadExistingShop() {
  yield takeEvery(RELOAD_EXISTING_SHOP, reloadExistingShop);
}

export function* watchToggleSort() {
  yield takeLatest(TOGGLE_SORT, toggleSort);
}

export function* watchSetSortBy() {
  yield takeLatest(SET_SORT_BY, setSortBy);
}

export function* watchToggleAttributesFilter() {
  yield takeLatest(TOGGLE_ATTRIBUTES_FILTER, toggleAttributesFilter);
}

export function* watchSetSearchText() {
  yield takeLatest(SET_SHOP_SEARCH_TEXT, setSearchText);
}

export function* watchNewSetSearchText() {
  yield takeLatest('SET_NEW_SEARCH_TEXT', setNewSearchText);
}
