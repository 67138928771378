import React from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'ui-utils';

export const Spinner = styled.div`
  width: 3rem;
  height: 3rem;
  border: 4px solid ${props => props.theme.colors.borderColor};
  border-top: 4px solid #eee;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  animation: spin 800ms linear forwards infinite;
`;

export const Overlay = styled.div`
  ${props => css`
    height: ${props.height ? props.height : '100%'};
    width: ${props.width ? props.width : '100%'};
  `}

  ${Flex('row nowrap', 'center', 'center')}
  padding: 1rem 1rem ${props => props.paddingBottom};

  ${props =>
    props.isRelative
      ? css`
          position: relative;
          margin-bottom: ${props.marginBottom};
          ${Spinner} {
            border: 4px solid ${props.theme.colors.red};
            border-top: 4px solid #eee;
          }
        `
      : css`
          background: rgba(0, 0, 0, 0.5);
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
        `}

  z-index: 1011;
`;

const Loader = ({
  overlayHeight = '100%',
  overlayWidth = '100%',
  isRelative = false,
  marginBottom = '0',
  paddingBottom = '4rem',
}) => {
  return (
    <Overlay
      height={overlayHeight}
      width={overlayWidth}
      isRelative={isRelative}
      marginBottom={marginBottom}
      paddingBottom={paddingBottom}
    >
      <Spinner />
    </Overlay>
  );
};

export default Loader;
