// import loadable from '@loadable/component';
import React from 'react';
import { useSelector } from 'react-redux';

import Header from './index';
import Loading from './loading';

// const Loading = loadable(() => import('./loading'));

const LoadableHeader = () => {
  const { isPageLoading } = useSelector(({ ui }) => ui);
  // const { isLoggedOut } = useSelector(({ user }) => user?.userState);

  return (
    // isPageLoading ? <Loading /> :
    <Header />
  );
};

export default LoadableHeader;
