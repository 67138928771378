/**
 * Gets the repositories of the user from Github
 */

import { call, put, select, takeLatest } from 'redux-saga/effects'; //eslint-disable-line
import * as Sentry from '@sentry/react';
import request from 'utils/request';

import { API_BASE_URL } from 'utils/constants';
import { updateShopProfileAction } from 'config/actions';
import { loadUserState } from 'config/sagas';
import {
  productDetailsFetchSuccessful,
  productDetailsFetchErrored,
  similarProductsFetchSuccessful,
  similarProductsFetchErrored,
} from './actions';
import {
  FETCH_SIMILAR_PRODUCTS,
  FETCH_PRODUCT_DETAILS,
  UPDATE_SIMILAR_PRODUCTS,
  UPDATE_SIMILAR_PRODUCTS_SUCCESSFUL,
} from './constants';

export function* getProductDetails({ productId, history, params }) {
  let url = '';
  if(params?.ga_ref) {
    url = `${API_BASE_URL}/product/details/${productId}?ga_ref=${params.ga_ref}&ga_uid=${params.ga_uid}&ga_iid=${params.ga_iid}`;
  } else {
    url = `${API_BASE_URL}/product/details/${productId}`;
  }

  try {
    const productDetails = yield call(request, url);
    if (productDetails.status === 'success') {
      yield put(productDetailsFetchSuccessful(productDetails));
      yield put(updateShopProfileAction(productDetails));
      if(params?.ga_ref) {
        yield* loadUserState({
          payload: { params: { shopName: '/', sortBy: 'recency', glance: params } },
        });
      }
    } else {
      // Prevent Page from going to /notfound

      yield call(history.replace, { pathname: '/notfound' });
    }
  } catch (error) {
    // console.log(error);
    Sentry.captureException(new Error(error));
    yield put(productDetailsFetchErrored(error));

    // Prevent Page from going to /notfound
    yield call(history.replace, { pathname: '/notfound' });
  }
}

export function* getSimilarProducts({ productId }) {
  const url = `${API_BASE_URL}/product/similarproducts/${productId}`;
  try {
    const similarProducts = yield call(request, url);
    yield put(similarProductsFetchSuccessful(similarProducts));
  } catch (error) {
    // console.log(error);
    Sentry.captureException(new Error(error));
    yield put(similarProductsFetchErrored(error));
  }
}

export function* updateSimilarProducts({ payload }) {
  yield put({
    type: UPDATE_SIMILAR_PRODUCTS_SUCCESSFUL,
    payload,
  });
}

export default function* productWatcherSaga() {
  yield takeLatest(FETCH_PRODUCT_DETAILS, getProductDetails);
  yield takeLatest(FETCH_SIMILAR_PRODUCTS, getSimilarProducts);
  yield takeLatest(UPDATE_SIMILAR_PRODUCTS, updateSimilarProducts);
}
