import crypto from 'crypto'
import { Buffer } from "buffer"
 export const generateKeys = () => {
    if(window?.JSEncrypt) {
        const { JSEncrypt } = window;
        if(getLocalStorage("mspSessionLocalKey1")){
           let localObj = getLocalStorage("mspSessionLocalKey1");
           let isKeyExpiredBool = isKeyExpired(localObj["ttl"])
           if(isKeyExpiredBool){
            const crypt = new JSEncrypt({default_key_size: 1024});
            let expD = setCookie("mspSessionPubKey",crypt.getPublicKeyB64(),2);
            setLocalStorage("mspSessionLocalKey2",getLocalStorage("mspSessionLocalKey1"));
            initiateDeleteTimeout();
            setLocalStorage("mspSessionLocalKey1",{"ttl": expD, "val": crypt.getPrivateKeyB64()});
           }
        } else {
            const crypt = new JSEncrypt({default_key_size: 1024});
            let expD = setCookie("mspSessionPubKey",crypt.getPublicKeyB64(),2);
            setLocalStorage("mspSessionLocalKey1",{"ttl": expD, "val": crypt.getPrivateKeyB64()});
        }
        console.log("key generated");
    }
}


  export const setLocalStorage = (key,value) => {
   localStorage.setItem(key,JSON.stringify(value));
}

  export const getLocalStorage = (key) => {
     return JSON.parse(localStorage.getItem(key));
}

 export const deleteLocalStorage = (key) => {
    localStorage.removeItem(key);
}
  export const setCookie = (cname, cvalue, expHours) => {
    const d = new Date();
    d.setTime(d.getTime() + (expHours*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;";
    initiateTime(d);
    return d;
}

  export const getCookie = (cname)  =>{
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

  export const initiateTime = (endDate) =>{
    let startDate = new Date();
    let seconds = ((endDate.getTime()) - (startDate.getTime()));
    setTimeout(initiateTimerFunction,seconds);
}

 export const initiateDeleteTimeout = () => {
    let localObj = getLocalStorage("mspSessionLocalKey2");
    if(!localObj){
        return;
    }
    let delTimeOut = 900000;
    console.log("caluclated delete time out");
    console.log(delTimeOut);
    setTimeout(deletePreviousKeys,delTimeOut);
}

  export const deletePreviousKeys = () => {
    console.log("Delete executed")
    console.log("Timer called at");
    console.log(new Date().toUTCString());
    deleteLocalStorage("mspSessionLocalKey2");
}

 export const initiateTimerFunction = () => {
    console.log("Generate keys called");
    console.log("Timer called at");
    console.log(new Date().toUTCString());
    generateKeys();
}

  export const isKeyExpired = (ttl) => {
    if(new Date().getTime() <= new Date(ttl).getTime()){
       return false;
    }
    return true;
} 

  export const decryptString = (data) => {
    if(window?.JSEncrypt) {
        const { JSEncrypt } = window;
        const jsEncrypt = new JSEncrypt();
        jsEncrypt.setPrivateKey(getLocalStorage("mspSessionLocalKey1")["val"], 'base64');
        let returnData = jsEncrypt.decrypt(data.encryptedKey);
        let salt = jsEncrypt.decrypt(data.encryptedSalt);
        if(!returnData){
            jsEncrypt.setPrivateKey(getLocalStorage("mspSessionLocalKey2")["val"], 'base64');
            returnData = jsEncrypt.decrypt(data.encryptedKey);
            salt = jsEncrypt.decrypt(data.encryptedSalt);
            if(!returnData){
               console.log("Decryption failed");    
               return;
            }
            return decryptStringWithPvKey(data.encryptedTextString,returnData,salt);
        } else {
            return decryptStringWithPvKey(data.encryptedTextString,returnData,salt);
        }
    } else {
        alert("JS Encrypt not loaded");
    }
}
export const decryptStringWithPvKey = (data,key,salt) => {
  let dataBuf = Buffer.from(data, 'base64');
  let keyBuf =  Buffer.from(key,'base64');
  let saltBuf = Buffer.from(salt,'base64');
  let authTag = dataBuf.slice(dataBuf.length-16,data.length);
  const decipher = crypto.createDecipheriv("aes-128-gcm", keyBuf, saltBuf);
  decipher.setAuthTag(authTag);
  let resp = JSON.parse(Buffer.concat([decipher.update(dataBuf.slice(0, dataBuf.length-16)), decipher.final()]).toString());
  return resp;
}
