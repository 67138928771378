import produce from 'immer';

import {
  FETCH_ORDERS,
  FETCH_ORDERS_FAILED,
  FETCH_ORDERS_SUCCESSFUL,
  SET_REVERSE_PICKUP,
  SET_REVERSE_PICKUP_FAILED,
  SET_REVERSE_PICKUP_SUCCESSFUL,
} from './constants';

export const initialState = {
  loading: false,
  error: null,
};

const orderStatusReducer = produce((draft, action) => {
  switch (action.type) {
    case FETCH_ORDERS: {
      draft.loading = true;
      draft.error = null;
      return draft;
    }
    case FETCH_ORDERS_SUCCESSFUL: {
      console.log('FETCH_ORDERS_SUCCESSFUL===>', action.data);
      draft.loading = false;
      draft.error = null;
      draft.nextCourierAttemptDate = action.data.nextCourierAttemptDate;
      draft.orders = action.data.orders;
      draft.customerName = action.data.customerName;
      draft.orderId = action.data.orderId;
      draft.courierAttemptReject = action.data.courierAttemptReject;
      return draft;
    }
    case FETCH_ORDERS_FAILED: {
      draft.loading = false;
      draft.error = action.err;
      return draft;
    }
    case SET_REVERSE_PICKUP: {
      draft.loading = true;
      draft.error = null;
      return draft;
    }
    case SET_REVERSE_PICKUP_SUCCESSFUL: {
      // console.log('SET_REVERSE_PICKUP_SUCCESSFUL ====>', action.data);
      draft.loading = false;
      draft.error = null;
      draft.nextCourierAttemptDate = action.data.nextCourierAttemptDate;
      draft.courierAttemptReject = action.data.courierAttemptReject;
      draft.orders = null;
      return draft;
    }
    case SET_REVERSE_PICKUP_FAILED: {
      draft.loading = false;
      draft.error =
        action.err ??
        'We seem to be having issues scheduling the pickup. Please try again in some time.';
      return draft;
    }

    default: {
      draft.loading = false;
      return draft;
    }
  }
}, initialState);

export default orderStatusReducer;
