// @flow

// Initial Load
export const INIT_LOAD = '@@/INIT_LOAD';
export const INIT_LOAD_SUCCESS = '@@/INIT_LOAD_SUCCESS';
export const INIT_LOAD_FAILED = '@@/INIT_LOAD_FAILED';

// Shop Constants
export const LOAD_MAIN_SHOP: string = 'shop/LOAD_MAIN_SHOP';
export const LOAD_NEW_SHOP: string = 'shop/LOAD_NEW_SHOP';
export const LOAD_MAIN_SHOP_SUCCESS: string = 'shop/LOAD_MAIN_SHOP_SUCCESS';
export const LOAD_MAIN_SHOP_FAILED: string = 'shop/LOAD_MAIN_SHOP_FAILED';

export const UPDATE_SHOP_PROFILE: string = 'shop/UPDATE_SHOP_PROFILE';

export const RELOAD_MAIN_SHOP: string = 'shop/RELOAD_MAIN_SHOP';
export const RELOAD_MAIN_SHOP_SUCCESS: string = 'shop/RELOAD_MAIN_SHOP_SUCCESS';
export const RELOAD_MAIN_SHOP_FAILED: string = 'shop/RELOAD_MAIN_SHOP_FAILED';

export const RELOAD_EXISTING_SHOP: string = 'shop/RELOAD_EXISTING_SHOP';
export const RELOAD_EXISTING_SHOP_SUCCESS: string =
  'shop/RELOAD_EXISTING_SHOP_SUCCESS';
export const RELOAD_EXISTING_SHOP_FAILED: string =
  'shop/RELOAD_EXISTING_SHOP_FAILED';

export const RELOAD_HOME_PAGE_FEED = 'shop/RELOAD_HOME_PAGE_FEED';
export const RELOAD_HOME_PAGE_FEED_SUCCESS =
  'shop/RELOAD_HOME_PAGE_FEED_SUCCESS';
export const LOAD_HOME_PAGE_FEED_SUCCESS: string =
  'shop/LOAD_HOME_PAGE_FEED_SUCCESS';

export const UPDATE_HOME_PAGE_FEED_PRODUCT =
  'shop/UPDATE_HOME_PAGE_FEED_PRODUCT';
export const UPDATE_HOME_PAGE_FEED_PRODUCT_SUCCESS =
  'shop/UPDATE_HOME_PAGE_FEED_PRODUCT_SUCCESS';

export const CLEAR_SHOP_DATA = 'shop/CLEAR_SHOP_DATA';
export const CLEAR_SHOP_DATA_SUCCESS = 'shop/CLEAR_SHOP_DATA_SUCCESS';

export const UPDATE_SHOPLANDING_PRODUCTS = 'shop/UPDATE_SHOPLANDING_PRODUCTS';
export const UPDATE_SHOPLANDING_PRODUCTS_SUCCESS =
  'shop/UPDATE_SHOPLANDING_PRODUCTS_SUCCESS';
export const CLEAR_CASHBACK_POPUP = 'shop/CLEAR_CASHBACK_POPUP';

export const UPDATE_LISTING_PRODUCTS = 'shop/UPDATE_LISTING_PRODUCTS';
export const UPDATE_LISTING_PRODUCTS_SUCCESS =
  'shop/UPDATE_LISTING_PRODUCTS_SUCCESS';

export const SET_SCROLL_POSITION: string = 'ui/SET_SCROLL_POSITION';
export const SET_SCROLL_POSITION_SUCCESS: string =
  'ui/SET_SCROLL_POSITION_SUCCESS';

export const LOAD_CATEGORY_SHOP: string = 'shop/LOAD_CATEGORY_SHOP';
export const LOAD_CATEGORY_SHOP_SUCCESS: string =
  'shop/LOAD_CATEGORY_SHOP_SUCCESS';
export const LOAD_CATEGORY_SHOP_FAILED: string =
  'shop/LOAD_CATEGORY_SHOP_FAILED';

export const SET_SORT_BY: string = 'shop/SET_SORT_BY';
export const SET_SORT_BY_SUCCESS: string = 'shop/SET_SORT_BY_SUCCESS';

export const SET_SHOP_SEARCH_TEXT: string = 'shop/SET_SHOP_SEARCH_TEXT';

export const TOGGLE_PRODUCT_BOTTOM_SHEET = 'ui/TOGGLE_PRODUCT_BOTTOM_SHEET';
export const SET_SHOW_PRODUCT_BOTTOM_SHEET = 'ui/SET_SHOW_PRODUCT_BOTTOM_SHEET';
export const SET_SHOW_PRODUCT_BOTTOM_SHEET_SUCCESS =
  'ui/SET_SHOW_PRODUCT_BOTTOM_SHEET_SUCCESS';
export const SET_SHOW_PRODUCT_BOTTOM_SHEET_FAILURE =
  'ui/SET_SHOW_PRODUCT_BOTTOM_SHEET_FAILURE';

export const CLEAR_CART: string = 'cart/CLEAR_CART';

// User Constants
export const LOAD_USER_STATE: string = 'user/LOAD_USER_STATE';
export const LOAD_USER_STATE_SUCCESS: string = 'user/LOAD_USER_STATE_SUCCESS';
export const LOAD_USER_STATE_FAILED: string = 'user/LOAD_USER_STATE_FAILED';
export const LOGIN = 'user/LOGIN';
export const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS';
export const LOGOUT = 'user/LOGOUT';
export const LOGOUT_SUCCESS = 'user/LOGOUT_SUCCESS';
export const NAME_CHANGED_SUCCESSFULLY = 'user/NAME_CHANGED_SUCCESSFULLY';
export const SET_SIGNIN_PHONE = 'user/SET_SIGNIN_PHONE';
export const SET_IS_WEBVIEW = 'user/SET_IS_WEBVIEW';

// UI Constants
export const TOGGLE_SIDEBAR = 'ui/TOGGLE_SIDEBAR';
export const TOGGLE_ATTRIBUTES_FILTER = 'ui/TOGGLE_ATTRIBUTES_FILTER';
export const TOGGLE_ATTRIBUTES_FILTER_SUCCESS =
  'ui/TOGGLE_ATTRIBUTES_FILTER_SUCCESS';
export const TOGGLE_SORT = 'ui/TOGGLE_SORT';
export const TOGGLE_SORT_SUCCESS = 'ui/TOGGLE_SORT_SUCCESS';
export const TOGGLE_PAGE_LOADING = 'ui/TOGGLE_PAGE_LOADING';
export const TOGGLE_SHOP_LOADING = 'ui/TOGGLE_SHOP_LOADING';
export const SET_LOADING_STATE = 'ui/SET_LOADING_STATE';
export const SET_HOME_LOADING_STATE = 'ui/SET_HOME_LOADING_STATE';
export const SET_HOME_FIRST_LOAD = 'ui/SET_HOME_FIRST_LOAD';
export const SET_LISTING_FIRST_LOAD = 'ui/SET_LISTING_FIRST_LOAD';
export const TOGGLE_SHOP_RELOADING: string = 'ui/TOGGLE_SHOP_RELOADING';
export const SHOW_TOAST = 'ui/SHOW_TOAST';
export const HIDE_TOAST = 'ui/HIDE_TOAST';
export const SHOW_TOAST_SUCCESS = 'ui/SHOW_TOAST_SUCCESS';
export const HIDE_TOAST_SUCCESS = 'ui/HIDE_TOAST_SUCCESS';
export const RESET_SHOP_LOADING_INITIAL_STATE =
  'ui/RESET_SHOP_LOADING_INITIAL_STATE';
export const RESET_SHOP_LOADING_INITIAL_STATE_SUCCESS =
  'ui/RESET_SHOP_LOADING_INITIAL_STATE_SUCCESS';
export const TOGGLE_SIGN_IN_LOADER = 'ui/TOGGLE_SIGN_IN_LOADER';
export const TOGGLE_OPEN_WHATSAPP = 'ui/TOGGLE_OPEN_WHATSAPP';
export const SET_APP_SECRETS = 'ui/SET_APP_SECRETS';

// FLAGS
// requires SENTRY_AUTH_TOKEN to be exported on server
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const REACT_APP_SENTRY = IS_PRODUCTION
  ? process.env.REACT_APP_SENTRY
  : '';
export const API_BASE_URL = '/api';
export const IMAGE_BASE_URL =
  // 'https://lqp-imgs.s3.ap-south-1.amazonaws.com/images';
  // 'https://lqp-imgs.s3.ap-south-1.amazonaws.com/images/myshopprime';
'https://d1ixo36kppfedg.cloudfront.net/images/myshopprime';

export const HOMEPAGE_STATES = {
  loading: 'loading',
  reloading: 'reloading',
  loaded: 'loaded',
  reloadingFeed: 'reloadingFeed',
  loadingWithoutFacet: 'loadingWithoutFacet',
};

export const reCAPTCHA_site_action_cart = "add_to_cart";
