import { captureException } from '@sentry/react';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API_BASE_URL } from 'utils/constants';
import requestForm from 'utils/requestForm';

import {
  loadingFailed,
  loadingSuccess,
  reloadingFailed,
  reloadingSuccess,
  toggleLoading,
  toggleReloading,
} from './actions';
import { LOAD_REVIEWS, RELOAD_REVIEWS } from './constants';

export function* loadReviews({ payload }) {
  const requestUrl = `${API_BASE_URL}/product/reviews`;

  yield put(toggleLoading(true));
  try {
    const { productId } = payload;

    const body = `productId=${productId}&pageNo=1`;
    const data = yield call(requestForm, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    });

    yield put(toggleLoading(false));
    if (data.status === 'success') {
      data.pageNo = 1;
      yield put(loadingSuccess(data));
    } else {
      yield put(loadingFailed(data));
    }
  } catch (err) {
    yield put(toggleLoading(false));
    captureException(new Error(err));
  }
}

export function* reloadReviews({ payload }) {
  const { pageNo, productId } = payload;

  const requestUrl = `${API_BASE_URL}/product/reviews`;

  yield put(toggleReloading(true));
  try {
    const body = `productId=${productId}&pageNo=${pageNo}`;

    const data = yield call(requestForm, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    });

    yield put(toggleReloading(false));

    if (data.status === 'success') {
      data.pageNo = pageNo;
      yield put(reloadingSuccess(data));
    } else {
      yield put(reloadingFailed(data));
    }
  } catch (err) {
    yield put(reloadingFailed({ mssg: err.mssg }));
    yield put(toggleReloading(false));
    captureException(new Error(err));
  }
}

export function* watchLoadReviews(payload) {
  yield takeLatest(LOAD_REVIEWS, loadReviews);
}

export function* watchReloadReviews(payload) {
  yield takeLatest(RELOAD_REVIEWS, reloadReviews);
}
