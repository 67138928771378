import Icon from 'components/Icon';
import Portal from 'components/Portal';
import React, { useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import GenericButton from 'ui-components/Buttons/GenericButton';
import { RedButtonStyles } from 'ui-components/Buttons/styles';
import { SpanText, Text } from 'ui-components/Texts';
import { ModalWrapper } from 'ui-components/Wrappers';
import { MediaQ } from 'ui-utils';

import { BaseCardContainer } from './styles';

const Wrapper = styled(BaseCardContainer)`
  margin-bottom: 1rem;
  border: 1px solid transparent;
  padding: 0;
  ${MediaQ('768px')`
    border: 1px solid #eee;
    padding: 1rem;
  `}
`;

const SizeButton = styled(GenericButton)`
  border-radius: 25px;
  height: 36px;
  display: inline-block;
  ${RedButtonStyles}
  margin:0.2rem;
  min-width: 36px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
`;
export const Th = styled.th`
  padding: 10px;
  text-align: center;
  background: #cecece;
  font-size: 14px;
`;
export const Td = styled.td`
  padding: 10px;
  text-align: center;
  border: 1px solid #cecece;
  font-size: 14px;
`;

const CloseButton = styled(GenericButton)`
  min-width: 0;
  font-weight: 400;
  font-size: 20px;
`;

const ErrorMssg = styled.p`
  font-size: 12px;
  position: absolute;
  top: 0px;
  right: -124px;
  min-width: 100px;
  height: auto;
  padding: 0.125rem 0.5rem;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.red};
  color: white;
  background: ${props => props.theme.colors.red};
  display: none;
  ${props => props.isActive && 'display: block;'}
`;

const SizeCard = ({
  sizeSet,
  productTitle,
  sizeChartHeader,
  sizeChartrows,
  variant,
  selectVariant,
  shakeSize,
}) => {
  const [showSizeChart, setShowSizeChart] = useState(false);

  const sizeRow =
    sizeSet?.length > 0
      ? sizeSet.map(size => {
          return (
            <button
              type='button'
              className={`focus:outline-none min-w-48px px-2 h-48px rounded-full mr-3 mb-2 min-h-10 text-xs ${
                size?.isProductInCart
                  ? 'bg-gray-300 border-gray-300 text-gray-900'
                  : size.variant_id === variant.variant_id
                  ? 'bg-red-700 border-red-700 text-white'
                  : ''
              }`}
              key={size.label}
              onClick={() => selectVariant(size)}
              inverted={size.variant_id === variant.variant_id}
              disabled={size?.isProductInCart || false}
              color='red'>
              {size.label}
            </button>
          );
        })
      : () => <div>row with sizes</div>;

  // console.log({ sizeSet });

  return (
    <Wrapper>
      <div
        style={{
          justifyContent: 'space-between',
          marginBottom: '0.6rem',
          display: 'flex',
          alignItem: 'center',
        }}>
        <SpanText style={{ position: 'relative' }}>
          Select Size
          <ErrorMssg isActive={shakeSize}>Please select size</ErrorMssg>
        </SpanText>
        <SpanText
          fontSize='13px'
          primary
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => setShowSizeChart(true)}>
          Size Chart
        </SpanText>
      </div>

      <Row className={`animated fast${shakeSize ? ' shake' : ''}`}>
        <Col xs={12}>{sizeRow}</Col>
      </Row>
      <Portal isOpen={showSizeChart} zIndex={1064}>
        <div className='min-w-50% max-w-98% px-2 rounded flex bg-white items-center justify-center py-4 h-auto'>
          <Grid className='max-w-full'>
            <Row className='mb-4'>
              <Col xs={10}>
                <p className='text-sm font-semibold'>
                  Size Chart for {productTitle}
                </p>
              </Col>
              <Col xs={2}>
                <button
                  className='border-0 w-full flex items-start justify-end rounded'
                  type='button'
                  onClick={() => setShowSizeChart(false)}>
                  <Icon icon='close' size='20px' />
                </button>
              </Col>
            </Row>
            <Table>
              <tbody>
                <tr key='head'>
                  {sizeChartHeader
                    ? sizeChartHeader.map((header, i) => (
                        <Th key={header.label + i}>
                          {header.label}
                          {header.metric ? (
                            <span>
                              <br />({header.metric})
                            </span>
                          ) : null}
                        </Th>
                      ))
                    : null}
                </tr>
                {sizeChartrows
                  ? sizeChartrows.map((sizeChartrow, i) => (
                      <tr key={i}>
                        {sizeChartrow.map((row, i) => (
                          <Td key={i}>{row}</Td>
                        ))}
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </Grid>
        </div>
      </Portal>
    </Wrapper>
  );
};

export default SizeCard;
