import {
  CHECK_OTP,
  CHECK_OTP_FAILED,
  CHECK_OTP_SUCCESSFUL,
  RESEND_OTP,
  RESEND_OTP_FAILED,
  RESEND_OTP_SUCCESSFUL,
} from './constants';

export function resendOtp() {
  return {
    type: RESEND_OTP,
  };
}

export function resendOtpSuccessful(data) {
  return {
    type: RESEND_OTP_SUCCESSFUL,
    data,
  };
}

export function resendOtpFailed(error) {
  return {
    type: RESEND_OTP_FAILED,
    error,
  };
}

export function checkOtp({ otp, history }) {
  return {
    type: CHECK_OTP,
    otp,
    history,
  };
}

export function checkOtpSuccessful(data) {
  return {
    type: CHECK_OTP_SUCCESSFUL,
    data,
  };
}

export function checkOtpFailed(err) {
  return {
    type: CHECK_OTP_FAILED,
    err,
  };
}
