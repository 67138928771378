import * as React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Flex } from 'ui-utils';

const Wrapper = styled.div`
  position: fixed;
  user-select: none;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: ${props => (props.zIndex ? props.zIndex : '1025')};
  ${Flex('row nowrap', 'center', 'center')}
`;

const Portal = ({ isOpen, children, onOverlayClick, zIndex }) => {
  const modalRoot = document.getElementById('portal-root');

  return isOpen
    ? ReactDOM.createPortal(
        <Wrapper zIndex={zIndex} onClick={onOverlayClick}>
          {children}
        </Wrapper>,
        modalRoot
      )
    : null;
};

Portal.defaultProps = {
  isOpen: false,
};

export default Portal;
