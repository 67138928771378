import { produce } from 'immer';
import { UPDATE_TYPES } from 'screens/Cart/constants';
import { isNullOrUndefined } from 'util';
import {
  // CLEAR_SHOP_DATA,
  CLEAR_SHOP_DATA_SUCCESS,
  HOMEPAGE_STATES,
  LOAD_HOME_PAGE_FEED_SUCCESS,
  LOAD_MAIN_SHOP_FAILED,
  LOAD_MAIN_SHOP_SUCCESS,
  RELOAD_HOME_PAGE_FEED_SUCCESS,
  RELOAD_MAIN_SHOP_SUCCESS,
  SET_HOME_LOADING_STATE,
  SET_SHOP_SEARCH_TEXT,
  SET_SORT_BY_SUCCESS,
  UPDATE_HOME_PAGE_FEED_PRODUCT_SUCCESS,
  UPDATE_LISTING_PRODUCTS_SUCCESS,
  UPDATE_SHOP_PROFILE,
  UPDATE_SHOPLANDING_PRODUCTS_SUCCESS,
  CLEAR_CASHBACK_POPUP,
} from 'utils/constants';

const initialShopState = {
  loading: true,
  filter: false,
  shop: {},
  resellingProducts: [],
  isFacetOnly: true,
  filters: { categories: [], others: [] },
  topProductCategories: [],
  bestsellerCollections: [],
  pageNo: 1,
  totalProducts: 0,
  totalBestsellerCollections: 0,
  sortBy: 'recency',
  searchText: '',
  collection: {},
  // sarees: [],
  // kurtas: [],
  // tops: [],
  // polos: [],
  // newly: [],
  // the: [],
  // top: [],
  homeFeedProducts: [],
  homeFeedPageNo: 1,
  homeFeedTotalProducts: 0,
  categoryName: '',
  reloadRequired: false,
  homeLoadingState: HOMEPAGE_STATES.loading,
  isScrollCompleted: false,
  stores: [],
  banners: [],
  cashBack: {
    showCashbackPopup: false,
    cashbackBanner: '',
  },
  scrollTop: 0,
  virtualOffset: 0,
  noResultCase: false,
  noResultMessage: '',
  listHeading: '',
  noResultEmojiUrl: '',
};

// eslint-disable-next-line consistent-return
const shopReducer = produce((draft, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOAD_MAIN_SHOP_SUCCESS: {
      // debugger;
      // eslint-disable-next-line no-case-declarations
      const { shop } = payload;
      // console.log(shop.virtualOffset);

      // eslint-disable-next-line no-case-declarations
      const {
        shop: profile,
        resellingProducts,
        showMoreBestsellerCollections,
        pageSize,
        filters,
        topProductCategories,
        bestsellerCollections,
        showMoreBestsellerCollectionsLink,
        pageNo,
        totalProducts,
        totalBestsellerCollections,
        sortBy,
        bestsellerCategories,
        collection,
        // sarees,
        // kurtas,
        // tops,
        // polos,
        // newly,
        // the,
        // top,
        categoryName,
        virtualOffset,
        noResultCase,
        noResultMessage,
        listHeading,
        noResultEmojiUrl,
        // homeLoadingState,
        // doNotShowFacet,
        hasNextPage,
      } = shop;

      const filterAttrs = new URLSearchParams(window.location.search);
      let hasFilters = false;
      filterAttrs.forEach((val, key) => {
        if (/attr/.test(key)) {
          hasFilters = true;
        }
      });

      draft.isScrollCompleted = !hasNextPage;
      draft.resellingProducts = resellingProducts || [];

      if (hasFilters) {
        draft.resellingProducts = resellingProducts ?? [];
      }

      draft.profile = profile ?? draft.profile;
      draft.isFacetOnly = false;
      draft.showMoreBestsellerCollections = showMoreBestsellerCollections;
      draft.pageSize = pageSize ?? draft.pageSize;
      draft.filters = filters ?? draft.filters;
      draft.topProductCategories = topProductCategories;
      draft.bestsellerCollections = bestsellerCollections;
      draft.showMoreBestsellerCollectionsLink = showMoreBestsellerCollectionsLink;
      draft.pageNo = pageNo ?? draft.pageNo;
      draft.totalProducts = totalProducts;
      draft.totalBestsellerCollections = totalBestsellerCollections;
      draft.sortBy = sortBy ?? draft.sortBy;
      draft.bestsellerCategories = bestsellerCategories;
      draft.searchText = profile?.searchText ?? '';
      draft.collection = collection;
      draft.noResultCase = noResultCase;
      draft.noResultMessage = noResultMessage;
      draft.noResultEmojiUrl = noResultEmojiUrl;
      draft.listHeading = listHeading;
      if (virtualOffset) {
        draft.virtualOffset = virtualOffset;
      }
      draft.categoryName = categoryName ?? draft.categoryName;
      draft.homeLoadingState = HOMEPAGE_STATES.loaded;

      return draft;
    }

    case LOAD_HOME_PAGE_FEED_SUCCESS: {
      // debugger;
      // console.log('load success', payload);
      const { shop } = payload;

      const {
        homeFeedProducts,
        homeFeedPageNo,
        homeFeedTotalProducts,
        profile,
        banners,
        cashBack,
        stores,
        virtualOffset,
        scrollTop,
        isScrollCompleted,
      } = shop;

      draft.scrollTop = scrollTop || 0;
      if (homeFeedProducts) {
        draft.homeFeedProducts = homeFeedProducts;
        draft.homeFeedPageNo = homeFeedPageNo;
        draft.homeFeedTotalProducts = homeFeedTotalProducts;
        draft.profile = profile;
        draft.isScrollCompleted = isScrollCompleted || false;
        draft.stores = stores;
        draft.banners = banners;
        draft.cashBack.showCashbackPopup = cashBack.showCashbackPopup || false;
        draft.cashBack.cashbackBanner = cashBack.cashbackBanner || '';
        draft.virtualOffset = virtualOffset;
        // debugger;
      }
      draft.homeLoadingState = HOMEPAGE_STATES.loaded;

      return draft;
    }

    case RELOAD_MAIN_SHOP_SUCCESS: {
      const {
        resellingProducts,
        pageSize,
        topProductCategories,
        bestsellerCollections,
        pageNo,
        sortBy,
        virtualOffset,
        hasNextPage,
      } = payload.shop;

      if (resellingProducts && resellingProducts?.length > 0) {
        resellingProducts.forEach(product =>
          draft.resellingProducts.push(product)
        );
      }
      draft.isScrollCompleted = !hasNextPage;
      draft.isFacetOnly = false;
      draft.pageSize = pageSize;
      draft.topProductCategories = topProductCategories;
      draft.bestsellerCollections = bestsellerCollections;
      draft.pageNo = pageNo;
      draft.sortBy = sortBy;
      draft.homeLoadingState = HOMEPAGE_STATES.loaded;
      if (virtualOffset) {
        draft.virtualOffset = virtualOffset;
      }
      return draft;
    }

    case RELOAD_HOME_PAGE_FEED_SUCCESS: {
      const {
        resellingProducts,
        pageNo,
        virtualOffset,
        hasNextPage,
      } = payload.shop;

      if (resellingProducts?.length > 0) {
        draft.isScrollCompleted = false;
        resellingProducts.forEach(product =>
          draft.homeFeedProducts.push(product)
        );
      } else {
        draft.isScrollCompleted = true;
      }

      draft.isScrollCompleted = !hasNextPage;

      draft.homeFeedPageNo = pageNo;
      draft.homeLoadingState = HOMEPAGE_STATES.loaded;
      draft.virtualOffset = virtualOffset || null;

      return draft;
    }

    case UPDATE_HOME_PAGE_FEED_PRODUCT_SUCCESS: {
      let index = null;
      draft.homeFeedProducts.forEach((existingProduct, i) => {
        if (existingProduct.id === payload.product.id) {
          index = i;
        }
        // return existingProduct;
      });

      if (index !== null && index >= 0) {
        draft.homeFeedProducts[index].isProductInCart = !(
          payload?.updateType &&
          (payload?.updateType === UPDATE_TYPES.REMOVE_ALL_INACTIVE ||
            UPDATE_TYPES.REMOVE)
        );
      }

      return draft;
    }

    case UPDATE_SHOPLANDING_PRODUCTS_SUCCESS: {
      const keys = ['sarees', 'kurtas', 'tops', 'polos', 'newly', 'the', 'top'];
      keys.forEach(key => {
        if (draft[key]?.resellingProducts?.length > 0) {
          let index = null;
          draft[key].resellingProducts.forEach((existingProduct, i) => {
            if (existingProduct.id === payload.product.id) {
              index = i;
            }
          });
          if (index !== null && index >= 0) {
            draft[key].resellingProducts[index].isProductInCart = !(
              payload?.updateType &&
              (payload.updateType === UPDATE_TYPES.REMOVE_ALL_INACTIVE ||
                UPDATE_TYPES.REMOVE)
            );
          }
        }
      });

      return draft;
    }

    case UPDATE_LISTING_PRODUCTS_SUCCESS: {
      let index = null;
      draft.resellingProducts.forEach((existingProduct, i) => {
        if (existingProduct.id === payload.product.id) {
          index = i;
        }
      });

      if (index !== null && index >= 0) {
        draft.resellingProducts[index].isProductInCart = !(
          payload?.updateType &&
          (payload?.updateType === UPDATE_TYPES.REMOVE_ALL_INACTIVE ||
            UPDATE_TYPES.REMOVE)
        );
      }

      draft.reloadRequired = payload.reloadRequired;
      return draft;
    }

    // eslint-disable-next-line no-fallthrough
    case SET_SORT_BY_SUCCESS:
      draft.sortBy = payload.sortBy;
      return draft;

    // eslint-disable-line no-fallthrough
    case LOAD_MAIN_SHOP_FAILED:
      return draft;

    // eslint-disable-line no-fallthrough
    case SET_SHOP_SEARCH_TEXT:
      // debugger;
      draft.searchText = payload.searchText;
      return draft;

    case UPDATE_SHOP_PROFILE:
      draft.profile = { ...action.payload.product.shop };
      return draft;

    case SET_HOME_LOADING_STATE: {
      draft.homeLoadingState = payload.homeLoadingState;
      return draft;
    }

    case CLEAR_SHOP_DATA_SUCCESS: {
      draft.homeFeedPageNo = 1;
      return draft;
    }

    case CLEAR_CASHBACK_POPUP: {
      draft.cashBack.showCashbackPopup = false;
      return draft;
    }

    case 'shop/UPDATE_SCROLL_POSITION_SUCCESS': {
      // debugger;
      draft.scrollTop = payload.scrollTop;
      return draft;
    }
  }
}, initialShopState);

export default shopReducer;
