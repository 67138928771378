import {
  LOAD_MAIN_SHOP_SUCCESS,
  SET_SORT_BY,
  SHOW_PRODUCT_BOTTOM_SHEET,
  TOGGLE_ATTRIBUTES_FILTER,
  TOGGLE_PRODUCT_BOTTOM_SHEET,
  TOGGLE_SIDEBAR,
  TOGGLE_SORT,
  CLEAR_CASHBACK_POPUP,
} from 'utils/constants';

export const showAttributesFilterAction = isShowAttributesFilter => ({
  type: TOGGLE_ATTRIBUTES_FILTER,
  payload: {
    isShowAttributesFilter,
  },
});

export const toggleSidebarAction = isShowSidebar => ({
  type: TOGGLE_SIDEBAR,
  payload: {
    isShowSidebar,
  },
});

export const showSortAction = isShowSort => ({
  type: TOGGLE_SORT,
  payload: {
    isShowSort,
  },
});

export const setSortByAction = sortBy => ({
  type: SET_SORT_BY,
  payload: {
    sortBy,
  },
});

export const toggleProductBottomSheetAction = (
  isShowProductBottomSheet,
  selectedProduct
) => ({
  type: TOGGLE_PRODUCT_BOTTOM_SHEET,
  payload: {
    isShowProductBottomSheet,
    selectedProduct,
  },
});

export const loadMainShopSuccess = payload => ({
  type: LOAD_MAIN_SHOP_SUCCESS,
  payload,
});

export const clearCashbackPopup = () => ({
  type: CLEAR_CASHBACK_POPUP,
});