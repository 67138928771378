import styled, { css } from 'styled-components';
import { Flex } from 'ui-utils';

import GenericButton from './GenericButton';

const FloatingButton = styled(GenericButton)`
    position: fixed;
    height: 3rem;
    min-height: 3rem;
    min-width: 3rem;
    width: 3rem;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.red};
    color: white;
    background: ${props => props.theme.colors.red};
    box-shadow: 0 2px 11px rgba(0,0,0,0.34);
    padding: 0;
    ${Flex('row nowrap', 'center', 'center')}
    ${props => props.top && 'top: 3rem'}
    ${props => props.bottom && 'bottom: 4rem'}
    ${props => props.left && 'left: 3rem'}
    ${props => props.right && 'right: 2rem'}
    z-index: 1006;
    transform: scale(0.0);
    opacity: 0;
    transform-origin: center center;
    transition: all 300ms ease-in-out;

    ${props =>
      props.isActive &&
      css`
        transform: scale(1);
        opacity: 1;
      `}
`;

export default FloatingButton;
