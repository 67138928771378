import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import tw from 'tailwind.macro';
import { GenericButton } from 'ui-components/Buttons';
import { Flex, MediaQ } from 'ui-utils';

export const BaseCardContainer = styled.div`
  padding: 1rem 1rem 0;
  margin-bottom: 0.5rem;
  position: relative;
  width: 100%;
  height: auto;
  border-top: 1px solid ${props => props.theme.primary.borderColor};
  border-bottom-left: 4px;
  border-bottom-right: 4px;
  background-color: ${props => props.theme.primary.backgroundColor};
  @media only screen and (max-device-width: 480px) {
    border-radius: 0;
  }
`;

export const CardImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => (props.hasSaleTimer ? '14rem' : '16rem')};
  margin-bottom: 0.5rem;
  overflow: hidden;
  // @media (max-width: 578px) {
  //   height: 14rem;
  // }
`;

export const ProductCardContainer = styled.div`
  height: 100%;
  background-color: ${props => props.theme.primary.backgroundColor};
  &:hover {
    background-color: ${props => props.theme.primary.hover.backgroundColor};
    box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.07);
    ${CardImageWrapper} {
      overflow: hidden;
    }
  }
  ${props =>
    !props.isHorizontal &&
    css`
      ${Flex('column nowrap', 'flex-start', 'space-between')}
      position: relative;
      width: 100%;
      display: block;
      flex: 1;
      padding: 0.5rem;
      border: 1px solid #dae1e3;
      margin-bottom: 1rem;
      height: 100%;
      @media (max-width: 578px) {
        border-left: 0 !important;
        border-bottom: 0 !important;
        margin-bottom: 0;
      }
      ${MediaQ('768px')`
        // margin-bottom: 76px;
        border-radius: 4px;
      `}
    `}

  .CardCTA {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
  ${props =>
    props.isHorizontal &&
    css`
      ${tw`
        w-full
      `}
      margin: 0px 8px;
      border-radius: 4px;
      border: solid 0.9px #dae1e3;
      padding: 8px;
    `}
`;

ProductCardContainer.displayName = 'ProductCardContainer';

export const GradientContainer = styled.div`
  width: 100%;
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  background-image: linear-gradient(
    to right,
    ${props => props.theme.colors.gradientColorStart},
    ${props => props.theme.colors.gradientColorEnd}
  );
`;
export const StyledLink = styled(Link)`
  cursor: pointer;
`;

export const CarouselDotGroup = styled.div`
  width: 100%;
  text-align: center;
  background-color: white;
  margin-top: -1rem;
`;

export const CarouselDot = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0.1rem;
  background-color: ${props =>
    props.selected ? props.theme.colors.red : 'white'};
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.red};
`;

export const CarouselCtaWrapper = styled.div`
  width: 100%;
  padding: 0.25rem;
  text-align: center;
  background-color: white;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.2rem;
`;

export const shimmer = props => keyframes`
  0% {
    background-position: ${
      props && props.width ? `-${props.width} 0` : '-30rem 0'
    };
  }
  100% {
    background-position: ${
      props && props.width ? `${props.width} 0` : '30rem 0'
    };
  }
`;

export const Placeholder = styled.div`
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : '#f6f7f8;'};
  border: ${props => (props.border ? props.border : '1px solid #f6f7f8;')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `};
  ${props =>
    props.width &&
    css`
      width: ${props.width};
      background-size: ${props.width} 100%;
    `};
  ${props =>
    props.circle &&
    css`
      border-radius: 50%;
    `};
  ${props =>
    props.animate &&
    css`
      background-image: linear-gradient(
        to right,
        #f6f7f8 0%,
        #edeef1 20%,
        #f6f7f8 60%,
        #f6f7f8 100%
      );
      animation-duration: ${props.speed ? props.speed : '1s'};
      fill-mode: forwards;
      animation-name: ${shimmer(props)}
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    `};
  ${props => props.className && `${tw`${props.className}`}`};
`;

export const ProductBase = styled.div`
  width: 210px;
  text-align: left;
  position: relative;
  vertical-align: top;
  overflow: hidden;
  display: inline-block;
  box-sizing: border-box;
  margin: 0 10px 30px;
`;

export const ProductThumbShim = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 101%;
  height: 210px;
  background-color: #282c3f;
  transition: visibility 0s, opacity 0.5s linear;
  visibility: hidden;
  opacity: 0;
`;

export const ProductCardContent = styled.div`
  position: relative;
  background: #fff;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
`;

export const ProductCardTitle = styled.h3`
  height: auto
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  font-weight: 400;
  line-height: 1.414;
  color: #282c3f;
  &.truncate {
    max-width: calc(100% - 24px);
  }
`;

export const ProductCardPrice = styled.div`
  line-height: 15px;
  margin: 0 0 12px;
  white-space: nowrap;
  font-weight: 400;
  ${Flex('row nowrap', 'center', 'flex-start')};
`;

export const ProductCardDiscountedPrice = styled.span`
  font-size: 14px;
  color: #282c3f;
  font-weight: 700;
  ${MediaQ('370px')`
        font-size: 14px;
  `}
`;

export const ProductCardStrike = styled.span`
  text-decoration: line-through;
  color: #7e818c;
  font-weight: 400;
  margin-left: 4px;
  font-size: 11px;
  ${MediaQ('370px')`
      font-size: 12px;
  `}
`;

export const ProductCardDiscountPercentage = styled.span`
  color: ${props => props.theme.colors.red};
  font-weight: 400;
  font-size: 11px;
  margin-left: 4px;
  background: #f0f8e6;
  border: 1px solid #f0f8e6;
  color: #13aa02;
  border-radius: 4px;
  padding: 0.25rem;
  ${MediaQ('370px')`
        font-size: 12px;
  `}
`;

export const slideUp = keyframes`
  0% {
    position: -120px;
  }
  100% {
    position: 120px
  }
`;

export const SizeDisplayWrapper = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 100%;
  position: absolute;
  bottom: 90px;
  background: #fff;
  transition: all 1s ease-out;
`;

export const SizeDisplayHeader = styled.div`
  padding: 8px 8px 8px 10px;
  text-align: left;
  font-size: 14px;
  color: #535766;
`;

export const SizeButton = styled.button`
  cursor: pointer;
  border-radius: 20px;
  margin: 5px;
  min-width: 34px;
  min-height: 34px;
  background-color: #fff;
  border: 1px solid #bfc0c6;
  outline: none;
  font-size: 12px;
  padding: 5px;
`;

export const CartCardSizeAndQty = styled.div`
  display: inline-block;
  margin-right: 8px;
`;

export const CartBase = styled.div`
  display: inline-block;
  padding: 2px 8px;
  background: #f5f5f6;
  color: #282c3f;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  border-radius: 2px;
  margin: 0 8px 4px 0;
`;

export const AddressCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: white;
`;

export const FloatRightContainer = styled.div`
  clear: both;
`;

export const OrderSummaryItems = styled.div`
  width: 100%;
  ${Flex('row nowrap', 'center', 'space-between')}
  line-height: 1.618;
  color: #2b3240;
  margin-bottom: 0.25rem;
`;

export const TrashButton = styled.button`
  border-radius: 3px;
  border: none;
  padding-left: 50%;
  cursor: pointer;
`;

export const CTAButton = styled(GenericButton)`
  font-weight: 700;
  svg {
    margin-right: 0.5rem;
    ${props =>
      props.iconFlipped &&
      css`
        transform: rotate(180deg);
      `}
  }
`;

export const ProductCardPriceWrapper = styled.div`
  ${Flex('row nowrap', 'center', 'flex-start')}
`;
