export const LOAD_REVIEWS = 'reviews/LOAD_REVIEWS';
export const LOAD_REVIEWS_SUCCESS = 'reviews/LOAD_REVIEWS_SUCCESS';
export const LOAD_REVIEWS_FAILED = 'reviews/LOAD_REVIEWS_FAILED';

export const RELOAD_REVIEWS = 'reviews/RELOAD_REVIEWS';
export const RELOAD_REVIEWS_SUCCESS = 'reviews/RELOAD_REVIEWS_SUCCESS';
export const RELOAD_REVIEWS_FAILED = 'reviews/RELOAD_REVIEWS_FAILED';

export const TOGGLE_REVIEWS_LOADING = 'reviews/TOGGLE_REVIEWS_LOADING';
export const TOGGLE_REVIEWS_RELOADING = 'reviews/TOGGLE_REVIEWS_RELOADING';
