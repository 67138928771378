import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Navbar = styled.div`
  ${tw`fixed w-full max-w-4xl h-bottomNav left-0 bg-white z-bottomNav mx-auto right-0`}
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
  bottom: ${props => props.affiliatePartner ? "56px" : "0px"}
`;

export const Wrapper = styled.div`
  ${tw`w-full h-full flex flex-row items-center justify-center`}
`;

export const ButtonLink = styled(NavLink).attrs({
  className: 'w-full h-full flex flex-col items-center justify-center',
})`
  p {
    ${tw`text-xs text-gray-600 text-center`};
  }
  &.nav-active {
    p {
      ${tw`text-red-700`};
    }
    svg path {
      fill: ${props => props.theme.colors.red} !important;
    }
  }
`;
