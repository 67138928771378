import Icon from 'components/Icon';
import React, { useLayoutEffect, useState, useRef, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUserAction,setSigninPhone } from 'config/actions';
import {setCodSignin} from 'screens/Payment/actions';
import { ScreenWrapper } from 'ui-components/Wrappers';
import { API_BASE_URL } from 'utils/constants';
import request from 'utils/request';
import {getRandomKey,handleEncryption}from 'utils/request'; 
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { kbDisplay, kbLayout } from 'utils/kbUtils';
import {decryptString} from 'utils/intransitUtils';

export const generateOtp = async phoneNumber => {
  try {
    const resRandom = await getRandomKey({});
    if(!resRandom){
      alert("Some error occured, please try again");
      return {
        status: 'failure',
      };
    }
    const encBody = handleEncryption(JSON.stringify({buyerMobile:phoneNumber}),resRandom.random);
    if(!encBody){
      alert("Encryption Failed");
      return {
        status: 'failure',
      };
    }
    const response = await request(`${API_BASE_URL}/rest/user/generateOtp`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        apiVersion: '5',
      },
      body: JSON.stringify({md: encBody}),
    });

    return response;
  } catch (error) {
    return {
      result: 'error',
      ...error,
    };
  }
};

async function validateOtp(options) {
  try {
    const resRandom =  await getRandomKey({});
    if(!resRandom){
      alert("Some error occured, please try again");
      return {
        status: 'failure',
      };
    }
    const encBody = handleEncryption(JSON.stringify({ ...options}),resRandom.random);
    if(!encBody){
      alert("Encryption Failed");
      return {
        status: 'failure',
      };
    }
    const response = await request(`${API_BASE_URL}/rest/user/validateOtp`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        apiVersion: '5',
      },
      body: JSON.stringify({md: encBody}),
    });

    return response;
  } catch (error) {
    return {
      result: 'error',
      ...error,
    };
  }
}

const SignIn = props => {
  const [phone, setPhone] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isWhatsapp, setIsWhatsapp] = useState(false);
  const [generateOtpFailure, setGenerateOtpFailure] = useState(false);
  const [otpFailureMessage,setOtpFailureMessage] = useState("");
  const [layout, setLayout] = useState('alt');
  const history = useHistory();
  const location = useLocation();
  const userState = useSelector(({ user }) => user.userState);
  const { affiliatePartner, glance, name, url, signinPhone } = userState;
  const { savedAddresses, buyerAddress } = useSelector(
    ({ address }) => address
  );
  const keyboard = useRef();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    // window.clevertap.event.push('msp signin page visted', {
    //   lqid: localStorage?.getItem('lqid'),
    // });
    // if (affiliatePartner && glance?.ga_ref) {
    //   ReactGA.set(
    //     {
    //       dimension1: glance.ga_ref,
    //       dimension2: glance.ga_uid,
    //       dimension3: glance.ga_iid,
    //     },
    //     ['glance']
    //   );
    //   ReactGA.pageview(location.pathname, ['glance']);
    // }
    const searchParams = new URLSearchParams(location.search);

    console.log(props.location.state);
    if (searchParams.has('redirect')) {
      let redirect = searchParams.get('redirect');
      if(redirect) {
        if(localStorage && redirect === "address") {
          if(savedAddresses?.length > 0) {
            localStorage.setItem('prevRoute', '/checkout/address');
          } else {
            localStorage.setItem('prevRoute', '/address?checkout=true');
          }
          
        } else if (redirect === "cod") {
          dispatch(setCodSignin(true));
        }
      }
    }
    if(searchParams.has('isWhatsapp')) {
      setIsWhatsapp(searchParams.get('isWhatsapp'));
    }
    const script = document.createElement("script");
      script.src = "/msp-images/jsencrypt.min.js";
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }

  }, []);

  useEffect(() => {
    if (signinPhone) {
      setPhone(signinPhone);
      setIsButtonDisabled(false);
    }
  },[signinPhone]);

  useEffect(() => {
    // if(!isButtonDisabled) {
    //   window.clevertap.event.push('msp signin valid phone number entered', {
    //     lqid: localStorage?.getItem('lqid'),
    //   });
    // }
  },[isButtonDisabled])

  useEffect(() => {
    if(buyerAddress.mobile) {
      setPhone(buyerAddress.mobile);
      setIsButtonDisabled(false);
    }
  },[buyerAddress.mobile]);

  const onChange = input => {
    if (input.length <= 10) {
      if (/^\d{0,10}$/.test(input)) {
        setPhone(input);
        if (affiliatePartner && keyboard?.current) {
          keyboard.current.setInput(input);
        }
      } else {
        setPhone(prevState => prevState);
        if (affiliatePartner && keyboard?.current) {
          keyboard.current.setInput(phone);
        }
      }
    } else {
      if (affiliatePartner && keyboard?.current) {
        keyboard.current.setInput(phone);
      }
    }
    // console.log(keyboard);
    setIsButtonDisabled(input.length < 10);
  };

  const onKeyPress = button => {
    if (button === '{shift}' || button === '{lock}') handleShift();
    if (button === '{alt}' || button === '{default}') handleAlt();
  };

  const handleShift = () => {
    setLayout(layout === 'default' ? 'shift' : 'default');
  };

  const handleAlt = () => {
    setLayout(layout === 'default' ? 'alt' : 'default');
  };

  const onLinkClick = () => {
    history.goBack();
  };

  const onPhoneInput = e => {
    const { value } = e.currentTarget;
    if (value.length <= 10) {
      if (/^\d{0,10}$/.test(value)) {
        setPhone(value);
        if (affiliatePartner && keyboard?.current) {
          keyboard.current.setInput(value);
        }
      } else {
        setPhone(prevState => prevState);
        if (affiliatePartner && keyboard?.current) {
          keyboard.current.setInput(phone);
        }
      }
    } else {
      if (affiliatePartner && keyboard?.current) {
        keyboard.current.setInput(phone);
      }
    }
    // console.log(keyboard);
    setIsButtonDisabled(value.length < 10);
  };

  const onSendOtp = async e => {
    e.preventDefault();
    console.log('submitting form...');
    if (affiliatePartner && glance?.ga_ref && /^\d{0,10}$/.test(phone) && phone.length === 10) {
      const response = await validateOtp({ buyerMobile: phone, otp: null });
      if (response.result === 'success') {
        // Redirect to some page
        const shopName = url?.split('/')[1];
        dispatch(
          loginUserAction({
            params: { shopName, sortBy: 'recency', state: location?.state },
          })
        );
        ReactGA.event(
          {
            category: 'Signin',
            action: 'signin click',
          },
          ['glance']
        );
      }
    } else if(/^\d{0,10}$/.test(phone) && phone.length === 10) {
      const encData = await generateOtp(phone);
      const response = decryptString(encData);
      console.log('returned response', response);
      if (response?.result === 'success') {
        setGenerateOtpFailure(false);
        setOtpFailureMessage("");
        dispatch(setSigninPhone(phone));
        // window.clevertap.event.push('msp signin initial send otp clicked', {
        //   lqid: localStorage?.getItem('lqid'),
        // });
        if(isWhatsapp) {
          history.push({
            pathname: '/signin/verification',
            search: '?isWhatsapp=true',
            state: props.location.state,
          });
        } else {
          history.push({
            pathname: '/signin/verification',
            search: '',
            state: props.location.state,
          });
        }
      } else {
        // window.clevertap.event.push('msp signin initial send otp api failed', {
        //   lqid: localStorage?.getItem('lqid'),
        //   result: response?.result ?? 'NA',
        //   status: response?.result === 'error' ? response?.response?.status ?? 'NA' : response?.status ?? 'NA',
        //   statusText: response?.result === 'error' ? response?.response?.statusText ?? 'NA' : response?.statusText ?? 'NA',
        // });
        setGenerateOtpFailure(true);
        setOtpFailureMessage(response?.message);
        setTimeout(() => {
          setGenerateOtpFailure(false);
        }, 4000);
      }
    } else {
      alert("Please enter a valid phone number")
    }
  };

  return (
    <ScreenWrapper className='py-4 bg-white min-h-screen'>
      <section className='px-2 border-b border-solid border-gray-200'>
        <header className='flex flex-row items-center justify-start mb-2'>
          <button className='border-0' type='button' onClick={onLinkClick}>
            <Icon icon='back' color='#2e2e2e' size='24px' />
          </button>
          <h1 className='flex-grow text-center text-base font-bold'>
            Enter your Mobile Number
          </h1>
        </header>
        <div className='mb-4'>
          <h3 className='text-xs text-center'>
            {affiliatePartner
              ? 'We will use this number to send order tracking details.'
              : 'We will send an SMS with a confirmation code to verify your phone number.'}
          </h3>
        </div>
      </section>

      {/* Form */}
      <section className='py-4 px-4'>
        <form onSubmit={onSendOtp}>
          <fieldset className='p-0 border-0 mb-4'>
            <label htmlFor='phone'>
              <span className='block mb-1 text-xs font-semibold text-gray-600'>
                Phone Number
              </span>
              <div
                className='h-10 flex flex-row items-center justify-between border border-solid border-gray-200 rounded bg-gray-200 focus:outline-none focus:border-red-700 focus:border-b'
                tabIndex='0'>
                <span className='text-xs px-2'>+91 -</span>
                <input
                  className='flex-grow h-full flex flex-row items-center justify-start text-xs bg-gray-200 focus:outline-none placeholder-gray-400 placeholder-opacity-80'
                  type='text'
                  spellCheck={false}
                  name='phone'
                  value={phone}
                  onChange={onPhoneInput}
                  placeholder='Enter your 10 digit mobile number'
                />
              </div>
            </label>
          </fieldset>
          {affiliatePartner && (
            <p className='text-xs text-center'>
              By continuing, you accept the{' '}
              <Link
                className='text-blue-15c visited:text-blue-15c underline hover:underline'
                to='/privacy'>
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link
                className='text-blue-15c visited:text-blue-15c hover:underline underline'
                to='/terms'>
                Terms of Services
              </Link>{' '}
              of {name}
            </p>
          )}
          <div>
            <button
              className='block w-full h-10 bg-red-700 rounded disabled:border-red-200 disabled:bg-red-200 text-white  border border-solid border-red-700 text-xs font-semibold'
              type='submit'
              disabled={isButtonDisabled}>
              {affiliatePartner ? 'Submit' : 'Send OTP'}
            </button>
          </div>
          <div className='my-4'>
            {generateOtpFailure && otpFailureMessage && (
              <p className='w-full text-center text-red-700 text-xs font-bold uppercase'>
                {otpFailureMessage}
              </p>
            )}
          </div>
        </form>
        {affiliatePartner && (
          <Keyboard
            keyboardRef={r => (keyboard.current = r)}
            layoutName={layout}
            onChange={onChange}
            onKeyPress={onKeyPress}
            layout={kbLayout}
            display={kbDisplay}
          />
        )}
      </section>
    </ScreenWrapper>
  );
};

SignIn.propTypes = {};

export default SignIn;
