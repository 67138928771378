import {
  FETCH_PAYMENT_DETAILS,
  FETCH_PAYMENT_DETAILS_FAILED,
  FETCH_PAYMENT_DETAILS_SUCCESSFUL,
  REDIRECT_CART,
  REDIRECT_CART_FAILED,
  REDIRECT_CART_SUCCESSFUL,
  RESET_PAYMENT,
  SET_COD_SIGNIN,
} from './constants';

export function redirectCart({mode, history}) {
  return {
    type: REDIRECT_CART,
    mode,
    history
  };
}

export function redirectCartSuccessful(data) {
  return {
    type: REDIRECT_CART_SUCCESSFUL,
    data,
  };
}

export function redirectCartFailed(error) {
  return {
    type: REDIRECT_CART_FAILED,
    error,
  };
}

export function fetchPaymentDetails() {
  return {
    type: FETCH_PAYMENT_DETAILS,
  };
}

export function fetchPaymentDetailsSuccessful(data) {
  return {
    type: FETCH_PAYMENT_DETAILS_SUCCESSFUL,
    data,
  };
}

export function setCodSignin(codSignin) {
  return {
    type: SET_COD_SIGNIN,
    codSignin,
  };
}

export function fetchPaymentDetailsFailed(error) {
  return {
    type: FETCH_PAYMENT_DETAILS_FAILED,
    error,
  };
}

export function resetPayment() {
  return {
    type: RESET_PAYMENT
  };
}
