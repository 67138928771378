export const LOAD_STORE = 'store/LOAD_STORE';
export const LOAD_STORE_SUCCESS = 'store/LOAD_STORE_SUCCESS';
export const LOAD_STORE_FAILURE = 'store/LOAD_STORE_FAILURE';

export const RELOAD_STORE = 'store/RELOAD_STORE';
export const RELOAD_STORE_SUCCESS = 'store/RELOAD_STORE_SUCCESS';
export const RELOAD_STORE_FAILURE = 'store/RELOAD_STORE_FAILURE';

export const TOGGLE_LOADING_STATE = 'store/TOGGLE_LOADING_STATE';
export const UPDATE_SCROLL_POSITION = 'store/UPDATE_SCROLL_POSITION';
export const UPDATE_SCROLL_POSITION_SUCCESS =
  'store/UPDATE_SCROLL_POSITION_SUCCESS';
export const UPDATE_SCROLL_POSITION_FAILURE =
  'store/UPDATE_SCROLL_POSITION_FAILURE';

export const UPDATE_STORE_PRODUCT = 'store/UPDATE_STORE_PRODUCT';
export const UPDATE_STORE_PRODUCT_SUCCESS =
  'store/UPDATE_STORE_PRODUCT_SUCCESS';

export const pageStates = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  RELOADING: 'RELOADING',
  RELOADED: 'RELOADED',
};

export const UPDATE_TYPES = {
  REMOVE: 'REMOVE',
  SIZE: 'SIZE',
  QTY: 'QTY',
  REMOVE_ALL_INACTIVE: 'REMOVE_ALL_INACTIVE',
};
