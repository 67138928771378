import { produce } from 'immer';

import {
  LOAD_STORE_FAILURE,
  LOAD_STORE_SUCCESS,
  pageStates,
  RELOAD_STORE_FAILURE,
  RELOAD_STORE_SUCCESS,
  TOGGLE_LOADING_STATE,
  UPDATE_SCROLL_POSITION_SUCCESS,
  UPDATE_STORE_PRODUCT_SUCCESS,
  UPDATE_TYPES,
} from './constants';

const initialState = {
  store: null,
  pageNo: 1,
  loadingState: pageStates.LOADING,
  isScrollCompleted: false,
  hasNextPage: false,
  resellingProducts: [],
  totalProducts: 0,
  scrollTop: 0,
};

// eslint-disable-next-line consistent-return
const storeReducer = produce((draft, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOAD_STORE_SUCCESS: {
      const {
        store,
        resellingProducts,
        pageNo,
        productCount,
        hasNextPage,
      } = payload.data;

      draft.isScrollCompleted = !hasNextPage;
      draft.hasNextPage = hasNextPage;
      draft.store = store || null;
      draft.resellingProducts = resellingProducts || [];
      draft.loadingState = pageStates.LOADED;
      draft.pageNo = pageNo;
      draft.totalProducts = productCount;

      return draft;
    }

    case LOAD_STORE_FAILURE: {
      console.log(payload);
      draft.loadingState = pageStates.LOADED;
      draft.error = payload?.error || {};

      return draft;
    }

    case RELOAD_STORE_SUCCESS: {
      // debugger;
      console.log(payload);
      const { resellingProducts, hasNextPage } = payload.data;
      if (resellingProducts?.length > 0) {
        resellingProducts.forEach(product =>
          draft.resellingProducts.push(product)
        );
      }

      draft.isScrollCompleted = !hasNextPage;
      draft.hasNextPage = hasNextPage;
      draft.loadingState = pageStates.RELOADED;
      draft.pageNo = payload.pageNo || 1;
      return draft;
    }

    case RELOAD_STORE_FAILURE: {
      console.log(payload);
      draft.loadingState = pageStates.RELOADED;
      draft.error = payload?.error || {};

      return draft;
    }

    case TOGGLE_LOADING_STATE: {
      draft.loadingState = payload.loadingState;
      return draft;
    }

    case UPDATE_SCROLL_POSITION_SUCCESS: {
      // debugger;
      draft.scrollTop = payload.scrollTop;
      return draft;
    }

    case UPDATE_STORE_PRODUCT_SUCCESS: {
      let index = null;
      draft.resellingProducts.forEach((existingProduct, i) => {
        if (existingProduct.id === payload.product.id) {
          index = i;
        }
      });

      if (index !== null && index >= 0) {
        draft.resellingProducts[index].isProductInCart = !(
          payload?.updateType &&
          (payload?.updateType === UPDATE_TYPES.REMOVE_ALL_INACTIVE ||
            UPDATE_TYPES.REMOVE)
        );
      }

      return draft;
    }

    default: {
      return draft;
    }
  }
}, initialState);

export default storeReducer;
