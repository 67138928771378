import React,{useEffect, useLayoutEffect} from 'react';
import { useDispatch, useSelector }from 'react-redux';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';
import { setAppSecrets } from 'config/actions';
import { IS_PRODUCTION } from 'utils/constants';

const random = ["rcp","gap","ctp","sep"];

const InitializeComponent = () => {
  const dispatch = useDispatch();
  const { secrets } = useSelector(({ ui }) => ui);

  useLayoutEffect(() => {
    let secret = {};
    random.map(item => {
      secret[item] = document.getElementById(item).innerText;
    });
    dispatch(setAppSecrets(secret));
    const elem = document.getElementById("random");
    elem.parentNode.removeChild(elem);
  },[]);

  useEffect(() => {
    if(secrets?.gap) {
      // ReactGA.initialize(
      //   [
      //     {
      //       trackingId: secrets.gap,
      //       gaOptions: {name: 'msp',}
      //     },
      //   ],
      //   { debug: false, alwaysSendToDefaultTracker: false }
      // );
      ReactGA.initialize(secrets?.gap);
    }
    if(secrets?.ctp) {

    }
    if(secrets?.sep) {
      // Sentry.init({
      //   dsn: IS_PRODUCTION ? secrets.sep : '',
      // });
    }
  },[secrets]);

  return (
      <></>
  )
}

export default InitializeComponent;