import Icon from 'components/Icon';
import React, { useState } from 'react';
import Countdown from 'react-countdown';

import { Timer, TimerText, Wrapper } from './styles';

const SalesTimerStrip = ({ showEnded = true, startTime, page }) => {
  const [isCompleted, setIsCompleted] = useState(false);

  const onTimerComplete = () => {
    // console.log(props);
    setIsCompleted(true);
  };

  const renderer = ({ hours, minutes, seconds, days, completed }) => {
    let calculatedHours = hours;

    if (days) {
      calculatedHours += 24 * days;
    }

    if (!completed) {
      return (
        <TimerText className='flex items-center justify-start text-xxs'>
          {calculatedHours > 9 ? calculatedHours : `0${calculatedHours}`}h{' '}
          {minutes > 9 ? minutes : `0${minutes}`}m{' '}
          {seconds > 9 ? seconds : `0${seconds}`}s
        </TimerText>
      );
    }

    return null;
  };

  const isSpaceRequired = page === 'product';
  const screenWidth = window.innerWidth;

  return (
    <Wrapper
      hide={!showEnded && isCompleted}
      style={{ margin: isSpaceRequired ? '1rem 0' : '0' }}
      className={`relative overflow-hidden h-full w-full px-2 block ${
        screenWidth > 400 ? 'rounded' : ''
      }  ${page === 'product' ? `fixed-width max-w-200px` : ''}`}>
      {window.innerWidth >= 372 && (
        <button
          type='button'
          className='absolute top-0 right-0 bg-red-700 border-red-700 border-solid border h-full block'>
          <Icon icon='bolt_fill' size='16px' color='white' />
        </button>
      )}
      <p className='text-xxs py-1 text-left text-black'>
        {showEnded && isCompleted ? 'Offer has ended' : 'Offer Ends in'}
      </p>

      <Timer>
        {startTime && (
          <Countdown
            date={startTime}
            renderer={renderer}
            daysInHours={false}
            onComplete={onTimerComplete}
          />
        )}
      </Timer>
    </Wrapper>
  );
};

export default SalesTimerStrip;
