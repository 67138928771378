import * as Sentry from '@sentry/react';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API_BASE_URL } from 'utils/constants';
import { dateDiff } from 'utils/helpers';
import request from 'utils/request';

import {
  GET_WALLET_TRANSACTIONS,
  RELOAD_WALLET_TRANSACTIONS,
} from './constants';

import {
  getWalletTransactionsSuccessful,
  getWalletTransactionsFailure,
  reloadWalletTransactionsSuccessful,
  reloadWalletTransactionsFailure,
  setWalletLoadingState,
} from './actions';

export function* getWalletTransactions() {
  const requestUrl = `${API_BASE_URL}/user/wallettransactions`;
  try {
    yield put(setWalletLoadingState({ walletLoadingState: 'loading' }));
    const options = {
      method: 'POST',
      body: JSON.stringify({ pageNo: 1 }),
      headers: {
        'Content-Type': 'application/json',
        // apiVersion: '5',
      },
    };
    const responseData = yield call(request, requestUrl, options);
    if (responseData.status === 'success') {
      // const { data } = responseData;
      // if (data?.orders?.length === 5) {
      //   yield* reloadOrders({
      //     lastOrderId: data.orders[data.orders.length - 1].orderId,
      //   });
      // }
      yield put(
        getWalletTransactionsSuccessful({
          walletTransactionsList: responseData.walletTransactionsList,
          userWallet: responseData.userWallet,
        })
      );
    }
    yield put(setWalletLoadingState({ walletLoadingState: 'loaded' }));
  } catch (err) {
    console.log(err);
    Sentry.captureException(new Error(err));
    yield put(getWalletTransactionsFailure({ err }));
    yield put(setWalletLoadingState({ walletLoadingState: 'loaded' }));
  }
}

export function* reloadWalletTransactions({ pageNo }) {
  const requestUrl = `${API_BASE_URL}/user/wallettransactions`;
  try {
    yield put(setWalletLoadingState({ walletLoadingState: 'reloading' }));
    const options = {
      method: 'POST',
      body: JSON.stringify({ pageNo }),
      headers: {
        'Content-Type': 'application/json',
        // apiVersion: '5',
      },
    };
    const responseData = yield call(request, requestUrl, options);
    if (responseData.status === 'success') {
      const { walletTransactionsList } = responseData;
      if (walletTransactionsList.length > 0) {
        yield put(
          reloadWalletTransactionsSuccessful({
            walletTransactionsList: responseData.walletTransactionsList,
          })
        );
      }
    }
    yield put(setWalletLoadingState({ walletLoadingState: 'loaded' }));
  } catch (err) {
    console.log(err);
    Sentry.captureException(new Error(err));
    yield put(reloadWalletTransactionsFailure({ err }));
    yield put(setWalletLoadingState({ walletLoadingState: 'loaded' }));
  }
}

export default function* walletsWatcherSaga() {
  yield takeLatest(GET_WALLET_TRANSACTIONS, getWalletTransactions);
  yield takeLatest(RELOAD_WALLET_TRANSACTIONS, reloadWalletTransactions);
}
