import Icon from 'components/Icon';
import Loader from 'components/Loader';
import { loginUserAction, setOpenWhatsapp } from 'config/actions';
import { fetchSavedAddresses } from 'screens/Address/actions';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { generateOtp } from 'screens/SignIn';
import { ScreenWrapper } from 'ui-components/Wrappers';
import { API_BASE_URL } from 'utils/constants';
import request from 'utils/request';
import {getRandomKey,handleEncryption}from 'utils/request'; 
import {decryptString} from 'utils/intransitUtils';


async function validateOtp(options) {
  try {
    const resRandom =  await getRandomKey({});
    if(!resRandom){
      alert("Some error occured, please try again");
      return {
        status: 'failure',
      };
    }
    const encBody = handleEncryption(JSON.stringify({ ...options}),resRandom.random);
    if(!encBody){
      alert("Encryption Failed");
      return {
        status: 'failure',
      };
    }
    const response = await request(`${API_BASE_URL}/rest/user/validateOtp`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        apiVersion: '5',
      },
      body: JSON.stringify({
        md: encBody,
      }),
    });

    return response;
  } catch (error) {
    return {
      result: 'error',
      ...error,
    };
  }
}

export const signInStates = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
};

const SignInVerification = ({ location }) => {
  const firstOtpInput = useRef(null);
  const formRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const userState = useSelector(({ user }) => user.userState);
  const {url,signinPhone} = userState;
  const { savedAddresses } = useSelector(
    ({ address }) => address
  );
  const { isSigningIn } = useSelector(({ ui }) => ui);
  const [buyerMobile, setBuyerMobile] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [verifyOtpFailure, setVerifyOtpFailure] = useState(false);
  const [otpFailureMessage,setOtpFailureMessage] = useState("");
  const [verifyOtpSucess, setVerifyOtpSucess] = useState(false);
  const [otp, setOtp] = useState([]);
  const [seconds, setSeconds] = useState(30);
  // const [resendCount, setResendCount] = useState(1);
  const [otpMessageSuccess, setOtpMessageSuccess] = useState('');
  const [otpMessageFailed, setOtpMessageFailed] = useState('');
  const [otpGenerateFailed, setOtpGenerateFailed] = useState(false);
  const [isWhatsapp, setIsWhatsapp] = useState(false);

  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if(searchParams.has('isWhatsapp')) {
      setIsWhatsapp(searchParams.get('isWhatsapp'));
    }
    // autofocus on first otp input
    if (firstOtpInput?.current) {
      firstOtpInput.current.focus();
    }
  }, []);
  useEffect(()=>{
    const script = document.createElement("script");
    script.src = "/msp-images/jsencrypt.min.js";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  },[])

  useEffect(() => {
    let timer;
    if (seconds > 0) {
      timer = setTimeout(() => {
        if (seconds <= 10) {
          setSeconds(`0${seconds - 1}`);
        } else {
          setSeconds(parseInt(seconds, 10) - 1);
        }
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [seconds]);

  useEffect(() => {
    if(signinPhone) {
      setBuyerMobile(signinPhone);
    }
  },[signinPhone]);

  const onLinkClick = () => {
    // history.push(`/signin?phone=${buyerMobile}`);
    history.goBack();
  };

  const onOtpInputChange = (e, i) => {
    // debugger;
    const re = /^[0-9]{0,1}$/;
    const { value } = e.currentTarget;
    // console.log('change');
    if (value && re.test(value)) {
      if (value.length === 1) {
        const newOtp = otp;
        otp[i] = value;
        setOtp(newOtp);
      } else if (value.length === 2) {
        e.currentTarget.value = value[0]; //eslint-disable-line
      }

      if (e.keyCode !== 8) {
        if (i < 5) {
          const correctInput = document.querySelector(`.input-${i + 1}`);
          if (correctInput) {
            correctInput.focus();
          }

          if (!isButtonDisabled) {
            setIsButtonDisabled(true);
          }
        } else {
          setIsButtonDisabled(false);
        }
      }
    } else {
      e.currentTarget.value = '';
    }
  };

  const onKeyUp = (e, i) => {
    // debugger;
    // console.log('key up');
    if (e.keyCode >= 37 && e.keyCode <= 40) {
      e.preventDefault();
    }
    if (e.keyCode === 8) {
      const { value } = e.currentTarget;
      if (!isButtonDisabled) {
        setIsButtonDisabled(true);
      }
      if (value.length === 0) {
        if (i > 0) {
          let prevInput = null;
          prevInput = document.querySelector(`.input-${i - 1}`);
          if (prevInput) {
            prevInput.focus();
          }
        }
      }
    }
  };

  const onValidateOtp = async e => {
    e.preventDefault();
    const inputs = formRef?.current?.querySelectorAll('input[type=number]');
    let data = [];
    if (inputs) {
      data = Array.from(inputs).map(el => el.value);
    }
    const otpValue = Number(data.join(''));
    if(otpValue) {
      const response = await validateOtp({ buyerMobile, otp: otpValue });

      if (response?.result === 'success') {
        // Redirect to some page
        const shopName = url?.split('/')[1];
        setVerifyOtpFailure(false);
        setOtpFailureMessage("");
        setVerifyOtpSucess(true);
        setTimeout(() => {
          setVerifyOtpSucess(false);
        }, 4000);
        if(localStorage?.getItem('prevRoute') === "/address?checkout=true" && (savedAddresses?.length === 0 || !savedAddresses)){
          dispatch(fetchSavedAddresses());
        }
        dispatch(
          loginUserAction({
            params: { shopName, sortBy: 'recency', state: location?.state },
          })
        );
        // window.clevertap.event.push('msp signin verified otp successfully', {
        //   lqid: localStorage?.getItem('lqid'),
        // });
        if(isWhatsapp) {
          dispatch(setOpenWhatsapp(true));
        }
      } else {
        setVerifyOtpFailure(true);
        setOtpFailureMessage(response?.message);
        setVerifyOtpSucess(false);
        setTimeout(() => {
          setVerifyOtpFailure(false);
        }, 4000);
      }
    } else {
      setVerifyOtpFailure(true);
      setOtpFailureMessage("Please enter OTP to verfiy");
      setVerifyOtpSucess(false);
      setTimeout(() => {
        setVerifyOtpFailure(false);
      }, 4000);
    }
  };

  const onResendClick = async () => {
    setSeconds(30);
    // setSeconds(resendCount * 15 + 30);
    // setResendCount(resendCount + 1);
    if(/^\d{0,10}$/.test(buyerMobile) && buyerMobile.length === 10) {
      const encData = await generateOtp(buyerMobile);
      const response = decryptString(encData);
      if (response?.result === 'success') {
        setOtpGenerateFailed(false);
        // window.clevertap.event.push('msp signin resend otp clicked', {
        //   lqid: localStorage?.getItem('lqid'),
        // });
        setOtpMessageSuccess('OTP sent successfully.');
        setTimeout(() => {
          setOtpMessageSuccess('');
        }, 4000);
      } else {
        // window.clevertap.event.push('msp signin resend otp api failed', {
        //   lqid: localStorage?.getItem('lqid'),
        //   result: response?.result ?? 'NA',
        //   status: response?.result === 'error' ? response?.response?.status ?? 'NA' : response?.status ?? 'NA',
        //   statusText: response?.result === 'error' ? response?.response?.statusText ?? 'NA' : response?.statusText ?? 'NA',
        // });
        setOtpMessageFailed(response?.message ?? 'Oops. Please try again later.');
        setTimeout(() => {
          setOtpMessageFailed('');
        }, 4000);
        setOtpGenerateFailed(true);
      }
    } else {
      alert("Please enter a valid phone number");
    }
  };

  return (
    <ScreenWrapper className='py-4 bg-white min-h-screen'>
      {/* Header */}
      <section className='px-2 border-b border-solid border-gray-200'>
        <header className='flex flex-row items-center justify-start mb-2'>
          <button className='border-0' type='button' onClick={onLinkClick}>
            <Icon icon='back' color='#2e2e2e' size='24px' />
          </button>
          <h1 className='flex-grow text-center text-base font-bold'>
            Verification Code
          </h1>
        </header>
        <div className='mb-4'>
          {
            buyerMobile && (
              <h3 className='text-xs text-center'>
                Enter OTP sent to {buyerMobile}.{' '}
                <Link
                  className='ml-2 visited:text-blue-700 focus:text-blue-700'
                  style={{ color: '#2c6cc6' }}
                  to='/signin'>
                  Edit Number
                </Link>
              </h3>
            )
          }
        </div>
      </section>

      {/* form */}
      <section className='py-4 px-4'>
        {
          buyerMobile ? (
            <form onSubmit={onValidateOtp} ref={formRef}>
              <fieldset className='border-0 mx-0 p-0 mb-4'>
                <label htmlFor='phone'>
                  <span className='block mb-1 text-xs font-semibold text-gray-600'>
                    Phone Number
                  </span>
                  <input
                    className='block h-10 w-full text-xs bg-gray-200 px-2 rounded'
                    type='text'
                    name='phone'
                    spellCheck={false}
                    value={buyerMobile}
                    disabled
                  />
                </label>
              </fieldset>

              <fieldset className='border-0 mx-0 p-0 mb-4'>
                <label htmlFor='otp-input'>
                  <span className='block mb-2 text-xs font-semibold text-gray-600'>
                    Enter OTP Here
                  </span>

                  <div
                    name='otp-input'
                    className={`mb-4 flex flex-row items-center justify-start animated fast${
                      verifyOtpFailure ? ' shake' : ''
                    }`}>
                    <input
                      onKeyUp={e => onKeyUp(e, 0)}
                      onInputCapture={e => onOtpInputChange(e, 0)}
                      type='number'
                      spellCheck={false}
                      className='input-0 text-xs text-center rounded mr-4 focus:rounded-b-none focus:outline-none focus:border-b-2 focus:border-solid focus:border-red-700 w-10 h-10 block bg-gray-300'
                      ref={firstOtpInput}
                    />
                    <input
                      onKeyUp={e => onKeyUp(e, 1)}
                      onInputCapture={e => onOtpInputChange(e, 1)}
                      type='number'
                      spellCheck={false}
                      className='input-1 text-xs text-center rounded mr-4 focus:rounded-b-none focus:outline-none focus:border-b-2 focus:border-solid focus:border-red-700 w-10 h-10 block bg-gray-300'
                    />
                    <input
                      onKeyUp={e => onKeyUp(e, 2)}
                      onInputCapture={e => onOtpInputChange(e, 2)}
                      type='number'
                      spellCheck={false}
                      className='input-2 text-xs text-center rounded mr-4 focus:rounded-b-none focus:outline-none focus:border-b-2 focus:border-solid focus:border-red-700 w-10 h-10 block bg-gray-300'
                    />
                    <input
                      onKeyUp={e => onKeyUp(e, 3)}
                      onInputCapture={e => onOtpInputChange(e, 3)}
                      type='number'
                      spellCheck={false}
                      className='input-3 text-xs text-center rounded mr-4 focus:rounded-b-none focus:outline-none focus:border-b-2 focus:border-solid focus:border-red-700 w-10 h-10 block bg-gray-300'
                    />
                    <input
                      onKeyUp={e => onKeyUp(e, 4)}
                      onInputCapture={e => onOtpInputChange(e, 4)}
                      type='number'
                      spellCheck={false}
                      className='input-4 text-xs text-center rounded mr-4 focus:rounded-b-none focus:outline-none focus:border-b-2 focus:border-solid focus:border-red-700 w-10 h-10 block bg-gray-300'
                    />
                    <input
                      onKeyUp={e => onKeyUp(e, 5)}
                      onInputCapture={e => onOtpInputChange(e, 5)}
                      type='number'
                      spellCheck={false}
                      className='input-5 text-xs text-center rounded focus:rounded-b-none focus:outline-none focus:border-b-2 focus:border-solid focus:border-red-700 w-10 h-10 block bg-gray-300'
                    />
                  </div>
                  {
                    !otpGenerateFailed && (
                      <div>
                        {seconds > 0 ? (
                          <div className='text-xs text-red-700'>00:{seconds}</div>
                        ) : (
                          <button
                            type='button'
                            className='bg-transparent border-0 text-left text-blue-700 text-xs'
                            onClick={onResendClick}>
                            Resend OTP
                          </button>
                        )}
                      </div>
                    )
                  }
                </label>
              </fieldset>
              <div>
                <button
                  type='submit'
                  className='block w-full h-10 bg-red-700 rounded disabled:border-red-200 disabled:bg-red-200 text-white  border border-solid border-red-700 text-xs font-semibold'
                >
                  Verify OTP
                </button>

                <div className='my-4'>
                  {verifyOtpFailure && otpFailureMessage && (
                    <p className='w-full text-center text-red-700 text-xs font-bold uppercase'>
                      {otpFailureMessage}
                    </p>
                  )}
                  {verifyOtpSucess && (
                    <p className='w-full text-center text-green-600 text-xs font-semibold uppercase'>
                      Otp Verified Successfully!
                    </p>
                  )}

                  {otpMessageSuccess && (
                    <p className='w-full text-center text-green-600 text-xs font-semibold uppercase'>
                      {otpMessageSuccess}
                    </p>
                  )}

                  {otpMessageFailed && (
                    <p className='w-full text-center text-red-600 text-xs font-semibold uppercase'>
                      {otpMessageFailed}
                    </p>
                  )}
                </div>
              </div>
            </form>
          ) : (
            <p>Go back to <Link className='visited:text-blue-700 focus:text-blue-700' style={{ color: '#2c6cc6' }} to='/signin'>Signin</Link> page.</p>
          )
        }
      </section>

      {isSigningIn === signInStates.LOADING && (
        <div>
          <Loader />
        </div>
      )}
    </ScreenWrapper>
  );
};

export default SignInVerification;
