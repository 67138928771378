import queryString from 'query-string';
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import DOMPurify from 'dompurify';

export const withConnect = mapStateToProps => connect(mapStateToProps);

export const getPage = location => {
  let page = '';
  const { pathname } = location;

  if (/\/checkout\/success/gi.test(pathname)) {
    page = 'order success';
  } else if (/\/categories+/gi.test(pathname)) {
    page = 'search';
  } else if (/\/signin$/gi.test(pathname)) {
    page = 'signin';
  } else if (/\/signin\/verification$/gi.test(pathname)) {
    page = 'signin-verification';
  } else if (/\/[a-zA-Z0-9.]+\/shop\/all/gi.test(pathname)) {
    page = 'shopAll';
  } else if (/\/[a-zA-Z0-9.]+\/shop\/stores/gi.test(pathname)) {
    page = 'stores';
  } else if (/\/[a-zA-Z0-9.]+\/[a-zA-Z0-9-.]+\/stores/gi.test(pathname)) {
    page = 'stores';
  } else if (/\/[a-zA-Z0-9.]+\/shop\/store\/[a-zA-Z0-9.]+/gi.test(pathname)) {
    page = 'storeproducts';
  } else if (
    /\/[a-zA-Z0-9.]+\/[a-zA-Z0-9-.]+\/store\/[a-zA-Z0-9.]+/gi.test(pathname)
  ) {
    page = 'storeproducts';
  } else if (/\/[a-zA-Z0-9.]+\/shop\/[a-zA-Z0-9-.]+/gi.test(pathname)) {
    page = 'categories';
  } else if (/\/[a-zA-Z0-9.]+\/shop\/[a-zA-Z0-9-.]+/gi.test(pathname)) {
    page = 'categories';
  } else if (/\/[a-zA-Z0-9.]+\/collections\/[a-zA-Z0-9-.]+/gi.test(pathname)) {
    page = 'collections';
  } else if (/\/delivery\/[a-zA-Z0-9.]+/gi.test(location.pathname)) {
    page = 'delivery status';
  } else if (
    /\/[a-zA-Z0-9.]+\/share\/[a-zA-Z0-9-.]+/gi.test(location.pathname)
  ) {
    page = 'share';
  } else if (/\/shop-list/gi.test(pathname)) {
    page = 'shop-list';
  } else if (/^\/$/gi.test(pathname)) {
    page = 'main';
  } else if (/\/[a-zA-Z0-9.]+\/new/gi.test(location.pathname)) {
    page = 'newShop';
  } else if (
    /\/[a-zA-Z0-9.]+\/list\/[a-zA-Z0-9_-]*$/gi.test(location.pathname)
  ) {
    page = 'list';
  } else if (/\/[a-zA-Z0-9.]+\/shop/gi.test(location.pathname)) {
    page = 'newShop';
  } else if (/\/notfound/gi.test(pathname)) {
    page = 'notfound';
  } else if (/\/[a-zA-Z0-9.]+\/shopproducts\/\d+/gi.test(pathname)) {
    page = 'shopproducts';
  } else if (/\/collections\/\d+/gi.test(pathname)) {
    page = 'collections';
  } else if (/\/share\/\d+/gi.test(pathname)) {
    page = 'share';
  } else if (/\/[a-zA-Z0-9.]+\/shopcollections/gi.test(pathname)) {
    page = 'shopcollections';
  } else if (/\/checkout\/payment\/otp/gi.test(pathname)) {
    page = 'verification';
  } else if (/\/checkout\/cart/gi.test(pathname)) {
    page = 'cart';
  } else if (/\/checkout\/address/gi.test(pathname)) {
    page = 'checkout address';
  } else if (/\/checkout\/payment/gi.test(pathname)) {
    page = 'payment';
  } else if (/\/product\/[a-zA-Z0-9-]+\/[a-zA-Z0-9-]/gi.test(pathname)) {
    page = 'product';
  } else if (/\/reviews\/[a-zA-Z0-9]+\/images/gi.test(pathname)) {
    page = 'review images';
  } else if (/\/reviews\/[a-zA-Z0-9]+/gi.test(pathname)) {
    page = 'reviews';
  } else if (/\/about-shop/gi.test(pathname)) {
    page = 'about shop';
  } else if (/\/about/gi.test(pathname)) {
    page = 'about';
  } else if (/\/terms/gi.test(pathname)) {
    page = 'terms and conditions';
  } else if (/\/privacy/gi.test(pathname)) {
    page = 'privacy policy';
  } else if (/\/privacy-new/gi.test(pathname)) {
    page = 'privacy policy';
  } else if (/\/returncancelpolicy/gi.test(pathname)) {
    page = 'return policy';
  } else if (/\/contact/gi.test(pathname)) {
    page = 'contact us';
  } else if (/\/profile/gi.test(pathname)) {
    page = 'profile';
  } else if (/\/edit-name/gi.test(pathname)) {
    page = 'editName';
  } else if (/\/manage-address/gi.test(pathname)) {
    page = 'manageAddress';
  } else if (/\/address/gi.test(pathname)) {
    page = 'address';
  } else if (/\/address\/[a-zA-Z0-9]+/gi.test(pathname)) {
    page = 'address';
  } else if (/\/editAddress/gi.test(pathname)) {
    page = 'edit address';
  } else if (/\/editAddress\/[a-zA-Z0-9]+/gi.test(pathname)) {
    page = 'edit address';
  } else if (/\/email-us/gi.test(pathname)) {
    page = 'emailUs';
  } else if (/\/know-more/gi.test(pathname)) {
    page = 'know more';
  } else if (/\/wallet-faq/gi.test(pathname)) {
    page = 'wallet faq';
  } else if (/\/wallet/gi.test(pathname)) {
    page = 'wallet';
  } else if (/\/myorders/gi.test(pathname)) {
    page = 'myorders';
  } else if (/\/order\/[a-zA-Z0-9-.]+/gi.test(pathname)) {
    page = 'order details';
  } else if (/\/help/gi.test(pathname)) {
    page = 'help';
  } else if (/\/selfFulfillmentOrders\/[a-zA-Z0-9-.]+/gi.test(pathname)) {
    page = 'selfFulfillmentOrders';
  } else if (/\/[a-zA-Z0-9.]+/gi.test(pathname)) {
    page = 'newShop';
  }

  return page;
};

export const getParamsByPage = (
  page,
  shopName = '',
  categoryName = '',
  sortBy = 'recency',
  searchText = '',
  pageNo = 1,
  attributes,
  collectionId,
  shareId,
  token,
  virtualOffset,
  noResultCase
) => {
  if (attributes) {
    attributes.join('&');
  }
  const params = {
    sortBy,
    type: 'shop',
    pageNo,
    productsOnlyView: false,
    shopName,
    categoryName,
    searchText,
    attributes: attributes && attributes.join('&'),
    collectionId,
    shareId,
    token,
    virtualOffset,
    noResultCase,
  };

  if (page === 'shop' || page === 'shopAll') {
    params.type = page;
  }

  if (page === 'categories') {
    params.productsOnlyView = true;
    params.categoryName = categoryName;
  }

  // console.log(`LOG ->: params`, params);
  return params;
};

export const getRequestOptionsForPage = (page, params) => {
  const requestOptions = {
    url: '',
    method: 'GET',
  };

  // debugger;
  console.log(page);

  const {
    type,
    sortBy,
    pageNo,
    shopName,
    collectionId,
    categoryId,
    shareId,
    categoryName,
    searchText,
    token,
    virtualOffset,
    shopUrl,
    noResultCase,
  } = params;
  // console.log(`LOG ->: getRequestOptionsForPage -> params`, params);

  const shopBaseURL = `/${shopName}/shop`;
  const collectionsBaseUrl = `/shop`;
  let baseURL = shopName && shopName !== '' ? shopBaseURL : collectionsBaseUrl;
  const q = searchText ? `&q=${searchText}` : '';

  let newURL = '';

  const attributes = params.attributes ? `&${params.attributes}` : '';
  const noResult = noResultCase
    ? `&noResultCase=${noResultCase}`
    : `&noResultCase=false`;

  // debugger;
  switch (page) {
    case 'shop':
      newURL = `listing?type=shop&sortBy=${sortBy}&pageNo=${pageNo}${q}${attributes}`;
      break;

    case 'list':
    case 'newShop':
      // debugger;
      let cName = null;
      if (categoryName && categoryName !== 'all') {
        cName = categoryName;
      }
      baseURL = `/${shopName}/shop${cName ? `/${cName}` : ''}`;
      if (pageNo > 1) {
        newURL = `listing?type=shop&sortBy=${sortBy}&pageNo=${pageNo}${attributes}${noResult}`;
      } else {
        newURL = `listing?type=shop&sortBy=${sortBy}&pageNo=${pageNo}${attributes}`;
      }
      break;

    case 'shopAll':
      newURL = `listing?type=shop&sortBy=${sortBy}&pageNo=${pageNo}${q}${attributes}`;
      break;

    case 'categories':
      newURL = `${categoryName}/listing?productsOnlyView=true&type=${type}&sortBy=${sortBy}&pageNo=${pageNo}${q}${attributes}`;
      break;

    case 'shopproducts':
      newURL = `listing?type=shopproducts&categoryId=${categoryId}&sortBy=${sortBy}&pageNo=${pageNo}`;
      break;

    case 'collections':
      newURL = `sharelisting?type=collectionshare&collectionsSharedId=${collectionId}`;
      break;

    case 'share':
      newURL = `sharelisting?type=multishare&shareid=${shareId}`;
      break;

    case 'shopcollections':
      break;

    default:
      break;
  }

  requestOptions.url = `${baseURL}/${newURL}`;
  // debugger;
  if (token) {
    requestOptions.url += newURL ? `&token=${token}` : `?token=${token}`;
  }
  if (virtualOffset && pageNo !== 1) {
    requestOptions.url += newURL
      ? `&virtualOffset=${virtualOffset}`
      : `?virtualOffset=${virtualOffset}`;
  }

  if (!token && shopUrl) {
    const retrievedToken = shopUrl
      .split('/')
      .slice(1)
      .pop();
    requestOptions.url += newURL
      ? `&token=${retrievedToken}`
      : `?token=${retrievedToken}`;
  }

  return requestOptions;
};

export const getWidthSafely = () => {
  return window
    ? Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    : 9999;
};

export const defualtBreakpoints = [
  { xs: 0 }, // all mobile devices
  { sm: 576 }, // mobile devices (not sure which one's this big)
  { md: 768 }, // ipad, ipad pro, ipad mini, etc
  { lg: 992 }, // smaller laptops
  { xl: 1200 }, // laptops and desktops
];

export const getBreakpointWidth = breakpointName => {
  let breakpointWidth = 0;
  defualtBreakpoints.forEach(obj => {
    const currentKey = Object.keys(obj)[0];
    if (currentKey === breakpointName) breakpointWidth = obj[currentKey];
  });

  return breakpointWidth;
};

export const getBreakpointName = width => {
  let bpName;

  defualtBreakpoints.forEach(obj => {
    const currentKey = Object.keys(obj)[0];
    if (obj[currentKey] <= width) bpName = currentKey;
  });

  return bpName;
};

export const getNextBreakpointWidth = breakpointName => {
  let nextBreakpointName;
  let nextBreakpointWidth = 9999;
  let currentBreakpointIndex = 0;

  for (let i = 0; i < defualtBreakpoints.length; i += 1) {
    const obj = defualtBreakpoints[i];
    const currentKey = Object.keys(obj)[0];
    if (currentKey === breakpointName) {
      currentBreakpointIndex = i;
    }

    if (currentBreakpointIndex > 0) {
      const nextBreakpointIndex = currentBreakpointIndex + 1;
      if (defualtBreakpoints.length > nextBreakpointIndex) {
        const nextBreakpointObj = defualtBreakpoints[nextBreakpointIndex];
        nextBreakpointName = Object.keys(nextBreakpointObj)[0]; // eslint-disable-line
        nextBreakpointWidth = nextBreakpointObj[nextBreakpointName];
      }

      break;
    }
  }
  return nextBreakpointWidth;
};

export const shouldRender = ({
  breakpointName,
  modifier,
  currentBreakpointName,
  currentWidth,
}) => {
  if (modifier === 'only') {
    if (breakpointName === currentBreakpointName) return true;
  } else if (modifier === 'up') {
    const breakpointWidth = getBreakpointWidth(breakpointName);
    if (currentWidth >= breakpointWidth) return true;
  } else if (modifier === 'down') {
    const nextBreakpointWidth = getNextBreakpointWidth(breakpointName);
    if (currentWidth < nextBreakpointWidth) return true;
  }
  return false;
};

export const isWidthOfMobile = width => {
  return width <= 576;
};

export const isWidthOfDesktop = width => {
  return width >= 992;
};

export const clearSearchFromParam = (history, location) => {
  const { search } = location;

  const page = getPage(location);

  const searchParams = new URLSearchParams(search);
  searchParams.delete('q');
  if (page === 'list') {
    history.push({
      pathname: location.pathname,
      search: '',
    });
  } else {
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }
};

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params,
      },
      match,
      location,
      history,
    };
  }, [params, match, location, history]);
}

export function getRelativeUrl(url) {
  return url
    .replace('https://myshopprime.com', '')
    .replace('https://test.myshopprime.com', '')
    .replace('https://staging.myshopprime.com', '');
}

export const useLocalStorage = () => {
  const storage = localStorage;

  const get = key => {
    return storage.getItem(key);
  };

  const set = (key, value) => {
    storage.setItem(key, value);
  };

  const getAll = () => {
    return Array.from({ length: storage.length }, (v, i) => i).map(index => ({
      key: storage.key(index),
      value: storage.getItem(storage.key(index)),
    }));
  };

  return {
    set,
    get,
    getAll,
  };
};

export const useAppDataHook = location => {
  const params = location.search.slice(1).split('&');
  const appDataArray = params.map(el => {
    const [key, value] = el.split('=');
    const res = {};
    res.key = key;
    res.value = value;
    return res;
  });
  const appData = appDataArray.reduce(
    (obj, item) => Object.assign(obj, { [item.key]: item.value }),
    {}
  );

  const defaultAppData = {
    user_auth_token: '',
    version: '',
  };
  Object.keys(appData).map(key => {
    if (key === '' || key === 'undefined' || key === null) {
      delete appData[key];
    }
  });

  return {
    ...defaultAppData,
    ...appData,
  };
};

const isBrowser = typeof window !== `undefined`;

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

export function useScrollPosition(effect, deps, element, useWindow, wait) {
  const position = useRef(getScrollPosition({ useWindow }));

  let throttleTimeout = null;

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow });
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, deps);
}

export function getRatingColor(rating) {
  if (rating >= 4) {
    return 'rgba(19, 160, 2, 1)';
  }
  if (rating > 2) {
    return 'rgba(255, 206, 51,1)';
  }

  return 'rgba(	230, 36, 36, 1)';
}

// intersectiion observer hook

export const useIO = options => {
  const [elements, setElements] = useState([]);
  const [entries, setEntries] = useState([]);

  const observer = useRef(null);

  const { root, rootMargin, threshold } = options || {};

  useEffect(() => {
    if (elements.length) {
      console.log('-----CONNECTING OBSERVER------');
      console.log(observer.current);
      observer.current = new IntersectionObserver(
        ioEntries => {
          setEntries(ioEntries);
        },
        {
          threshold,
          root,
          rootMargin,
        }
      );

      // console.log(observer.current);

      elements.forEach(element => {
        observer.current.observe(element);
      });
    }
    return () => {
      if (observer.current) {
        console.log('-----DISCONNECTING OBSERVER------');
        observer.current.disconnect();
      }
    };
  }, [elements, root, rootMargin, threshold]);

  return [observer.current, setElements, entries];
};

export const getScrollParent = node => {
  console.log('node', node);
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  }
  return getScrollParent(node.parentNode);
};

export const dateConverter = timestamp => {
  const a = new Date(timestamp);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  const time = `${month} ${date}, ${year}`;
  return time;
};

export const dateDiff = timestamp => {
  const currentDate = new Date();
  const deliveryDate = new Date(timestamp);
  return Math.floor(
    (Date.UTC(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    ) -
      Date.UTC(
        deliveryDate.getFullYear(),
        deliveryDate.getMonth(),
        deliveryDate.getDate()
      )) /
      (1000 * 60 * 60 * 24)
  );
};

export const onItemsRenderedComposed = (fn, setScrollRowAndColumn) => rest => {
  const {
    overscanRowStartIndex,
    overscanRowStopIndex,
    visibleRowStartIndex,
    visibleRowStopIndex,
  } = rest;

  // window.sessionStorage.setItem('rowStartIndex', visibleRowStartIndex);
  // window.sessionStorage.setItem('rowStopIndex', visibleRowStopIndex);
  if (
    typeof setScrollRowAndColumn === 'function' ||
    typeof setScrollRowAndColumn === 'object'
  ) {
    setScrollRowAndColumn(visibleRowStartIndex, visibleRowStopIndex);
  }

  return fn({
    overscanStartIndex: overscanRowStartIndex,
    overscanStopIndex: overscanRowStopIndex,
    visibleStartIndex: visibleRowStartIndex,
    visibleStopIndex: visibleRowStopIndex,
  });
};

export const getPageLoadSpeed = () => {
  if (window.pageSpeedTimer) {
    const duration = Number(
      (Date.now() - window.pageSpeedTimer) / 1000
    ).toFixed(3);
    console.log(`Page Load Time: ${duration}s.`);
    return duration;
  }
  return 0.0;
};

export const domPurifyCleanup = (item) => {
  const clean = DOMPurify.sanitize(item);
  return clean;
};

export const sanitizeTypes = (type,value) => {
  if(type === "string") {
    return domPurifyCleanup(value);
  } else if(type === "number") {
    return Number(domPurifyCleanup(value));
  } else if(type === "boolean") {
    return Boolean(domPurifyCleanup(value));
  }
};

export const sanitizeObject = (object) => {
  let cleanObj = {};
  for (const [key, value] of Object.entries(object)) {
    const type = typeof value;
    const constructorType = Object.getPrototypeOf(value).constructor;
    if(type === "string" || type === "number" || type === "boolean") {
      Object.assign(cleanObj, { ...cleanObj, [key]: sanitizeTypes(type,value) });
    } else if(type === "array" || (type === "object" && constructorType === Array)) {
      let tempArray = [];
      value.map(item => {
        const itemType = typeof item;
        if(itemType === "object") {
          tempArray.push(sanitizeObject(item));
        } else {
          tempArray.push(sanitizeTypes(itemType,item));
        }
      });
      Object.assign(cleanObj, { ...cleanObj, [key]: domPurifyCleanup(tempArray) });
    } else if(type === "object" || (type === "object" && constructorType === Object)) {
      Object.assign(cleanObj, { ...cleanObj, [key]: sanitizeObject(value) });
    } else {
      Object.assign(cleanObj, { ...cleanObj, [key]: value });
      console.log(type,key,value,"unhandled/undefined type in object fn");
    }
  }
  return cleanObj;
};

export const sanitizeStringifiedObject = (object) => {
  let cleanObject = {};
  let json = JSON.parse(object);
  if(Object.keys(json).length === 0 && json.constructor === Object) {
    cleanObject = JSON.stringify({});
  } else {
    for (const [key, value] of Object.entries(json)) {
      const type = typeof value;
      const constructorType = Object.getPrototypeOf(value).constructor;
      if(type === "string" || type === "number" || type === "boolean") {
        Object.assign(cleanObject, { ...cleanObject, [key]: sanitizeTypes(type,value) });
      } else if(type === "array" || (type === "object" && constructorType === Array)) {
        let tempArray = [];
        value.map(item => {
          const itemType = typeof item;
          if(itemType === "object") {
            tempArray.push(sanitizeObject(item));
          } else {
            tempArray.push(sanitizeTypes(itemType,item));
          }
        });
        Object.assign(cleanObject, { ...cleanObject, [key]: tempArray });
      } else if(type === "object" || (type === "object" && constructorType === Object)) {
        Object.assign(cleanObject, { ...cleanObject, [key]: sanitizeObject(value) });
      } else {
        Object.assign(cleanObject, { ...cleanObject, [key]: value });
        console.log(type,key,value,"unhandled/undefined type in main fn");
      }
    }
    cleanObject = JSON.stringify(cleanObject);
  }
  return cleanObject;
};
