import { call, put, takeLatest } from 'redux-saga/effects';
import {
  loadCategoriesFailure,
  loadCategoriesSuccess,
} from 'screens/Categories/actions';
import { LOAD_CATEGORIES } from 'screens/Categories/constants';
import request from 'utils/request';

function* loadCategoriesData({ payload }) {
  // debugger;
  const { token, shopName } = payload;

  const URL = `/api/${shopName}/shop/listing?type=shop&sortBy=recency&pageNo=1${
    token ? `&token=${token}` : ''
  }`;
  const options = {
    method: 'get',
  };

  try {
    const res = yield call(request, URL, options);
    if (res.status === 'success') {
      yield put(loadCategoriesSuccess(res.data));
    } else {
      yield put(loadCategoriesFailure());
    }
  } catch (err) {
    yield put(loadCategoriesFailure());
  }
}

export function* watchLoadCategoriesData(payload) {
  yield takeLatest(LOAD_CATEGORIES, loadCategoriesData);
}
