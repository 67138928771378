import SizeCard from 'components/Cards/SizeCard';
import Icon from 'components/Icon';
import Portal from 'components/Portal';
import SalesTimerStrip from 'components/SalesTimerStrip';
import { CloseButton } from 'components/SizeSelectModal/styles';
import React, { useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addProduct } from 'screens/Cart/actions';
import { toggleProductBottomSheetAction } from 'screens/Home/actions';
import styled from 'styled-components';
import { Text } from 'ui-components/Texts';
import request from 'utils/request';
import {
  reCAPTCHA_site_action_cart,
} from 'utils/constants';
import { load } from 'recaptcha-v3';

import {
  Body,
  Header,
  Overlay,
  SelectSize,
  SelectVariant,
  Sheet,
} from './styles';

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
`;
export const Th = styled.th`
  padding: 10px;
  text-align: center;
  background: #cecece;
  font-size: 16px;
`;
export const Td = styled.td`
  padding: 10px;
  text-align: center;
  border: 1px solid #cecece;
  font-size: 14px;
`;

const ProductBottomSheet = ({ selectedProduct, history }) => {
  const dispatch = useDispatch();
  const [variantId, setVariantId] = useState('');
  const [shakeSize, setShakeSize] = useState(false);
  const [sizeSetState, setSizeSetState] = useState({
    showSizeCard: false,
    sizeChartHeader: [],
    sizeChartrows: [],
  });

  const {
    buyerPrice,
    fullProductImageUrl300,
    saleTimer,
    saleBuyerPrice,
    salePercentDiscount,
    productTitle,
    productId,
    id,
    position,
    ...rest
  } = selectedProduct;

  // console.log({ ...selectedProduct });

  const closeBottomSheet = () => {
    dispatch(toggleProductBottomSheetAction(false, null));
  };
  const { secrets } = useSelector(({ ui }) => ui);

  const addProductToCart = () => {
    if (variantId) {
      // window.clevertap.event.push('msp add to cart via bottomsheet', {
      //   lqid: localStorage?.getItem('lqid') ?? '',
      //   productTitle,
      //   productId,
      //   id,
      //   variantId,
      // });
      load(secrets?.rcp).then(recaptcha => {
        recaptcha.execute(reCAPTCHA_site_action_cart).then(token => {
          dispatch(
            addProduct({
              product: selectedProduct,
              qty: 1,
              variantId,
              buyNow: false,
              history,
              from: 'outside',
              position,
              token,
            })
          );
        });
      });
    } else {
      setShakeSize(true);
      setTimeout(() => {
        setShakeSize(false);
      }, 1500);
    }
  };

  const onSizeSelect = value => {
    setVariantId(value.variant_id);
  };

  const onSizeChartClick = async () => {
    // debugger;
    const { sizeChartHeader, sizeChartValues, sizeChartrows } = await request(
      `/api/productspecificdetails/${id}`
    );

    setSizeSetState(prevState => ({
      ...prevState,
      showSizeCard: true,
      sizeChartHeader,
      sizeChartValues,
      sizeChartrows,
    }));
  };

  const renderSizes = () => {
    const sizeSet = selectedProduct['size-set'];
    return Object.entries(sizeSet).map(([key, value]) => (
      <button
        type='button'
        className={`focus:outline-none h-48px min-w-48px w-auto text-xs mr-4 mb-2 px-2 rounded-full font-semibold${
          value.variant_id === variantId
            ? ' bg-red-700 text-white border border-solid border-red-700'
            : ' bg-white text-gray-800'
        }`}
        key={key}
        onClick={() => onSizeSelect(value)}>
        {value.label}
      </button>
    ));
  };

  return (
    <>
      <Overlay className='z-toast'>
        <Sheet className='slideInUp animated faster'>
          <Header>
            {/* close button */}
            <button
              type='button'
              onClick={closeBottomSheet}
              className='border-0'>
              <Icon icon='close' size='20px' color='#2e2e2e' />
            </button>

            {/* image wrapper */}
            <div className='w-1/4 h-90 flex flex-row items-center justify-center '>
              <img
                className='h-auto w-auto max-h-full max-w-full'
                src={fullProductImageUrl300}
                alt='product'
              />
            </div>

            {/* product details */}
            <div className='w-3/4 px-2'>
              {/* prices */}
              <div className='flex flex-row items-center justify-start leading-4 mb-1'>
                <span className='mr-2 text-lg font-bold text-gray-900'>
                  {`₹${buyerPrice}`}
                </span>

                {saleBuyerPrice && (
                  <span className='mr-2 text-sm font-normal line-through text-gray-600'>
                    {`₹${saleBuyerPrice}`}
                  </span>
                )}

                {salePercentDiscount && (
                  <span className='bg-green-100 border-green-100 text-green-700 p-1 rounded text-xs font-normal'>{`${salePercentDiscount} OFF`}</span>
                )}
              </div>

              {/* title */}
              <p className='truncate ... text-xs text-gray-500 mb-1'>
                {productTitle}
              </p>

              {/* sale timer */}
              {saleTimer && (
                <div className='relative w-auto max-w-200px'>
                  <SalesTimerStrip
                    showEnded={false}
                    startTime={Date.now() + saleTimer}
                  />
                </div>
              )}
            </div>
          </Header>

          <Body>
            {/* select size */}
            <SelectSize>
              <header>
                <h4 className='text-red-700 flex flex-row justify-start items-start'>
                  Select Size
                  {shakeSize && (
                    <span className='text-xs bg-red-700 text-white rounded px-2 h-auto block ml-2 font-normal'>
                      Please select size
                    </span>
                  )}
                </h4>
                <button
                  type='button'
                  className='border-0 underline text-xs'
                  onClick={onSizeChartClick}>
                  Size Chart
                </button>
              </header>
              <div
                className={`flex flex-row items-center justify-start flex-wrap animated fast${
                  shakeSize ? ' shake' : ''
                }`}>
                {renderSizes()}
              </div>
            </SelectSize>
          </Body>

          <button
            type='button'
            className='btn btn-icon w-full h-12 btn-red text-sm focus:outline-none'
            onClick={addProductToCart}>
            <Icon icon='cart' size='20px' color='white' className='mr-2' />
            Add To Cart
          </button>
        </Sheet>
      </Overlay>

      <Portal zIndex='1072' isOpen={sizeSetState.showSizeCard}>
        <div
          className='bg-white border-test z-toast h-auto rounded p-4 w-full relative'
          style={{
            maxWidth: '98%',
          }}>
          <Grid className='max-w-full'>
            <Row className='mb-4'>
              <Col xs={10}>
                <p className='text-sm font-semibold'>
                  Size Chart for {productTitle}
                </p>
              </Col>
              <Col xs={2}>
                <button
                  className='border-0 w-full flex items-start justify-end rounded'
                  type='button'
                  onClick={() =>
                    setSizeSetState(prevState => ({
                      ...prevState,
                      showSizeCard: false,
                    }))
                  }>
                  <Icon icon='close' size='20px' />
                </button>
              </Col>
            </Row>
            <Table>
              <tbody>
                <tr key='head'>
                  {sizeSetState.sizeChartHeader
                    ? sizeSetState.sizeChartHeader.map((header, i) => (
                        <Th key={header.label + i}>
                          {header.label}
                          {header.metric ? (
                            <span>
                              <br />({header.metric})
                            </span>
                          ) : null}
                        </Th>
                      ))
                    : null}
                </tr>
                {sizeSetState.sizeChartrows
                  ? sizeSetState.sizeChartrows.map((sizeChartrow, i) => (
                      <tr key={i}>
                        {sizeChartrow.map((row, i) => (
                          <Td key={i}>{row}</Td>
                        ))}
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </Grid>
        </div>
      </Portal>
    </>
  );
};

export default withRouter(ProductBottomSheet);
