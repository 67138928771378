// import CartPreview from 'components/CartPreview';
// import CartPreview from 'components/CartPreview';
import Icon from 'components/Icon';
// import Search from 'components/Search';
import React, { useEffect, useState } from 'react';
// import { Col, Grid, Row } from 'react-flexbox-grid';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showAttributesFilterAction } from 'screens/ProductListing/actions';
import { IconButton } from 'ui-components/Buttons';
import { getPage } from 'utils/helpers';

import Navigation from './Navigation';
import { HeaderButtons, Wrapper } from './styles';

const initialState = {
  navigationButton: false,
  pageTitle: false,
  cartButton: false,
  filterButton: false,
};

const headerStates = Object.freeze({
  SIMPLE_NAV: 'SIMPLE_NAV',
  NAV_WITH_CART: 'NAV_WITH_CART',
  NAV_WITH_FILTER_AND_CART: 'NAV_WITH_FILTER_AND_CART',
});

const simpleNavPages = [
  'verification',
  'payment',
  'cart',
  'address',
  'edit address',
  'checkout address',
  'know more',
  'manageAddress',
  'about shop',
  'about',
  'terms and conditions',
  'contact us',
  'return policy',
  'privacy policy',
  'shop-list',
  'editName',
  'emailUs',
  'reviews',
  'review images',
  'collections',
  'myorders',
  'order details',
  'help',
  'delivery status',
  'wallet faq',
];
const navPagesWithCart = ['product'];
const navPageWithCartAndFilter = ['list', 'categories'];
const navPageWithFaq = ['wallet'];

export const getHeaderState = page => {
  if (simpleNavPages.indexOf(page) > -1) {
    return {
      navigationButton: true,
      pageTitle: true,
      cartButton: false,
      filterButton: false,
      faqButton: false,
    };
  }
  if (navPagesWithCart.indexOf(page) > -1) {
    return {
      navigationButton: true,
      pageTitle: true,
      cartButton: true,
      filterButton: false,
      faqButton: false,
    };
  }
  if (navPageWithCartAndFilter.indexOf(page) > -1) {
    return {
      navigationButton: true,
      pageTitle: true,
      cartButton: true,
      filterButton: true,
      faqButton: false,
    };
  }
  if (navPageWithFaq.indexOf(page) > -1) {
    return {
      navigationButton: true,
      pageTitle: true,
      cartButton: false,
      filterButton: false,
      faqButton: true,
    };
  }

  return initialState;
};

const headerLoadingStates = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
};

const Header = ({ history, location }) => {
  const pageType = getPage(location);
  const [loading, setLoading] = useState(headerLoadingStates.LOADED);
  const {
    searchText,
    categoryName,
    profile,
    collection,
    filters,
    homeLoadingState,
  } = useSelector(({ shop }) => shop);
  const { productDetails, productDetailsLoading } = useSelector(
    ({ product }) => product
  );
  const orderId = useSelector(({ orders }) => orders?.currentOrder?.purchaseId);
  const { itemCount, shoppingListItems: products } = useSelector(
    ({ cart }) => cart.shoppingList
  );
  const {
    store: storeData = {},
    loadingState: storeLoadingState,
  } = useSelector(({ store }) => store ?? {});
  const { loadingState: listingLoadingState } = useSelector(({ ui }) => ui);

  const { bannerName } = storeData ?? { bannerName: '' };
  const [displayText, setDisplayText] = useState('');
  const [navigationLink, setNavigationLink] = useState('');
  const userState = useSelector(({ user }) => user.userState);
  const testRef = React.useRef(null);
  const dispatch = useDispatch();
  const [pageState, setPageState] = useState(initialState);

  useEffect(() => {
    setLoading(headerLoadingStates.LOADING);

    const state = getHeaderState(pageType);
    const category = location.pathname?.split('/')?.reverse()[0];
    let updatedDisplayText = '';

    // console.log('page changing', { displayText, pageType });

    switch (pageType) {
      case 'signin':
      case 'signin-verification':
        if (testRef.current) {
          testRef.current.style.display = 'none';
        }
        break;

      case 'stores':
        updatedDisplayText = 'All Stores';
        state.navigationButton = true;
        break;

      case 'storeproducts':
        // debugger;
        updatedDisplayText = bannerName || 'Store Product Name';
        state.navigationButton = true;
        break;

      case 'collections':
        updatedDisplayText = collection?.title || '';
        state.navigationButton = false;
        break;

      case 'share':
        updatedDisplayText = 'Share';
        break;

      case 'about shop':
        updatedDisplayText = 'About Shop';
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        break;

      case 'wallet faq':
        updatedDisplayText = 'Frequently Asked Questions';
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        break;

      case 'wallet':
        updatedDisplayText = 'My Wallet';
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        break;

      case 'product':
        updatedDisplayText = productDetails?.productTitle;
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        break;

      case 'list':
        if (['-1', '-2', '0'].indexOf(category) > -1) {
          state.filterButton = false;
        }

        if (searchText) {
          updatedDisplayText = searchText;
        } else {
          updatedDisplayText = categoryName;
        }

        break;

      case 'categories':
        updatedDisplayText = categoryName;
        break;

      case 'shop-list':
        updatedDisplayText = 'Recently Visited';
        state.navigationButton = false;
        break;

      case 'cart':
        updatedDisplayText = 'My Cart';
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        break;

      case 'address':
        updatedDisplayText = 'Address';
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        break;

        case 'edit address':
          updatedDisplayText = 'Address';
          if (testRef.current) {
            testRef.current.style.display = 'block';
          }
          break;

      case 'checkout address':
        updatedDisplayText = 'Address';
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        break;

      case 'payment':
        updatedDisplayText = 'Payment';
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        break;

      case 'verification':
        updatedDisplayText = 'Verify your Order';
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        break;

      case 'reviews':
        updatedDisplayText = 'Ratings & Reviews';
        break;

      case 'review images':
        updatedDisplayText = 'Customer Images';
        break;

      case 'myorders':
        updatedDisplayText = 'My Orders';
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        break;

      case 'order details':
        updatedDisplayText = orderId && `Order ID ${orderId}`;
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        break;

      case 'help':
        updatedDisplayText = 'Help';
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        break;

      case 'about':
      case 'terms and conditions':
      case 'contact us':
      case 'return policy':
      case 'privacy policy':
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        updatedDisplayText = pageType;
        if (pageType === 'terms and conditions') {
          updatedDisplayText = 'Terms and Conditions';
        }

        if (pageType === 'contact us') {
          updatedDisplayText = 'Contact Us';
        }

        if (pageType === 'about') {
          updatedDisplayText = 'About';
        }
        if (pageType === 'return policy') {
          updatedDisplayText = 'Return Policy';
        }
        if (pageType === 'privacy policy') {
          updatedDisplayText = 'Privacy Policy';
        }
        break;

      case 'know more':
        updatedDisplayText = 'Know More About';
        break;

      case 'editName':
        updatedDisplayText = 'Edit Name';
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        break;

      case 'manageAddress':
        updatedDisplayText = 'Manage Address';
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        break;

      case 'emailUs':
        updatedDisplayText = 'Contact Us';
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        break;

      case 'order success':
        if (testRef.current) {
          testRef.current.style.display = 'none';
        }
        break;
      case 'profile':
        if (testRef.current) {
          testRef.current.style.display = 'none';
        }
        break;

      case 'delivery status':
        updatedDisplayText = 'Delivery Status';
        if (testRef.current) {
          testRef.current.style.display = 'block';
        }
        break;

      default:
        updatedDisplayText = 'MyShopPrime';
        break;
    }
    setDisplayText(updatedDisplayText);
    setLoading(headerLoadingStates.LOADED);
    setPageState(state);
  }, [
    location,
    productDetails,
    profile,
    categoryName,
    collection,
    orderId,
    filters,
    bannerName,
  ]);

  let showCartCount = false;
  let cartCount = 0;

  if (products && products.length > 0) {
    showCartCount = true;
    cartCount = products.length;
  }

  if (itemCount !== undefined && itemCount !== 0) {
    showCartCount = true;
    cartCount = itemCount;
  }

  const openAttributesFilter = () => {
    dispatch(showAttributesFilterAction(true));
  };

  const goToCart = () => {
    // window.clevertap.event.push('msp cart icon clicked');
    history.push('/checkout/cart');
  };

  const goToFaq = () => {
    history.push('/wallet-faq');
  };

  let showHeaderText = loading === headerLoadingStates.LOADED;
  if (pageType === 'storeproducts') {
    showHeaderText = storeLoadingState.toLowerCase() !== 'loading';
  }
  if (pageType === 'list') {
    showHeaderText = listingLoadingState.toLowerCase() !== 'loading';
  }
  if (pageType === 'product') {
    showHeaderText = !productDetailsLoading;
  }

  return (
    <Wrapper ref={testRef} className='z-bottomNav'>
      <div className='flex flex-row items-start justify-between h-bottomNav px-2'>
        <div className='min-w-185px w-auto h-full'>
          <Navigation
            displayText={displayText}
            showNavigation={pageState.navigationButton}
            navigationLink={navigationLink}
            userState={userState}
            showHeaderText={showHeaderText}
            searchText={searchText}
          />
        </div>

        <div className='flex flex-row items-center justify-start h-full pr-2'>
          {pageState.filterButton && filters?.others?.length > 0 && (
            <HeaderButtons>
              <IconButton onClick={openAttributesFilter}>
                <Icon icon='filter' color='#c41e46' size='22px' />
              </IconButton>
            </HeaderButtons>
          )}

          {pageState.cartButton && (
            <HeaderButtons>
              <IconButton
                onClick={goToCart}
                className='relative w-48px flex flex-row items-center font-normal'>
                <Icon icon='cart' color='#2e2e2e' size='24px' />
                {showCartCount && (
                  <span
                    className='absolute bg-red-700 text-white text-xxs rounded-full px-1 flex items-center justify-center font-normal h-4'
                    style={{ top: '4px', right: '3px' }}>
                    {cartCount}
                  </span>
                )}
              </IconButton>
            </HeaderButtons>
          )}
          {pageState.faqButton && (
            <HeaderButtons>
              <IconButton
                onClick={goToFaq}
                className='relative w-48px flex flex-row items-center font-normal'>
                FAQ
              </IconButton>
            </HeaderButtons>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default withRouter(Header);
