// @flow
// eslint-disable-next-line import/no-cycle
import createReducer from 'config/reducers';
import rootSaga from 'config/sagas';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
// import { logger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const middlewares = [];
middlewares.push(sagaMiddleware);

if (process.env.NODE_ENV === `development`) {
  // middlewares.push(logger);
}

export default function configureStore() {
  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    typeof window === 'object' &&
      process.env.NODE_ENV !== 'production' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
      : compose;

  const store = createStore(
    createReducer(history),
    composeEnhancers(applyMiddleware(...middlewares))
  );

  // Extensions
  sagaMiddleware.run(rootSaga);

  return store;
}
