/**
 * Gets the repositories of the user from Github
 */

import { call, put, select, takeLatest } from 'redux-saga/effects'; //eslint-disable-line
import request from 'utils/request';
import requestForm from 'utils/requestForm';
import { fetchItemsSuccessful } from 'screens/Cart/actions';
import { API_BASE_URL } from 'utils/constants';
import * as Sentry from '@sentry/react';
import { checkOtp } from 'screens/Otp/saga';
import {
  redirectCartSuccessful,
  redirectCartFailed,
  fetchPaymentDetailsSuccessful,
  fetchPaymentDetailsFailed,
} from './actions';

import { REDIRECT_CART, FETCH_PAYMENT_DETAILS } from './constants';
import { decryptString } from 'utils/intransitUtils';

export function* fetchPaymentDetails() {
  const requestUrl = `${API_BASE_URL}/checkout/paymentdetails`;
  try {
    const responseData = yield call(request, requestUrl);
    yield put(fetchPaymentDetailsSuccessful(responseData.paymentMethods));
    // yield put(fetchItemsSuccessful(responseData.shoppingCart));
  } catch (err) {
    Sentry.captureException(new Error(err));
    yield put(fetchPaymentDetailsFailed(err));
  }
}

export function* redirectCart({ mode, history }) {
  const requestUrl = `${API_BASE_URL}/payment/redirectcart`;
  const data = `mode=${mode}`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    credentials: 'include',
    body: data,
  };
  try {
    const encData = yield call(requestForm, requestUrl, options);
    const responseData = decryptString(encData);
    // console.log('Redirect cart response: ', responseData);
    if (responseData.result === 'success') {
      if (responseData.mode === 'COD') {
        // call otp method
        // debugger;
        yield* checkOtp({ history });
        // history.push('/checkout/payment/otp');
      } else {
        yield put(redirectCartSuccessful(responseData));
      }
    } else {
      Sentry.captureException(new Error('Redirect failed'));
      yield put(redirectCartFailed());
    }
  } catch (err) {
    console.log(err);
    Sentry.captureException(new Error(err));
    yield put(redirectCartFailed(err));
  }
}

export default function* paymentWatcherSaga() {
  yield takeLatest(FETCH_PAYMENT_DETAILS, fetchPaymentDetails);
  yield takeLatest(REDIRECT_CART, redirectCart);
}
