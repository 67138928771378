import {
  GET_WALLET_TRANSACTIONS,
  GET_WALLET_TRANSACTIONS_SUCCESSFUL,
  GET_WALLET_TRANSACTIONS_FAILURE,
  RELOAD_WALLET_TRANSACTIONS,
  RELOAD_WALLET_TRANSACTIONS_SUCCESSFUL,
  RELOAD_WALLET_TRANSACTIONS_FAILURE,
  SET_WALLET_LOADING_STATE,
} from './constants';

export function getWalletTransactions() {
  return {
    type: GET_WALLET_TRANSACTIONS,
  };
}

export function getWalletTransactionsSuccessful(data) {
  return {
    type: GET_WALLET_TRANSACTIONS_SUCCESSFUL,
    data,
  };
}

export function getWalletTransactionsFailure(error) {
  return {
    type: GET_WALLET_TRANSACTIONS_FAILURE,
    error,
  };
}

export function reloadWalletTransactions({ pageNo }) {
  return {
    type: RELOAD_WALLET_TRANSACTIONS,
    pageNo,
  };
}

export function reloadWalletTransactionsSuccessful(data) {
  return {
    type: RELOAD_WALLET_TRANSACTIONS_SUCCESSFUL,
    data,
  };
}

export function reloadWalletTransactionsFailure(error) {
  return {
    type: RELOAD_WALLET_TRANSACTIONS_FAILURE,
    error,
  };
}

export function setWalletLoadingState({ walletLoadingState }) {
  return {
    type: SET_WALLET_LOADING_STATE,
    walletLoadingState,
  };
}
