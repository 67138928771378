import produce from 'immer';
import { CHECK_OTP_FAILED, CHECK_OTP_SUCCESSFUL } from 'screens/Otp/constants';

import {
  FETCH_PAYMENT_DETAILS,
  FETCH_PAYMENT_DETAILS_FAILED,
  FETCH_PAYMENT_DETAILS_SUCCESSFUL,
  REDIRECT_CART,
  REDIRECT_CART_FAILED,
  REDIRECT_CART_SUCCESSFUL,
  RESET_PAYMENT,
  SET_LOADING_STATE,
  SET_COD_SIGNIN,
} from './constants';

export const initialState = {
  gatewayOption: {},
  mode: null,
  loading: false,
  error: null,
  paymentOptionsLoading: false,
  otp: '',
  verifyOtpSuccess: false,
  verifyOtpFailure: false,
  codSignin: false,
};

const paymentReducer = produce((draft, action) => {
  switch (action.type) {
    case REDIRECT_CART: {
      draft.loading = true;
      draft.error = null;
      break;
    }
    case REDIRECT_CART_SUCCESSFUL: {
      draft.loading = true;
      draft.paymentOptionsLoading = false;
      draft.error = null;
      draft.gatewayOption = action.data.gatewayOption;
      draft.mode = action.data.mode;
      break;
    }
    case REDIRECT_CART_FAILED: {
      draft.loading = false;
      draft.paymentOptionsLoading = false;
      draft.error = action.err ?? true;
      break;
    }
    case FETCH_PAYMENT_DETAILS: {
      draft.paymentOptionsLoading = true;
      draft.error = null;
      break;
    }
    case FETCH_PAYMENT_DETAILS_SUCCESSFUL: {
      draft.paymentOptionsLoading = false;
      draft.loading = false;
      draft.error = null;
      draft.paymentOptions = action.data;
      break;
    }
    case FETCH_PAYMENT_DETAILS_FAILED: {
      draft.paymentOptionsLoading = false;
      draft.loading = false;
      draft.error = action.err;
      break;
    }
    case CHECK_OTP_SUCCESSFUL: {
      draft.verifyOtpSuccess = true;
      draft.verifyOtpFailure = false;
      draft.loading = false;
      return draft;
    }
    case CHECK_OTP_FAILED: {
      draft.verifyOtpSuccess = false;
      draft.verifyOtpFailure = true;
      draft.loading = false;
      return draft;
    }
    case SET_COD_SIGNIN: {
      draft.codSignin = action.codSignin;
      return draft;
    }
    case RESET_PAYMENT: {
      draft.loading = false;
      draft.mode = null;
      return draft;
    }

    // case SET_LOADING_STATE: {
    //   draft.loading = action.payload.loading;
    //   return draft;
    // }
  }
}, initialState);

export default paymentReducer;
