// @flow

import iconSet from 'fonts/selection.json';
import IcomoonReact from 'icomoon-react';
import * as React from 'react';

type TProps = {
  color?: string,
  size?: string,
  icon: string,
  className?: string,
  background?: string,
  id?: String,
};

const Icon = ({
  color = '#2e2e2e',
  size = '36px',
  icon,
  className = '',
  background = '',
  id= '',
}: TProps) => {
  return (
    <IcomoonReact
      className={className}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
      background={background}
      id={id}
    />
  );
};

export default Icon;
