// @flow
import { css } from 'styled-components';

export const RedButtonStyles = css`
  color: ${props => props.theme.colors.red};
  background-color: white;
  border: 1px solid ${props => props.theme.colors.defaultBackgroundColor};
  font-weight: 600;
  &:visited,
  &:hover {
    color: ${props => props.theme.colors.red};
  }
  ${props =>
    props.inverted &&
    css`
      color: white;
      background-color: ${props.theme.colors.red};
      border: 1px solid ${props.theme.colors.red};
      &:visited,
      &:hover {
        color: white;
      }
    `}

  &.active {
    color: white;
    background-color: ${props => props.theme.colors.red};
    border: 1px solid ${props => props.theme.colors.red};
    &:visited,
    &:hover {
      color: white;
    }
  }
`;

export const WhatsappGreenButtonStyles = css`
  color: white;
  background-color: ${props => props.theme.colors.whatsappGreen} !important;
  border: 1px solid ${props => props.theme.colors.whatsappGreen} !important;
  ${props =>
    props.inverted &&
    css`
      color: ${props.theme.colors.whatsappGreen} !important;
      background-color: white !important;
      border: 1px solid ${props.theme.colors.whatsappGreen} !important;
    `}
`;

export const FacebookBlueButtonStyles = css`
  color: white;
  background-color: ${props => props.theme.colors.facebookBlue} !important;
  border: 1px solid ${props => props.theme.colors.facebookBlue} !important;
  ${props =>
    props.inverted &&
    css`
      color: ${props.theme.colors.facebookBlue} !important;
      background-color: white !important;
      border: 1px solid ${props.theme.colors.facebookBlue} !important;
    `};
`;
