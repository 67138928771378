import produce from 'immer';

import {
  LOAD_REVIEW_IMAGES_FAILED,
  LOAD_REVIEW_IMAGES_SUCCESS,
  RELOAD_REVIEW_IMAGES_FAILED,
  RELOAD_REVIEW_IMAGES_SUCCESS,
  TOGGLE_REVIEW_IMAGES_LOADING,
  TOGGLE_REVIEW_IMAGES_RELOADING,
} from './constants';

const initialReviewImagesState = {
  isLoading: false,
  isLoadingFailed: false,
  isReloading: false,
  isReloadingFailed: false,
  errorMssg: '',
  pageNo: 1,
};

export default produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_REVIEW_IMAGES_SUCCESS: {
      const { productImage, productTitle, productRatingImages } = payload.data;

      draft.productImage = productImage;
      draft.productRatings = productRatingImages;
      draft.productTitle = productTitle;
      draft.pageNo = payload.data.pageNo;

      draft.isLoadingFailed = false;
      draft.errorMssg = '';
      return draft;
    }

    case LOAD_REVIEW_IMAGES_FAILED: {
      draft.isLoadingFailed = true;
      draft.errorMssg = payload.data.mssg;
      return draft;
    }

    case RELOAD_REVIEW_IMAGES_SUCCESS: {
      draft.isReloadingFailed = false;
      draft.errorMssg = '';

      const { productRatingImages, pageNo } = payload.data;

      if (productRatingImages?.length > 0) {
        productRatingImages.forEach(image => {
          draft.productRatings.push(image);
        });
      }

      draft.pageNo = pageNo;

      return draft;
    }

    case RELOAD_REVIEW_IMAGES_FAILED: {
      draft.isReloadingFailed = true;
      draft.errorMssg = payload.data.mssg;
      return draft;
    }

    case TOGGLE_REVIEW_IMAGES_LOADING: {
      draft.isLoading = payload.isLoading;
      return draft;
    }

    case TOGGLE_REVIEW_IMAGES_RELOADING: {
      draft.isReloading = payload.isReloading;
      return draft;
    }

    default:
      return draft;
  }
}, initialReviewImagesState);
