import {
  LOAD_CATEGORIES,
  LOAD_CATEGORIES_FAILURE,
  LOAD_CATEGORIES_SUCCESS,
} from 'screens/Categories/constants';

export const loadCategoriesAction = ({ url, token, shopName }) => ({
  type: LOAD_CATEGORIES,
  payload: {
    url,
    token,
    shopName,
  },
});

export const loadCategoriesSuccess = data => ({
  type: LOAD_CATEGORIES_SUCCESS,
  payload: {
    ...data,
  },
});

export const loadCategoriesFailure = () => ({
  type: LOAD_CATEGORIES_FAILURE,
});
