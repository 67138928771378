export const theme = {
  colors: {
    black: '#000000',
    red: '#c41e46',
    whatsappGreen: '#4ac959',
    facebookBlue: '#4267b2',
    default: '#dae1e3',
    defaultLightText: '#9397a3',
    defaultDarkText: '#2e2e2e',
    defaultLightBg: '#f3f4f5',
    borderColor: '#949a9c',
    defaultBorderColor: '#e9e9ed',
    shadowColor: '#000',
    gradientColorStart: '#ca1e3f',
    gradientColorEnd: '#321ce2',
    highlightedTextColor: '#676c76',
    bannerGradient: 'linear-gradient(90deg, #ca1e3f,#321ce2);',
    errorColor: '#e62424',
    discountPriceBg: '#f0f8e6',
    ratingsExcellent: '#13aa02',
    ratingsGood: '#ffce33',
    ratingsBad: '#e62424',
    timerBackground: '#d92547ee',
  },
  primary: {
    fontColor: '#000000',
    disabledFontColor: '#a8a8a8',
    backgroundColor: '#ffffff',
    borderColor: '#eee',
    hover: {
      fontColor: '#000000',
      backgroundColor: '#ffffff',
    },
    active: {
      fontColor: '#000000',
      backgroundColor: '#ffffff',
    },
  },
  secondary: {
    fontColor: '#000000',
    disabledFontColor: '#a8a8a8',
    backgroundColor: '#ffffff',
    hover: {
      fontColor: '#000000',
      backgroundColor: '#ffffff',
    },
    active: {
      fontColor: '#000000',
      backgroundColor: '#ffffff',
    },
  },
  fontWeights: {
    extraLight: '100',
    light: '250',
    semiLight: '300',
    normal: '400',
    semiBold: '550',
    bold: '700',
    extraBold: '800',
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '1rem',
    lg: '1.25rem',
    xl: '2rem',
    xxl: '2.5rem',
  },
  spacing: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '1.25rem',
    xl: '1.75rem',
    xxl: '2.25rem',
  },
  layout: {
    headerHeight: '54px',
    headerHeightWithOffset: '72px',
    borderRadius: '4px',
    sidebarWidth: '300px',
    bannerSetHeight: '32px',
  },
};
