// @flow

import {
  HIDE_TOAST,
  LOAD_MAIN_SHOP,
  LOAD_MAIN_SHOP_FAILED,
  LOAD_MAIN_SHOP_SUCCESS,
  LOAD_NEW_SHOP,
  LOAD_USER_STATE,
  LOGIN,
  LOGOUT,
  NAME_CHANGED_SUCCESSFULLY,
  SET_SIGNIN_PHONE,
  SET_IS_WEBVIEW,
  RELOAD_EXISTING_SHOP,
  RELOAD_EXISTING_SHOP_FAILED,
  RELOAD_EXISTING_SHOP_SUCCESS,
  RELOAD_MAIN_SHOP,
  RESET_SHOP_LOADING_INITIAL_STATE,
  SET_HOME_FIRST_LOAD,
  SET_HOME_LOADING_STATE,
  SET_LISTING_FIRST_LOAD,
  SET_LOADING_STATE,
  SET_SCROLL_POSITION,
  SET_SHOP_SEARCH_TEXT,
  SHOW_TOAST,
  TOGGLE_PAGE_LOADING,
  TOGGLE_SHOP_LOADING,
  TOGGLE_SHOP_RELOADING,
  TOGGLE_SIDEBAR,
  UPDATE_SHOP_PROFILE,
  TOGGLE_OPEN_WHATSAPP,
  SET_APP_SECRETS,
} from 'utils/constants';

type TAction = {
  type: string,
  payload: any,
};

export const setSearchTextAction = (
  history: any,
  location: any,
  searchText: string
): TAction => ({
  type: SET_SHOP_SEARCH_TEXT,
  payload: {
    searchText,
    location,
    history,
  },
});

export const loadUserStateAction = (payload: any): TAction => ({
  type: LOAD_USER_STATE,
  payload,
});

export const showPageLoadingAction = (payload: any): TAction => ({
  type: TOGGLE_PAGE_LOADING,
  payload,
});

export const showShopLoadingAction = (payload: any): TAction => ({
  type: TOGGLE_SHOP_LOADING,
  payload,
});

export const setLoadingState = (payload: any): TAction => ({
  type: SET_LOADING_STATE,
  payload,
});

export const setHomeLoadingState = (payload: any): TAction => ({
  type: SET_HOME_LOADING_STATE,
  payload,
});

export const setHomeFirstLoadState = (payload: any): TAction => ({
  type: SET_HOME_FIRST_LOAD,
  payload,
});

export const setListingFirstLoadState = (payload: any): TAction => ({
  type: SET_LISTING_FIRST_LOAD,
  payload,
});

export const showShopReloadingAction = (payload: any): TAction => ({
  type: TOGGLE_SHOP_RELOADING,
  payload,
});

export const showShopSidebarAction = (payload: any): TAction => ({
  type: TOGGLE_SIDEBAR,
  payload,
});

export const setOpenWhatsapp = openWhatsapp => ({
  type: TOGGLE_OPEN_WHATSAPP,
  payload: {
    openWhatsapp,
  },
});

export const setAppSecrets = secrets => ({
  type: SET_APP_SECRETS,
  payload: {
    secrets,
  },
});

export const loadShopStateAction = (payload: any): TAction => ({
  type: LOAD_MAIN_SHOP,
  payload,
});

export const loadNewShopStateAction = (payload: any): TAction => ({
  type: LOAD_NEW_SHOP,
  payload,
});

export const loadShopStateSuccessAction = (payload: any): TAction => ({
  type: LOAD_MAIN_SHOP_SUCCESS,
  payload,
});

export const loadShopStateFailedAction = (payload: any): TAction => ({
  type: LOAD_MAIN_SHOP_FAILED,
  payload,
});

export const reloadShopStateAction = (payload: any): TAction => ({
  type: RELOAD_MAIN_SHOP,
  payload,
});

export const updateShopProfileAction = (payload: any): TAction => ({
  type: UPDATE_SHOP_PROFILE,
  payload,
});

export const reloadExistingShopAction = payload => ({
  type: RELOAD_EXISTING_SHOP,
  payload,
});

export const reloadExistingShopActionSuccess = payload => ({
  type: RELOAD_EXISTING_SHOP_SUCCESS,
  payload,
});

export const reloadExistingShopActionFailed = payload => ({
  type: RELOAD_EXISTING_SHOP_FAILED,
  payload,
});

export const setScrollState = scrollPos => ({
  type: SET_SCROLL_POSITION,
  payload: {
    scrollPos,
  },
});

export const setNewSearchTextAction = (history, text, url) => ({
  type: 'SET_NEW_SEARCH_TEXT',
  payload: {
    history,
    text,
    url,
  },
});

export const showToastAction = () => ({
  type: SHOW_TOAST,
});

export const hideToastAction = () => ({
  type: HIDE_TOAST,
});

export const resetShopLoadingInitialStateAction = () => ({
  type: RESET_SHOP_LOADING_INITIAL_STATE,
});

export const UPDATE_ALL_PRODUCTS = 'app/UPDATE_ALL_PRODUCTS';

export function updateAllProductsAction(payload) {
  return {
    type: UPDATE_ALL_PRODUCTS,
    payload,
  };
}

export const loginUserAction = payload => ({
  type: LOGIN,
  payload,
});

export const logoutUserAction = () => ({
  type: LOGOUT,
});

export const setSigninPhone = (phone) => ({
  type:SET_SIGNIN_PHONE,
  phone,
});

export const setIsWebview = (isWebview) => ({
  type:SET_IS_WEBVIEW,
  isWebview,
});

export const changeUserNameAction = ({ name, history }) => ({
  type: NAME_CHANGED_SUCCESSFULLY,
  name,
  history,
});
