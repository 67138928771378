import * as Sentry from '@sentry/react';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API_BASE_URL } from 'utils/constants';
import { dateDiff } from 'utils/helpers';
import request from 'utils/request';
import {handleEncryption} from 'utils/request';
import {decryptString} from 'utils/intransitUtils';

import {
  addReturnImage,
  clearReturnImage,
  deleteReturnImage,
  getCancelOrderReasonsFailure,
  getCancelOrderReasonsSuccessful,
  getOrderDetailsFailure,
  getOrderDetailsSuccessful,
  getOrdersFailure,
  getOrdersSuccessful,
  getReturnOrderReasonsFailure,
  getReturnOrderReasonsSuccessful,
  reloadOrdersFailure,
  reloadOrdersSuccessful,
  returnOrderPopupToggle,
  setBankDetails,
  setImageLoadingState,
  setOrdersLoadingState,
} from './actions';
import {
  CANCEL_ORDER,
  DELETE_RETURN_ORDER_IMAGE,
  GET_ORDER_DETAILS,
  GET_ORDERS,
  RELOAD_ORDERS,
  RETURN_ORDER,
  SAVE_COD_REFUND_DETAILS,
  UPLOAD_RETURN_ORDER_IMAGE,
} from './constants';

export function* reloadOrders({ lastOrderId }) {
  const requestUrl = `${API_BASE_URL}/rest/myorders/list`;
  try {
    yield put(setOrdersLoadingState({ ordersLoadingState: 'reloading' }));
    const options = {
      method: 'POST',
      body: JSON.stringify({ lastOrderId }),
      headers: {
        'Content-Type': 'application/json',
        apiVersion: '5',
      },
    };
    const responseData = yield call(request, requestUrl, options);
    window.scroll(0,window.scrollY - 200);
    if (responseData.result === 'success') {
      const { data } = responseData;
      if (Object.keys(data)?.length > 0 && data?.orders?.length > 0) {
        yield put(reloadOrdersSuccessful({ data }));
      }
    }
    yield put(setOrdersLoadingState({ ordersLoadingState: 'loaded' }));
  } catch (err) {
    console.log(err);
    Sentry.captureException(new Error(err));
    yield put(reloadOrdersFailure({ err }));
    yield put(setOrdersLoadingState({ ordersLoadingState: 'loaded' }));
  }
}

export function* getOrders() {
  const requestUrl = `${API_BASE_URL}/rest/myorders/list`;
  try {
    yield put(setOrdersLoadingState({ ordersLoadingState: 'loading' }));
    const options = {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        apiVersion: '5',
      },
    };
    const responseData = yield call(request, requestUrl, options);
    if (responseData.result === 'success') {
      const { data } = responseData;
      yield put(getOrdersSuccessful({ data }));
      if (data?.orders?.length === 5) {
        yield* reloadOrders({
          lastOrderId: data.orders[data.orders.length - 1].orderId,
        });
      }
    }
    yield put(setOrdersLoadingState({ ordersLoadingState: 'loaded' }));
  } catch (err) {
    console.log(err);
    Sentry.captureException(new Error(err));
    yield put(getOrdersFailure({ err }));
    yield put(setOrdersLoadingState({ ordersLoadingState: 'loaded' }));
  }
}

export function* getCancelReasons() {
  const requestUrl = `${API_BASE_URL}/rest/orders/getcancelreasons`;
  const options = {
    method: 'GET',
  };
  try {
    const responseData = yield call(request, requestUrl, options);
    const { data, result } = responseData;
    const { reasons } = data;
    if (result === 'success') {
      yield put(getCancelOrderReasonsSuccessful({ reasons }));
    }
  } catch (error) {
    console.log(error);
    Sentry.captureException(new Error(error));
    yield put(getCancelOrderReasonsFailure({ error }));
  }
}

export function* getReturnReasons({orderId}) {
  const requestUrl = `${API_BASE_URL}/rest/orders/getreturnreasons`;
  const body = JSON.stringify({orderId});
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  };
  try {
    const responseData = yield call(request, requestUrl, options);
    const { data, result } = responseData;
    const { reasons } = data;
    if (result === 'success') {
      yield put(getReturnOrderReasonsSuccessful({ reasons }));
    }
  } catch (error) {
    console.log(error);
    Sentry.captureException(new Error(error));
    yield put(getReturnOrderReasonsFailure({ error }));
  }
}

export function* getBankDetails() {
  const requestUrl = `${API_BASE_URL}/rest/user/bankDetails`;
  const options = {
    method: 'GET',
  };
  try {
    const encData = yield call(request, requestUrl, options);
    const responseData = decryptString(encData);
    const { data, result } = responseData;
    if (result === 'success') {
      yield put(setBankDetails({ payload: data }));
    }
  } catch (error) {
    console.log(error);
    Sentry.captureException(new Error(error));
  }
}

export function* getOrderDetails({ orderId, return: isReturn }) {
  const requestUrl = `${API_BASE_URL}/rest/order/singleorder`;
  yield put(setOrdersLoadingState({ ordersLoadingState: 'loading' }));
  yield put(getOrderDetailsSuccessful({ currentOrder: {} }));
  yield put(getCancelOrderReasonsSuccessful({ reasons: [] }));
  yield put(getReturnOrderReasonsSuccessful({ reasons: [] }));
  yield put(setBankDetails({ payload: {} }));
  yield put(clearReturnImage());
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify({ orderId }),
      headers: {
        'Content-Type': 'application/json',
        apiVersion: '5',
      },
    };

    const encData = yield call(request, requestUrl, options);
    const responseData = decryptString(encData);
    if (responseData.result === 'success') {
      const { data } = responseData;
      yield put(getOrderDetailsSuccessful({ currentOrder: data.orders.order }));
      if (data.orders.order.orderStatus === 'Delivered') {
        const deliveryDateDiff = dateDiff(
          data.orders.order.lifecycle[4].timestamp
        );
        if (deliveryDateDiff <= 7 && data.orders.order.isReturnEligible) {
          yield* getReturnReasons({orderId});
          if (data.orders.order.paymentType === 'COD') {
            yield* getBankDetails();
          }
        }
      } else if (data.orders.order.orderStatus === 'Placed') {
        yield* getCancelReasons();
      } else if (
        isReturn &&
        data.orders.order.orderStatus === 'Return Requested'
      ) {
        yield put(returnOrderPopupToggle({ returnStatus: true }));
      }
      yield put(setOrdersLoadingState({ ordersLoadingState: 'loaded' }));
    } else {
      yield put(setOrdersLoadingState({ ordersLoadingState: 'failure' }));
    }
  } catch (error) {
    console.log(error);
    Sentry.captureException(new Error(error));
    yield put(getOrderDetailsFailure({ error }));
    // yield put(getOrdersFailure({ err }));
    yield put(setOrdersLoadingState({ ordersLoadingState: 'failure' }));
  }
}

export function* cancelOrder({ orderId, reasonCode }) {
  const requestUrl = `${API_BASE_URL}/rest/orders/cancel`;
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify({ orderId, reasonCode }),
      headers: {
        'Content-Type': 'application/json',
        apiVersion: '5',
      },
    };
    const responseData = yield call(request, requestUrl, options);
    if (responseData.result === 'success') {
      yield* getOrderDetails({ orderId });
    }
  } catch (error) {
    console.log(error);
    Sentry.captureException(new Error(error));
  }
}

export function* returnOrder({ orderId, reasonCode }) {
  const requestUrl = `${API_BASE_URL}/rest/orders/return`;
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify({ orderId, reasonCode }),
      headers: {
        'Content-Type': 'application/json',
        apiVersion: '5',
      },
    };
    const responseData = yield call(request, requestUrl, options);
    if (responseData.result === 'success') {
      yield put(clearReturnImage());
      yield* getOrderDetails({ orderId, return: true });
    }
  } catch (error) {
    console.log(error);
    Sentry.captureException(new Error(error));
  }
}

export function* uploadReturnOrderImage({ payload }) {
  yield put(setImageLoadingState({ imageLoadingState: true }));
  const requestUrl = `${API_BASE_URL}/rest/orders/returnImageUpload`;
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify({ ...payload }),
      headers: {
        'Content-Type': 'application/json',
        apiVersion: '5',
      },
    };
    const responseData = yield call(request, requestUrl, options);
    if (responseData.result === 'success') {
      yield put(
        addReturnImage({
          returnOrderImageId: responseData.returnOrderImageId,
          fileData: payload.fileData,
        })
      );
    }
    yield put(setImageLoadingState({ imageLoadingState: false }));
  } catch (error) {
    console.log(error);
    yield put(setImageLoadingState({ imageLoadingState: false }));
    Sentry.captureException(new Error(error));
  }
}

export function* deleteReturnOrderImage({ returnOrderImageId }) {
  const requestUrl = `${API_BASE_URL}/rest/orders/deleteReturnImage`;
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify({ returnOrderImageId }),
      headers: {
        'Content-Type': 'application/json',
        apiVersion: '5',
      },
    };
    const responseData = yield call(request, requestUrl, options);
    if (responseData.result === 'success') {
      yield put(
        deleteReturnImage({
          returnOrderImageId,
        })
      );
    }
  } catch (error) {
    console.log(error);
    Sentry.captureException(new Error(error));
  }
}

export function* saveCODRefundDetails({ payload, orderId, reasonCode, random }) {
  const requestUrl = `${API_BASE_URL}/rest/orders/codRefundDetails`;
  const encData = handleEncryption(JSON.stringify({ orderId, ...payload }),random);
  if(encData){
    try {
      const options = {
        method: 'POST',
        body: JSON.stringify({ md: encData }),
        headers: {
          'Content-Type': 'application/json',
          apiVersion: '5',
        },
      };
      const responseData = yield call(request, requestUrl, options);
      if (responseData.result === 'success') {
        yield* returnOrder({ orderId, reasonCode });
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(new Error(error));
    }
  } else {
    alert("Encryption Failed");
  }
  
}

export default function* ordersWatcherSaga() {
  yield takeLatest(GET_ORDERS, getOrders);
  yield takeLatest(RELOAD_ORDERS, reloadOrders);
  yield takeLatest(GET_ORDER_DETAILS, getOrderDetails);
  yield takeLatest(CANCEL_ORDER, cancelOrder);
  yield takeLatest(UPLOAD_RETURN_ORDER_IMAGE, uploadReturnOrderImage);
  yield takeLatest(DELETE_RETURN_ORDER_IMAGE, deleteReturnOrderImage);
  yield takeLatest(SAVE_COD_REFUND_DETAILS, saveCODRefundDetails);
  yield takeLatest(RETURN_ORDER, returnOrder);
}
