import React, { useEffect, useLayoutEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// import { sendCleverTapEvent } from 'utils/clevertapEvents';
import { IS_PRODUCTION } from 'utils/constants';
import { getPage, useAppDataHook, useLocalStorage } from 'utils/helpers';
import { generateKeys } from 'utils/intransitUtils';

const headerExclusionList = [
  'order success',
  'notfound',
  'search',
  'newShop',
  'signin',
  'signin verfication',
  'selfFulfillmentOrders',
];

const bottomNavExclusionList = [
  'product',
  'collections',
  'list',
  'cart',
  'payment',
  'signin',
  'signin verfication',
  'checkout address',
  'manageAddress',
  'address',
  'edit address',
  'order details',
  'order success',
  'shop-list',
  'about',
  'privacy policy',
  'terms and conditions',
  'return policy',
  'main',
  'contact us',
  'stores',
  'storeproducts',
  'selfFulfillmentOrders',
];

const prevRouteBlacklist = [
  '/signin',
  '/signin/verification',
  '/privacy',
  '/terms',
  '/about',
  '/contact',
  '/returncancelpolicy',
  '/',
];

export const RouteChangeComponent = ({
  sortBy,
  loadUserState,
  history,
  shoppingList,
  componentStates,
  updateCurrentState,
}) => {
  const location = useLocation();
  const userState = useSelector(({ user }) => user.userState);
  const { secrets } = useSelector(({ ui }) => ui);
  const { get } = useLocalStorage();
  const { token, phone } = useAppDataHook(location);
  const [isScriptLoaded, setScriptLoaded] = useState(false);

  let lqid = null;
  if (window.localStorage) {
    lqid = get('lqid');
  }

  useLayoutEffect(()=>{
    const script = document.createElement("script");
    script.src = "/msp-images/jsencrypt.min.js";
    document.body.appendChild(script);
    console.log("Layout effect script load executed");
    script.onload = () => { 
        generateKeys();
        setScriptLoaded(true);
    };
    
    return () => {
      document.body.removeChild(script);
    }
  },[]);

  
  useEffect(() => {
    if(isScriptLoaded){
    const online = navigator.onLine;
    if (
      localStorage &&
      location.pathname &&
      !prevRouteBlacklist.includes(location.pathname)
    ) {
      if (location.search) {
        localStorage.setItem('prevRoute', location.pathname + location.search);
      } else {
        localStorage.setItem('prevRoute', location.pathname);
      }
    }

    if (!online) {
      window.location.href = '/pages/offline.html';
      return null;
    }

    const currentPage = getPage(window.location);

    let state = componentStates.NONE;
    const pageHasHeader = headerExclusionList.indexOf(currentPage) === -1;
    const pageHasBottomNav = bottomNavExclusionList.indexOf(currentPage) === -1;

    if (pageHasHeader) {
      state = componentStates.WITH_HEADER;
    }

    if (pageHasBottomNav) {
      state = componentStates.WITH_BOTTOM_NAV;
    }

    if (pageHasBottomNav && pageHasHeader) {
      state = componentStates.BOTH;
    }

    if (!pageHasBottomNav && !pageHasHeader) {
      state = componentStates.NONE;
    }

    updateCurrentState(state);
  }
  }, [location, isScriptLoaded]);

  useEffect(() => {
    if(isScriptLoaded){
      const params = {
        sortBy,
      };

      const page = getPage(location);
      // if (IS_PRODUCTION && lqid && page) {
      //   window.clevertap.event.push('msp page change', {
      //     loadedAt: new Date(),
      //     uniqueId: lqid,
      //     page,
      //   });
      // }

    if (IS_PRODUCTION && secrets?.gap) {
      if (!userState.affiliatePartner) {
        ReactGA.pageview(location.pathname);
        ReactGA.set({ page, url: location.pathname });
      } else if (userState?.affiliatePartner && userState?.glance?.ga_ref) {
        ReactGA.pageview(location.pathname);
        ReactGA.set(
          {
            dimension1: userState.glance.ga_ref,
            dimension2: userState.glance.ga_uid,
            dimension3: userState.glance.ga_iid,
          },
          ['glance']
        );
      }

      if (token && phone) {
        params.token = token;
        params.phone = phone;
      }

      if (!userState.url) {
        // console.log('need more state... feed me!!!');
        loadUserState({ history, params });
      } else {
        // console.log('need no state....satisfied!!!');
      }
    }
  }}
  , [location,isScriptLoaded]);

  // useEffect(() => {
  //   if(isScriptLoaded){
  //   const page = getPage(location);
  //   if (shoppingList && Object.keys(shoppingList).length > 0) {
  //     const {
  //       shoppingListItems,
  //       totalValue,
  //       totalProductValue,
  //       totalShippingCharges,
  //       itemCount,
  //     } = shoppingList;
  //     if (
  //       totalValue &&
  //       shoppingListItems.length > 0 &&
  //       (page === 'cart' ||
  //         page === 'checkout address' ||
  //         page === 'address' ||
  //         page === 'payment')
  //     ) {
  //       const resellingProductIds = shoppingListItems.reduce(
  //         (accumulator, currentItem) => {
  //           return [...accumulator, currentItem?.resellingProduct?.id];
  //         },
  //         []
  //       );
  //       window.clevertap.event.push('msp cart status', {
  //         currentScreen: page,
  //         totalValue,
  //         totalProductValue,
  //         shippingCharges: totalShippingCharges,
  //         resellingProductIds: resellingProductIds.join(','),
  //         itemCount,
  //       });
  //     }
  //   }
     
  // }
  // }, [location, shoppingList,isScriptLoaded]);

  return <></>;
};

export default RouteChangeComponent;
