// @flow
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import GenericButton from './GenericButton';
import { RedButtonStyles } from './styles';

const TagButton = styled(GenericButton)`
  border-radius: 22px;
`;

export const TagButtonLink = styled(GenericButton).attrs({ as: Link })`
  border-radius: 25px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  ${props =>
    props.page && props.page === 'shop'
      ? css`
          background-color: ${props.theme.colors.red};
          border-color: ${props.theme.colors.red};
          color: white;
          &:hover,
          &:visited {
            color: white;
          }
        `
      : css`
          background-color: white;
          border-color: ${props.theme.colors.red};
          color: ${props.theme.colors.red};
          &:hover,
          &:visited {
            color: ${props.theme.colors.red};
          }
        `};
  &.active {
    ${RedButtonStyles}
  }
`;

export default TagButton;
