import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { API_BASE_URL } from 'utils/constants';
import request from 'utils/request';

import {
  loadStoreFailureAction,
  loadStoreSuccessAction,
  reloadStoreFailureAction,
  reloadStoreSuccessAction,
  toggleLoadingState,
} from './actions';
import {
  LOAD_STORE,
  pageStates,
  RELOAD_STORE,
  UPDATE_SCROLL_POSITION,
  UPDATE_SCROLL_POSITION_SUCCESS,
  UPDATE_STORE_PRODUCT,
  UPDATE_STORE_PRODUCT_SUCCESS,
} from './constants';

function* loadStore({ payload }) {
  yield console.log(payload);
  const { shopName, storeId, token } = payload;

  const URL = `${API_BASE_URL}/${shopName}/shop/store/listing/${storeId}?pageNo=1${
    token ? `&token=${token}` : ''
  }`;
  // debugger;
  try {
    yield put(toggleLoadingState({ loadingState: pageStates.LOADING }));
    const response = yield call(request, URL, {
      method: 'get',
      headers: {
        'content-type': 'application/json',
      },
    });

    if (response.status === 'success') {
      yield put(loadStoreSuccessAction({ ...response }));
    } else {
      yield put(loadStoreFailureAction({ ...response }));
    }
  } catch (error) {
    console.log(error);
    yield put(loadStoreFailureAction({ error }));
  }

  // set loading state to loading
  // Fetch data
  // If success then set loading state to success
  // If failure then set loading state to failure
}

function* reloadStore({ payload }) {
  const { shopName, pageNo, storeId, token } = payload;

  yield put(toggleLoadingState({ loadingState: pageStates.RELOADING }));

  const URL = `${API_BASE_URL}/${shopName}/shop/store/listing/${storeId}?pageNo=${pageNo}${
    token ? `&token=${token}` : ''
  }`;

  try {
    const response = yield call(request, URL, {
      method: 'get',
      headers: {
        'content-type': 'application/json',
      },
    });

    if (response.status === 'success') {
      yield put(reloadStoreSuccessAction({ ...response, pageNo }));
    } else {
      yield put(reloadStoreFailureAction({ ...response }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* updateScrollPosition({ payload }) {
  yield put({
    type: UPDATE_SCROLL_POSITION_SUCCESS,
    payload,
  });
}

function* updateStoreProduct({ payload }) {
  yield put({
    type: UPDATE_STORE_PRODUCT_SUCCESS,
    payload,
  });
}

export default function* watcher() {
  yield takeEvery(LOAD_STORE, loadStore);
  yield takeEvery(RELOAD_STORE, reloadStore);
  yield takeLatest(UPDATE_SCROLL_POSITION, updateScrollPosition);
  yield takeLatest(UPDATE_STORE_PRODUCT, updateStoreProduct);
}
