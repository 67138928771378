export const LOAD_REVIEW_IMAGES = 'productReviewImages/LOAD_REVIEW_IMAGES';
export const LOAD_REVIEW_IMAGES_SUCCESS =
  'productReviewImages/LOAD_REVIEW_IMAGES_SUCCESS';
export const LOAD_REVIEW_IMAGES_FAILED =
  'productReviewImages/LOAD_REVIEW_IMAGES_FAILED';

export const RELOAD_REVIEW_IMAGES = 'productReviewImages/RELOAD_REVIEW_IMAGES';
export const RELOAD_REVIEW_IMAGES_SUCCESS =
  'productReviewImages/RELOAD_REVIEW_IMAGES_SUCCESS';
export const RELOAD_REVIEW_IMAGES_FAILED =
  'productReviewImages/RELOAD_REVIEW_IMAGES_FAILED';

export const TOGGLE_REVIEW_IMAGES_LOADING =
  'productReviewImages/TOGGLE_REVIEW_IMAGES_LOADING';
export const TOGGLE_REVIEW_IMAGES_RELOADING =
  'productReviewImages/TOGGLE_REVIEW_IMAGES_RELOADING';
