import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Overlay = styled.div.attrs({
  className:
    'fixed bottom-0 h-screen z-1064 w-full flex flex-row items-end justify-center left-0',
})`
  background: rgba(0, 0, 0, 0.5);
`;

export const Sheet = styled.section`
  ${tw`w-full bg-white h-auto rounded-t-lg sm:w-8/12 md:w-7/12 lg:w-5/12 xl:w-5/12 sm:mx-auto`};
`;

export const Header = styled.header.attrs({
  className:
    'relative w-full py-4 px-2 flex flex-row flex-no-wrap border-b-100 border-solid border-gray-400',
})`
  & > button {
    ${tw`absolute right-1 top-1`};
  }
`;

export const Body = styled.div.attrs({ className: 'relative w-full px-2' })``;

export const SelectSize = styled.article.attrs({
  className: 'py-4 border-gray-300 border-solid border-b',
})`
  header {
    ${tw`flex flex-row items-center justify-between mb-2`}
    h4 {
      ${tw`text-sm font-semibold text-gray-700 leading-relaxed`}
    }
  }
`;

export const ChangeQuantity = styled.article.attrs({
  className:
    'flex flex-row items-center justify-between py-4 border-gray-300 border-solid border-b',
})`
  & > p {
    ${tw`text-sm font-semibold text-gray-700 leading-relaxed`}
  }
`;

export const SelectVariant = styled.div.attrs({
  className: 'py-4 border-gray-300 border-solid border-b',
})`
  header {
    ${tw`mb-2`}
    h4 {
      ${tw`text-sm font-semibold text-gray-700 leading-relaxed`}
    }
  }
`;
