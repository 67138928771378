import { produce } from 'immer';

import {
  LOAD_STORES_FAILURE,
  LOAD_STORES_SUCCESS,
  pageStates,
  RELOAD_STORES_FAILURE,
  RELOAD_STORES_SUCCESS,
  TOGGLE_LOADING_STATE,
} from './constants';

const initialState = {
  stores: [],
  loadingState: pageStates.LOADING,
  totalStores: 0,
  isScrollCompleted: false,
  scrollTop: 0,
  pageNo: 1,
};

// eslint-disable-next-line consistent-return
const shopReducer = produce((draft, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOAD_STORES_SUCCESS: {
      // debugger;
      const { stores, totalCount } = payload?.data || {};
      draft.stores = stores || [];
      draft.totalStores = totalCount || 0;
      draft.loadingState = pageStates.LOADED;
      draft.pageNo = 1;

      if (stores === undefined || stores.length === 0) {
        draft.isScrollCompleted = true;
      } else {
        draft.isScrollCompleted = false;
      }
      return draft;
    }

    case LOAD_STORES_FAILURE: {
      console.log(payload);
      draft.loadingState = pageStates.LOADED;
      draft.error = payload?.error || {};
      draft.isScrollCompleted = true;
      return draft;
    }

    case RELOAD_STORES_SUCCESS: {
      const { stores } = payload?.data || {};
      if (stores?.length > 0) {
        stores.forEach(store => draft.stores.push(store));
      }
      draft.pageNo = payload.pageNo || 1;
      draft.loadingState = pageStates.LOADED;

      if (!stores || stores.length === 0) {
        draft.isScrollCompleted = true;
      } else {
        draft.isScrollCompleted = false;
      }
      return draft;
    }

    case RELOAD_STORES_FAILURE: {
      draft.loadingState = pageStates.LOADED;
      draft.error = payload?.error || {};
      draft.isScrollCompleted = true;
      return draft;
    }

    case TOGGLE_LOADING_STATE: {
      console.log(payload);
      draft.loadingState = payload.loadingState;
      return draft;
    }

    case 'stores/UPDATE_SCROLL_POSITION_SUCCESS': {
      // debugger;
      draft.scrollTop = payload.scrollTop;
      return draft;
    }

    default: {
      return draft;
    }
  }
}, initialState);

export default shopReducer;
