import {
  LOAD_REVIEWS,
  LOAD_REVIEWS_FAILED,
  LOAD_REVIEWS_SUCCESS,
  RELOAD_REVIEWS,
  RELOAD_REVIEWS_FAILED,
  RELOAD_REVIEWS_SUCCESS,
  TOGGLE_REVIEWS_LOADING,
  TOGGLE_REVIEWS_RELOADING,
} from './constants';

export const fetchReviews = productId => {
  return {
    type: LOAD_REVIEWS,
    payload: {
      productId,
    },
  };
};

export const fetchNextReviews = (productId, pageNo) => {
  return {
    type: RELOAD_REVIEWS,
    payload: {
      productId,
      pageNo,
    },
  };
};

export const loadingSuccess = data => {
  return {
    type: LOAD_REVIEWS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const loadingFailed = data => {
  return {
    type: LOAD_REVIEWS_FAILED,
    payload: {
      data,
    },
  };
};

export const toggleLoading = isLoading => {
  return {
    type: TOGGLE_REVIEWS_LOADING,
    payload: {
      isLoading,
    },
  };
};

export const reloadingSuccess = data => {
  return {
    type: RELOAD_REVIEWS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const reloadingFailed = data => {
  return {
    type: RELOAD_REVIEWS_FAILED,
    payload: {
      data,
    },
  };
};

export const toggleReloading = isReloading => {
  return {
    type: TOGGLE_REVIEWS_RELOADING,
    payload: {
      isReloading,
    },
  };
};
