// @flow
// import * as Sentry from '@sentry/react';
import BottomNavigation from 'components/BottomNavigation';
import Header from 'components/Header/loadable';
import PrivateRoute from 'components/PrivateRoute';
import ProductBottomSheet from 'components/ProductBottomSheet';
import PublicRoute from 'components/PublicRoute';
import { history } from 'config/configureStore';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import AppShell from 'screens/AppShell';
import { LOAD_USER_STATE } from 'utils/constants';

import { RouteChangeComponent } from './RouteChangeComponent';

// export const retry = (fn, retriesLeft = 5, interval = 1000) => {
//   return new Promise((resolve, reject) => {
//     fn()
//       .then(module => {
//         // debugger;
//         resolve(module);
//       })
//       .catch(error => {
//         // debugger;
//         setTimeout(() => {
//           if (retriesLeft === 1) {
//             Sentry.captureException(error);
//             reject(error);
//             return;
//           }

//           retry(fn, interval, retriesLeft - 1).then(resolve, reject);
//         }, interval);
//       });
//   });
// };

const privateScreensList = [
  {
    path: '/profile',
    exact: true,
    isPrivate: true,
    Component: lazy(() => import('screens/Profile')),
  },
  {
    path: '/wallet',
    exact: true,
    isPrivate: true,
    Component: lazy(() => import('screens/Wallet')),
  },
  {
    path: '/wallet-faq',
    exact: true,
    isPrivate: true,
    Component: lazy(() => import('screens/WalletFaq')),
  },
  {
    path: '/manage-address',
    exact: true,
    isPrivate: true,
    Component: lazy(() => import('screens/ManageAddress')),
  },
  {
    path: '/editAddress',
    exact: true,
    isPrivate: true,
    Component: lazy(() => import('screens/EditAddress')),
  },
  {
    path: '/editAddress/:addressId',
    exact: true,
    isPrivate: true,
    Component: lazy(() => import('screens/EditAddress')),
  },
  {
    path: '/myorders',
    exact: true,
    isPrivate: true,
    Component: lazy(() => import('screens/MyOrders')),
  },
];

const publicScreensList = [
  {
    path: '/signin',
    exact: true,
    Component: lazy(() => import('screens/SignIn')),
  },
  {
    path: '/signin/verification',
    exact: true,
    Component: lazy(() => import('screens/SignInVerification')),
  },
];

const screensList = [
  {
    path: '/',
    exact: true,
    Component: lazy(() => import('screens/Main')),
  },

  {
    path: '/about-shop',
    exact: true,
    Component: lazy(() => import('screens/AboutShop')),
  },
  {
    path: '/:shopName/categories',
    exact: true,
    Component: lazy(() => import('screens/Categories')),
  },
  {
    path: '/:shopName/shop/categories',
    exact: true,
    Component: lazy(() => import('screens/Categories')),
  },
  {
    path: '/:shopName/:token/categories',
    exact: true,
    Component: lazy(() => import('screens/Categories')),
  },
  {
    path: '/share/:shareId',
    exact: true,
    Component: lazy(() => import('screens/ProductListing/loadable')),
  },
  {
    path: '/collections/:collectionId',
    exact: true,
    Component: lazy(() => import('screens/ProductListing/loadable')),
  },
  {
    path: '/s/collections/:collectionId',
    exact: true,
    Component: lazy(() => import('screens/ProductListing/loadable')),
  },
  {
    path: '/:shopName/shop/stores',
    exact: true,
    Component: lazy(() => import('screens/Stores')),
    page: 'stores',
    title: 'Stores By Experts',
  },
  {
    path: '/:shopName/:token/stores',
    exact: true,
    Component: lazy(() => import('screens/Stores')),
  },
  {
    path: '/:shopName/shop/store/:storeId',
    exact: true,
    Component: lazy(() => import('screens/StoreProducts')),
  },
  {
    path: '/:shopName/:token/store/:storeId',
    exact: true,
    Component: lazy(() => import('screens/StoreProducts')),
  },
  {
    path: '/s/:shopName/shop',
    exact: true,
    Component: lazy(() => import('screens/ShopMain')),
  },
  {
    path: '/s/:shopName/shop/:someId',
    exact: true,
    Component: lazy(() => import('screens/ShopMain')),
  },
  {
    path: '/:shopName/:token/list/:categoryName',
    exact: true,
    Component: lazy(() => import('screens/ProductListing/loadable')),
  },
  {
    path: '/:shopName/list/:categoryName',
    exact: true,
    Component: lazy(() => import('screens/ProductListing/loadable')),
  },
  {
    path: '/edit-name',
    exact: true,
    Component: lazy(() => import('screens/EditName')),
  },
  {
    path: '/terms',
    exact: true,
    Component: lazy(() => import('screens/NewTermsAndCondition')),
  },
  {
    path: '/know-more',
    exact: true,
    Component: lazy(() => import('screens/KnowMore')),
  },
  {
    path: '/:shopName/list/:categoryName',
    exact: true,
    Component: lazy(() => import('screens/ProductListing/loadable')),
  },
  {
    path: '/about',
    exact: true,
    Component: lazy(() => import('screens/About/loadable')),
  },
  {
    path: '/contact',
    exact: true,
    Component: lazy(() => import('screens/Contact')),
  },
  {
    path: '/help',
    exact: true,
    Component: lazy(() => import('screens/Help')),
  },
  {
    path: '/privacy',
    exact: true,
    Component: lazy(() => import('screens/PrivacyNew')),
  },
  {
    path: '/returncancelpolicy',
    exact: true,
    Component: lazy(() => import('screens/ReturnCancelPolicy')),
  },
  {
    path: '/checkout/failure',
    exact: true,
    Component: () => <Redirect to='/checkout/cart' />,
  },
  {
    path: '/address',
    exact: true,
    isPrivate: true,
    Component: lazy(() => import('screens/AddEditAddress')),
  },
  {
    path: '/address/:addressId',
    exact: true,
    isPrivate: true,
    Component: lazy(() => import('screens/AddEditAddress')),
  },
  {
    path: '/checkout/cart',
    exact: true,
    Component: lazy(() => import('screens/Cart/loadable')),
  },
  {
    path: '/checkout/address',
    exact: true,
    Component: lazy(() => import('screens/Address/loadable')),
  },
  {
    path: '/checkout/payment',
    exact: true,
    Component: lazy(() => import('screens/Payment/loadable')),
  },
  {
    path: '/checkout/success/:txnId',
    exact: true,
    Component: lazy(() => import('screens/PostOrderPlacement')),
  },
  {
    path: '/order/:orderId',
    exact: true,
    Component: lazy(() => import('screens/OrderDetails')),
  },
  {
    path: '/email-us',
    exact: true,
    Component: lazy(() => import('screens/EmailUs')),
  },
  {
    path: '/delivery/:deliveryId',
    exact: true,
    Component: lazy(() => import('screens/DeliveryStatus')),
  },
  {
    path: '/selfFulfillmentOrders/:uniqueId',
    exact: true,
    Component: lazy(() => import('screens/SelfOrderFulfillment')),
  },
  {
    path: '/reversePickup/:referenceId',
    exact: true,
    Component: lazy(() => import('screens/ReversePickupStatus')),
  },
  {
    path: '/product/:productName/:productId',
    exact: true,
    Component: lazy(() => import('screens/Product/loadable')),
  },
  {
    path: '/reviews/:productId',
    exact: true,
    Component: lazy(() => import('screens/ProductReviews/loadable')),
  },
  {
    path: '/reviews/:productId/images',
    exact: true,
    Component: lazy(() => import('screens/ProductReviewImages/loadable')),
  },
  {
    path: '/shop-list',
    exact: true,
    Component: lazy(() => import('screens/ShopListing/loadable')),
  },

  {
    path: '/notfound',
    exact: true,
    Component: lazy(() => import('screens/NotFound')),
  },
  {
    path: '/:shopName/shop',
    exact: true,
    Component: lazy(() => import('screens/ShopMain')),
  },

  {
    path: '/:shopName/shop/:categoryName',
    exact: true,
    Component: lazy(() => import('screens/ProductListing/loadable')),
  },
  {
    path: '/:shopName/:token/:categoryName',
    exact: true,
    Component: lazy(() => import('screens/ProductListing/loadable')),
  },
  {
    path: '/:shopName/:token',
    exact: true,
    Component: lazy(() => import('screens/ShopMain')),
  },
  {
    path: '/:shopName',
    exact: true,
    Component: lazy(() => import('screens/ShopMain')),
  },
];

const componentStates = {
  WITH_HEADER: 'WITH_HEADER',
  WITH_BOTTOM_NAV: 'WITH_BOTTOM_NAV',
  BOTH: 'BOTH',
  NONE: 'NONE',
};

const AppRouter = ({ pageNo = 1 }) => {
  const dispatch = useDispatch();
  const sortBy = useSelector(({ shop }) => shop.sortBy) ?? 'recency';
  const shoppingList = useSelector(({ cart }) => cart.shoppingList);

  const { isShowProductBottomSheet, selectedProduct } = useSelector(
    ({ ui }) => ui
  );

  const [currentState, setCurrentState] = useState(componentStates.NONE);

  useEffect(() => {
    // debugger;
    const installData = localStorage?.getItem('install-data');

    const isUndefined = window.installPromptEvent === undefined;
    const isUnitiliased = typeof window.installPromptEvent === 'string';
    const isNull = window.installPromptEvent === null;

    // console.log('* installData', installData);
    // console.log('* install state', {
    //   isUndefined,
    //   isUnitiliased,
    //   isNull,
    // });

    if (window.installPromptEvent) {
      // console.log('* running as web-app!');
      if (installData === null) {
        console.log('* no install data!');
      }

      if (installData) {
        const { expiration, rejected, installed } = JSON.parse(installData);
        // console.log('* install data', {
        //   ...JSON.parse(installData),
        // });
        if (rejected) {
          if (expiration - Date.now() > 0) {
            console.log('* time not expired yet');
          }
        }
        if (!expiration) {
          console.log('* no expiration required!');
        } else {
          console.log('* unset else');
        }
      }
    } else if (window.matchMedia('(display-mode: standalone)').matches) {
      console.log('running as pwa!');
    } else {
      console.log('a2hs not supported!');
    }
  }, [window.installPromptEvent]);

  const updateCurrentState = newState => {
    setCurrentState(newState);
  };

  const loadUserState = payload => dispatch({ type: LOAD_USER_STATE, payload });

  // TODO-CLIENT checkout pages need to be private routes except cart
  return (
    <Router history={history}>
      <LastLocationProvider>
        <div className='bg-gray-400'>
          <RouteChangeComponent
            loadUserState={loadUserState}
            sortBy={sortBy}
            pageNo={pageNo}
            history={history}
            shoppingList={shoppingList}
            updateCurrentState={updateCurrentState}
            componentStates={componentStates}
          />
          <div className='max-w-4xl mx-auto relative overflow-x-hidden'>
            {/* <ProgressBar isLoading={isShopLoading || isPageLoading} /> */}

            {(currentState === componentStates.BOTH ||
              currentState === componentStates.WITH_HEADER) && <Header />}

            <Suspense fallback={<div className='h-screen p-3'>Loading...</div>}>
              <Switch>
                <Route path='/app' component={AppShell} exact />
                {privateScreensList.map(
                  ({ path, Component, exact, ...rest }, index) => {
                    return (
                      <PrivateRoute
                        key={path + index}
                        path={path}
                        exact={exact}
                        component={Component}
                        {...rest}
                      />
                    );
                  }
                )}

                {publicScreensList.map(
                  ({ path, Component, exact, ...rest }, index) => {
                    return (
                      <PublicRoute
                        key={path + index}
                        path={path}
                        exact={exact}
                        component={Component}
                        {...rest}
                      />
                    );
                  }
                )}

                {screensList.map(
                  ({ path, Component, exact, ...rest }, index) => {
                    return (
                      <Route
                        key={path + index}
                        path={path}
                        exact={exact}
                        render={props => <Component {...props} {...rest} />}
                      />
                    );
                  }
                )}
              </Switch>
            </Suspense>

            {(currentState === componentStates.BOTH ||
              currentState === componentStates.WITH_BOTTOM_NAV) && (
              <div className='relative max-w-4xl h-bottomNav h-full w-auto mx-auto'>
                <BottomNavigation />
              </div>
            )}

            {isShowProductBottomSheet && (
              <ProductBottomSheet selectedProduct={selectedProduct} />
            )}
          </div>
        </div>
      </LastLocationProvider>
    </Router>
  );
};

export default AppRouter;
