import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { API_BASE_URL } from 'utils/constants';
import request from 'utils/request';

import {
  loadStoreFailureAction,
  loadStoreSuccessAction,
  reloadStoreFailureAction,
  reloadStoreSuccessAction,
  toggleLoadingState,
} from './actions';
import { LOAD_STORES, pageStates, RELOAD_STORES } from './constants';

function* loadStores({ payload }) {
  yield console.log(payload);
  const { shopName, token } = payload;

  const URL = `${API_BASE_URL}/${shopName}/shop/stores?pageNo=1${
    token ? `&token=${token}` : ''
  }`;
  // debugger;
  try {
    yield put(toggleLoadingState({ loadingState: pageStates.LOADING }));
    const response = yield call(request, URL, {
      method: 'get',
      headers: {
        'content-type': 'application/json',
      },
    });

    if (response.status === 'success') {
      yield put(loadStoreSuccessAction({ ...response }));
    } else {
      yield put(loadStoreFailureAction({ ...response }));
    }
  } catch (error) {
    console.log(error);
    yield put(loadStoreFailureAction({ error }));
  }
}

function* reloadStores({ payload }) {
  // debugger;
  const { shopName, pageNo, token } = payload;

  yield put(toggleLoadingState({ loadingState: pageStates.RELOADING }));

  const URL = `${API_BASE_URL}/${shopName}/shop/stores?pageNo=${pageNo}${
    token ? `&token=${token}` : ''
  }`;
  try {
    const response = yield call(request, URL, {
      method: 'get',
      headers: {
        'content-type': 'application/json',
      },
    });

    if (response.status === 'success') {
      yield put(reloadStoreSuccessAction({ ...response, pageNo }));
    } else {
      yield put(reloadStoreFailureAction({ ...response, pageNo }));
    }
  } catch (error) {
    console.log(error);
    yield put(loadStoreFailureAction({ error }));
  }
}

function* updateScrollPosition({ payload }) {
  yield put({
    type: 'stores/UPDATE_SCROLL_POSITION_SUCCESS',
    payload,
  });
}

export default function* watcher() {
  yield takeEvery(LOAD_STORES, loadStores);
  yield takeEvery(RELOAD_STORES, reloadStores);
  yield takeLatest('stores/UPDATE_SCROLL_POSITION', updateScrollPosition);
}
