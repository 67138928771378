import {
  SET_SORT_BY,
  TOGGLE_ATTRIBUTES_FILTER,
  TOGGLE_SORT,
  UPDATE_LISTING_PRODUCTS,
} from 'utils/constants';

export const showAttributesFilterAction = isShowAttributesFilter => ({
  type: TOGGLE_ATTRIBUTES_FILTER,
  payload: {
    isShowAttributesFilter,
  },
});

export const showSortAction = isShowSort => ({
  type: TOGGLE_SORT,
  payload: {
    isShowSort,
  },
});

export const setSortByAction = sortBy => ({
  type: SET_SORT_BY,
  payload: {
    sortBy,
  },
});

export const updateListingProductsAction = payload => ({
  type: UPDATE_LISTING_PRODUCTS,
  payload,
});
