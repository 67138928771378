import produce from 'immer';

import {
  LOAD_REVIEWS_FAILED,
  LOAD_REVIEWS_SUCCESS,
  RELOAD_REVIEWS_FAILED,
  RELOAD_REVIEWS_SUCCESS,
  TOGGLE_REVIEWS_LOADING,
  TOGGLE_REVIEWS_RELOADING,
} from './constants';

const initialReviewsState = {
  isLoading: false,
  isLoadingFailed: false,
  isReloading: false,
  isReloadingFailed: false,
  errorMssg: '',
  pageNo: 1,
};

export default produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_REVIEWS_SUCCESS: {
      console.log(payload.data);
      const {
        productImage,
        productRatings,
        productTitle,
        ratingCount,
        reviews,
        reviewImageList,
      } = payload.data;

      console.log('data', payload.data);

      draft.productImage = productImage;
      draft.productRatings = productRatings;
      draft.productTitle = productTitle;
      draft.ratingCount = ratingCount;
      draft.reviews = reviews;
      draft.reviewImageList = reviewImageList;

      draft.isLoadingFailed = false;
      draft.errorMssg = '';

      draft.reviews = payload.data.reviews;
      return draft;
    }

    case LOAD_REVIEWS_FAILED: {
      draft.isLoadingFailed = true;
      draft.errorMssg = payload.data.mssg;
      return draft;
    }

    case RELOAD_REVIEWS_SUCCESS: {
      draft.isReloadingFailed = false;
      draft.errorMssg = '';

      const { productRatings, pageNo } = payload.data;

      if (productRatings?.length > 0) {
        productRatings.forEach(review => {
          draft.productRatings.push(review);
        });
      }

      draft.pageNo = pageNo;

      return draft;
    }

    case RELOAD_REVIEWS_FAILED: {
      draft.isReloadingFailed = true;
      draft.errorMssg = payload.data.mssg;
      return draft;
    }

    case TOGGLE_REVIEWS_LOADING: {
      draft.isLoading = payload.isLoading;
      return draft;
    }

    case TOGGLE_REVIEWS_RELOADING: {
      draft.isReloading = payload.isReloading;
      return draft;
    }

    default:
      return draft;
  }
}, initialReviewsState);
