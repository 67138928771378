export const FETCH_ITEMS = 'CART/FETCH_ITEMS';
export const FETCH_ITEMS_SUCCESSFUL = 'CART/FETCH_ITEMS_SUCCESSFUL';
export const FETCH_ITEMS_FAILED = 'CART/FETCH_ITEMS_FAILED';

export const ADD_PRODUCT = 'CART/ADD_PRODUCT';
export const ADD_PRODUCT_SUCCESSFUL = 'CART/ADD_PRODUCT_SUCCESSFUL';
export const ADD_PRODUCT_FAILED = 'CART/ADD_PRODUCT_FAILED';

export const UPDATE_PRODUCT = 'CART/UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESSFUL = 'CART/UPDATE_PRODUCT_SUCCESSFUL';
export const UPDATE_PRODUCT_FAILED = 'CART/UPDATE_PRODUCT_FAILED';

export const UPDATE_CART_SUMMARY = 'CART/UPDATE_CART_SUMMARY';

export const UPDATE_TYPES = {
  REMOVE: 'REMOVE',
  SIZE: 'SIZE',
  QTY: 'QTY',
  REMOVE_ALL_INACTIVE: 'REMOVE_ALL_INACTIVE',
};
