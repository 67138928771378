// @flow

import Icon from 'components/Icon';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Truncate from 'react-truncate';
import { IconButton } from 'ui-components/Buttons';
import { getPage, useLocalStorage } from 'utils/helpers';

import {
  // Button,
  Wrapper,
} from './styles';

type Props = {
  displayText: string,
  showNavigation: Boolean,
  history: any,
  location: any,
  // navigationLink?: string,
  userState?: Object,
  showHeaderText?: Boolean,
  searchText: String,
};

const Navigation = ({
  displayText = '',
  showNavigation,
  history,
  location,
  // navigationLink,
  userState,
  showHeaderText,
  searchText,
}: Props) => {
  const {get} = useLocalStorage();
  const pageType = getPage(location);
  const onBackButtonClick = () => {
    if(pageType === "list" && searchText) {
      if (window.localStorage) {
        let lqid = get('lqid');
        // window.clevertap.event.push('msp back button clicked after search', { uniqueId: lqid });
      }
    }
    if (window.history.length > 1) {
      console.log('going back');
      history.goBack();
    } else {
      console.log('going to userState.url');
      history.push(userState?.url);
    }
  };

  const { innerWidth } = window;

  const pathname = history.location.pathname.split('/').slice(1);
  const isMainPage = history.location.pathname === '/';

  const isShopPage = pathname[1] === 'shop' || pathname[1] === '';
  const isCollectionPage = pathname[0] === 'collections';
  const isDisplayTextLink = isShopPage || isMainPage || isCollectionPage;

  let displayTextLink = '';
  if (isShopPage || isCollectionPage) {
    displayTextLink = userState.url;
  }
  if (isMainPage) {
    displayTextLink = '/';
  }

  return (
    <Wrapper className='fluid-height fluid-width'>
      {/* if not homepage then show */}
      {showNavigation && (
        <IconButton bordered onClick={onBackButtonClick}>
          <Icon icon='back' color='#2e2e2e' size='24px' />
        </IconButton>
      )}

      {!showHeaderText && (
        <div className='block w-full h-6 bg-gray-200 rounded' />
      )}

      {isDisplayTextLink
        ? showHeaderText && (
            <Link to={displayTextLink ?? ''}>
              <Truncate
                lines={1}
                ellipsis='...'
                trimWhitespace
                width={innerWidth > 768 ? 450 : 250}>
                {showHeaderText && (displayText || '')}
              </Truncate>
            </Link>
          )
        : showHeaderText && (
            <p className='truncate text-sm font-bold leading-6 tracking-wide'>
              {displayText}
            </p>
          )}
    </Wrapper>
  );
};

export default withRouter(Navigation);
