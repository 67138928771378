export const REDIRECT_CART = 'PAYMENT/REDIRECT_CART';
export const REDIRECT_CART_SUCCESSFUL = 'PAYMENT/REDIRECT_CART_SUCCESSFUL';
export const REDIRECT_CART_FAILED = 'PAYMENT/REDIRECT_CART_FAILED';

export const FETCH_PAYMENT_DETAILS = 'PAYMENT/FETCH_PAYMENT_DETAILS';
export const FETCH_PAYMENT_DETAILS_SUCCESSFUL =
  'PAYMENT/FETCH_PAYMENT_DETAILS_SUCCESSFUL';
export const FETCH_PAYMENT_DETAILS_FAILED =
  'PAYMENT/FETCH_PAYMENT_DETAILS_FAILED';

export const RESET_PAYMENT = 'PAYMENT/RESET_PAYMENT';
export const SET_LOADING_STATE = 'PAYMENT/SET_LOADING_STATE';

export const SET_COD_SIGNIN = 'PAYMENT/SET_COD_SIGNIN';
