/**
 * Gets the repositories of the user from Github
 */

import { call, put, select, takeLatest } from 'redux-saga/effects'; //eslint-disable-line
import request from 'utils/request';
import { updateCartSummary } from 'screens/Cart/actions';
import { API_BASE_URL } from 'utils/constants';
import * as Sentry from '@sentry/react';
// import { resetPayment } from 'screens/Payment/actions';
import {handleEncryption} from 'utils/request';
import {
  // fetchSavedAddresses,
  fetchSavedAddressesSuccessful,
  fetchSavedAddressesFailed,
  setPincodeUnserviceable,
  setNameAlreadyExist,
  storeAddress,
  setMangeAddressLoading,
  selectAddress as selectAdd,
  setBuyerAddress,
} from './actions';
// import { makeSelectProductId } from './selectors';
import {
  FETCH_SAVED_ADDRESSES,
  SAVE_ADDRESS,
  EDIT_ADDRESS,
  SAVE_CHECKOUT_ADDRESS,
  DELETE_ADDRESS,
  GET_ADDRESS,
  SELECT_ADDRESS,
  SAVE_ADDRESS_AND_PROCEED,
  GET_CITY_STATE,
} from './constants';
import { decryptString } from 'utils/intransitUtils';

export function* fetchSavedAddressesFn() {
  yield put(setMangeAddressLoading('LOADING'));
  const requestUrl = `${API_BASE_URL}/checkout/addresslist`;
  try {
    const encData = yield call(request, requestUrl);
    const data = decryptString(encData);
    yield put(setMangeAddressLoading('LOADED'));
    if(data.addresses.length > 0) {
      if(localStorage?.getItem('prevRoute') === "/address?checkout=true"){
        localStorage.setItem('prevRoute', '/checkout/address');
      }
      yield put(fetchSavedAddressesSuccessful(data.addresses));
    } else {
      yield put(fetchSavedAddressesSuccessful([]));
    }
    yield put(updateCartSummary(data.shoppingCart));
  } catch (err) {
    console.log(err);
    Sentry.captureException(new Error(err));
    yield put(fetchSavedAddressesFailed(err));
  }
}

export function* saveCheckoutAddress({ data, history, directPayment, random }) {
  const requestUrl = `${API_BASE_URL}/checkout/addaddress`;
  const encData = handleEncryption(JSON.stringify({ ...data, valid: true, proceed: false }),random);
  if(encData){
    try {
      const options = {
        method: 'POST',
        body: JSON.stringify({ md : encData}),
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const encRespone = yield call(request, requestUrl, options);
      const responseData = decryptString(encRespone);
      if (responseData.status === 'success') {
        // yield put(fetchSavedAddresses());
        if(directPayment){
          yield put(selectAdd({id:responseData.addresses[0].id,history}));
          yield put(setBuyerAddress(data));
          yield call(history.push('/checkout/payment'));
        } else {
          yield call(history.push('/checkout/address'));
        }
      } else if (responseData?.message === 'Pincode not serviceable') {
        yield put(setPincodeUnserviceable({ pincodeUnserviceable: true }));
      } else if (responseData?.message.includes('Receiver Name')) {
        yield put(setNameAlreadyExist({nameAlreadyExist: responseData?.message}));
      } else {
        yield put(fetchSavedAddressesFailed(responseData.message));
        yield put(
          setPincodeUnserviceable({
            pincodeUnserviceable: true,
            proceedList: responseData.proceedList,
            cancelledList: responseData.cancelledList,
            allActive: responseData.allActive,
          })
        );
      }
    } catch (err) {
      console.log(err);
      Sentry.captureException(new Error(err));
      yield put(fetchSavedAddressesFailed(err));
    }
  } else {
    alert("Encryption Failed");
  }
  
}

export function* saveAddress({ data, history ,random}) {
  const requestUrl = `${API_BASE_URL}/rest/user/address/add`;
  const encData = handleEncryption(JSON.stringify({ ...data, valid: true, proceed: false }),random);
  // console.log("ADDRESS ITEMID=====>", data);
  if(encData){
    try {
      const options = {
        method: 'POST',
        body: JSON.stringify({md: encData}),
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const encRespone = yield call(request, requestUrl, options);
      const responseData = decryptString(encRespone);
      if (responseData.status === 'success') {
        // yield put(fetchSavedAddresses());
        yield call(history.goBack);
      } else if (responseData?.message === 'Pincode not serviceable') {
        yield put(setPincodeUnserviceable({ pincodeUnserviceable: true }));
      } else if (responseData?.message.includes('Receiver Name')) {
        yield put(setNameAlreadyExist({nameAlreadyExist: responseData?.message}));
      } else {
        yield put(fetchSavedAddressesFailed(responseData.message));
        yield put(
          setPincodeUnserviceable({
            pincodeUnserviceable: true,
            proceedList: responseData.proceedList,
            cancelledList: responseData.cancelledList,
            allActive: responseData.allActive,
          })
        );
      }
    } catch (err) {
      console.log(err);
      Sentry.captureException(new Error(err));
      yield put(fetchSavedAddressesFailed(err));
    }
  } else {
    alert("Encryption failed");
  }
  
}

export function* editAddress({ data, history, random }) {
  const requestUrl = `${API_BASE_URL}/rest/user/address/edit`;
  const encData = handleEncryption(JSON.stringify({ ...data, valid: true, proceed: false }),random);
  // console.log("ADDRESS ITEMID=====>", data);
  if(encData){
    try {
      const options = {
        method: 'POST',
        body: JSON.stringify({md: encData}),
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const encRespone = yield call(request, requestUrl, options);
      const responseData = decryptString(encRespone);
      if (responseData.status === 'success') {
        // yield put(fetchSavedAddresses());
        yield call(history.goBack);
      } else if (responseData?.message === 'Pincode not serviceable') {
        yield put(setPincodeUnserviceable({ pincodeUnserviceable: true }));
      } else if (responseData?.message.includes('Receiver Name')) {
        yield put(setNameAlreadyExist({nameAlreadyExist: responseData?.message}));
      } else {
        yield put(fetchSavedAddressesFailed(responseData.message));
        yield put(
          setPincodeUnserviceable({
            pincodeUnserviceable: true,
            proceedList: responseData.proceedList,
            cancelledList: responseData.cancelledList,
            allActive: responseData.allActive,
          })
        );
      }
    } catch (err) {
      console.log(err);
      Sentry.captureException(new Error(err));
      yield put(fetchSavedAddressesFailed(err));
    }
  } else {
    alert("Encryption failed");
  }
  
}

export function* saveAddressAndProceed({ data, history }) {
  const requestUrl = `${API_BASE_URL}/checkout/addaddressandproceed`;
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify({ ...data, valid: true, proceed: true }),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const responseData = yield call(request, requestUrl, options);
    // console.log('Address saved and selected: ', responseData);
    if (responseData.status === 'success') {
      yield call(history.push, { pathname: '/checkout/payment' });
    } else if (responseData?.message === 'Pincode not serviceable') {
      yield put(setPincodeUnserviceable({ pincodeUnserviceable: true }));
    } else if (responseData?.message.includes('Receiver Name')) {
      yield put(setNameAlreadyExist({nameAlreadyExist: responseData?.message}));
    } else {
      yield put(fetchSavedAddressesFailed(responseData.message));
      yield put(
        setPincodeUnserviceable({
          pincodeUnserviceable: true,
          proceedList: responseData.proceedList,
          cancelledList: responseData.cancelledList,
          allActive: responseData.allActive,
        })
      );
    }
  } catch (err) {
    console.log(err);
    Sentry.captureException(new Error(err));
    yield put(fetchSavedAddressesFailed(err));
  }
}

export function* selectAddress({ id, history }) {
  const requestUrl = `${API_BASE_URL}/checkout/selectaddress`;
  const data = { id };
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const responseData = yield call(request, requestUrl, options);
    if (responseData?.status === 'success') {
      yield call(history.push, { pathname: '/checkout/payment' });
    } else if (responseData?.message === 'Pincode not serviceable') {
      yield put(fetchSavedAddressesFailed('Failed to select address'));
      yield put(
        setPincodeUnserviceable({
          pincodeUnserviceable: true,
          proceedList: responseData.proceedList,
          cancelledList: responseData.cancelledList,
          allActive: responseData.allActive,
        })
      );
    } else if (responseData?.message.includes('Receiver Name')) {
      yield put(setNameAlreadyExist({nameAlreadyExist: responseData?.message}));
    }
  } catch (err) {
    Sentry.captureException(new Error(err));
    yield put(fetchSavedAddressesFailed(err));
  }
}

export function* deleteAddress({ id }) {
  const requestUrl = `${API_BASE_URL}/checkout/deleteaddress`;
  const data = { itemId: id };
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const encData = yield call(request, requestUrl, options);
    const responseData = decryptString(encData);
    // console.log('Address deleted: ', responseData);
    if(responseData?.addresses?.length > 0) {
      yield put(fetchSavedAddressesSuccessful(responseData.addresses));
    } else {
      yield put(fetchSavedAddressesSuccessful([]));
    }
  } catch (err) {
    console.log(err);
    Sentry.captureException(new Error(err));
    yield put(fetchSavedAddressesFailed(err));
  }
}

export function* getAddress({ id }) {
  const requestUrl = `${API_BASE_URL}/checkout/getaddress`;
  const data = { itemId: id };
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const encData = yield call(request, requestUrl, options);
    const responseData = decryptString(encData);
    if (responseData.status === 'success') {
      const { addresses } = responseData;
      yield put(storeAddress({ ...addresses, clear: false }));
    }
    // console.log('Address deleted: ', responseData);
  } catch (err) {
    // console.log(
    //   '~~~~~~~~~~~~~~~~~~~~~~~~~~Error~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~',
    //   err
    // );
    Sentry.captureException(new Error(err));
  }
}

export function* getCityAndState({ pincode }) {
  const requestUrl = `${API_BASE_URL}/rest/checkout/pincodeDetails`;
  const body = { pincode };
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        apiVersion: 5,
      },
    };
    const responseData = yield call(request, requestUrl, options);
    if (responseData.result === 'success') {
      if (
        Object.keys(responseData.data).length !== 0 &&
        responseData.data.constructor === Object
      ) {
        yield put(
          storeAddress({
            ...responseData.data,
            clear: false,
          })
        );
      } else {
        yield put(
          storeAddress({
            clearPin: true,
            pincode,
          })
        );
      }
    }
  } catch (err) {
    Sentry.captureException(new Error(err));
  }
}

export default function* addressWatcherSaga() {
  yield takeLatest(FETCH_SAVED_ADDRESSES, fetchSavedAddressesFn);
  yield takeLatest(SAVE_ADDRESS, saveAddress);
  yield takeLatest(EDIT_ADDRESS, editAddress);
  yield takeLatest(SAVE_CHECKOUT_ADDRESS, saveCheckoutAddress);
  yield takeLatest(DELETE_ADDRESS, deleteAddress);
  yield takeLatest(GET_ADDRESS, getAddress);
  yield takeLatest(SELECT_ADDRESS, selectAddress);
  yield takeLatest(SAVE_ADDRESS_AND_PROCEED, saveAddressAndProceed);
  yield takeLatest(GET_CITY_STATE, getCityAndState);
}
