import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  getBreakpointName,
  getWidthSafely,
  isWidthOfDesktop,
} from 'utils/helpers';

const BreakpointContext = React.createContext({
  currentWidth: 9999,
  currentBreakpointName: '',
  isDesktop: true,
  isMobile: false,
});

const BreakpointProvider = props => {
  const [currentWidth, setCurrentWidth] = useState(getWidthSafely());
  const [currentBreakpointName, setCurrentBreakpointName] = useState(
    getBreakpointName(currentWidth)
  );
  const [isDesktop, setIsDesktop] = useState(isWidthOfDesktop(currentWidth));

  useEffect(() => {
    function handleResize() {
      setCurrentWidth(getWidthSafely());
      setCurrentBreakpointName(getBreakpointName(currentWidth));
      setIsDesktop(isWidthOfDesktop(currentWidth));
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { children } = props;

  return (
    <BreakpointContext.Provider
      value={{
        currentWidth,
        currentBreakpointName,
        isDesktop,
      }}
    >
      {children}
    </BreakpointContext.Provider>
  );
};

const useCurrentWidth = () => {
  return React.useContext(BreakpointContext).currentWidth;
};

const useCurrentBreakpointName = () => {
  return React.useContext(BreakpointContext).currentBreakpointName;
};

const useIsDesktop = () => {
  return React.useContext(BreakpointContext).isDesktop;
};

BreakpointProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {
  BreakpointProvider,
  useCurrentWidth,
  useCurrentBreakpointName,
  useIsDesktop,
};
