// @flow
import styled, { css } from 'styled-components';

import { Flex } from '../../ui-utils';
import GenericButton from './GenericButton';

const IconButton = styled(GenericButton)`
  border-radius: 50%;
  min-width: 42px;
  height: 42px;
  width: 42px;
  border: 0px solid ${props => props.theme.colors.default};
  //background: ${props => props.theme.colors.default};
  padding: 0;
  margin: 0;
  outline: none;
  &:focus,
  &:hover,
  &:visited {
    outline: none;
  }
  ${Flex('row nowrap', 'center', 'center')};
  position: relative;
  span.meta {
    position: absolute;
    width: auto;
    border: 1px solid ${props => props.theme.colors.red};
    background: ${props => props.theme.colors.red};
    color: white;
    border-radius: 25%;
    height: 16px;
    min-width: 16px;
    font-size: 11px;
    top: 0px;
    right: 0px;
    line-height: 16px;
  }
  ${props =>
    props.withText &&
    css`
      min-width: 88px;
      width: auto;
      border-radius: 4px;
      svg {
        margin-right: 0.5rem;
      }
      svg * {
        fill: white !important;
      }
    `};
`;

export default IconButton;
