// @flow
import 'react-app-polyfill/stable';
import 'url-search-params-polyfill';
import 'stylesheets/animate.css';
import 'fonts/style.css';
import 'app.generated.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import {REACT_APP_SENTRY} from 'utils/constants';

import ComposedApp from './app';
import * as serviceWorker from './serviceWorker';

const appRoot = document.getElementById('root');

if (appRoot) {
  ReactDOM.render(<ComposedApp />, appRoot);
}

Sentry.init({
  dsn: REACT_APP_SENTRY,
});

serviceWorker.register({
  onUpdate: registration => {
    console.log('* => On service worker update called....');
    if (registration && registration.waiting) {
      console.log('* => Registration waiting...', JSON.stringify(registration));
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  },
});
