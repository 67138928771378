import {
  FETCH_ORDERS,
  FETCH_ORDERS_FAILED,
  FETCH_ORDERS_SUCCESSFUL,
  SET_REVERSE_PICKUP,
  SET_REVERSE_PICKUP_FAILED,
  SET_REVERSE_PICKUP_SUCCESSFUL,
} from './constants';

export function fetchOrders(referenceId) {
  return {
    type: FETCH_ORDERS,
    payload: {
      referenceId,
    },
  };
}

export function fetchOrdersSuccessful(data) {
  return {
    type: FETCH_ORDERS_SUCCESSFUL,
    data,
  };
}

export function fetchOrdersFailed(error) {
  return {
    type: FETCH_ORDERS_FAILED,
    error,
  };
}

export function setReversePickup(referenceId, shouldRequestDelivery) {
  return {
    type: SET_REVERSE_PICKUP,
    payload: {
      referenceId,
      shouldRequestDelivery
    }
  };
}

export function setReversePickupSuccessful(data) {
  return {
    type: SET_REVERSE_PICKUP_SUCCESSFUL,
    data,
  };
}

export function setReversePickupFailed(error) {
  return {
    type: SET_REVERSE_PICKUP_FAILED,
    error,
  };
}
