import { css } from 'styled-components';

export const Flex = (flow = 'row nowrap', ai = 'center', jc = 'flex-start') => {
  return css`
    display: flex;
    flex-flow: ${flow};
    align-items: ${ai};
    justify-content: ${jc};
  `;
};

export const MediaQ = breakPoint => {
  return (...args) => {
    return css`
      @media screen and (min-width: ${breakPoint}) {
        ${css(...args)};
      }
    `;
  };
};

export const MediaQL = breakPoint => {
  return (...args) => {
    return css`
      @media screen and (orientation: landscape) and (min-width: ${breakPoint}) {
        ${css(...args)};
      }
    `;
  };
};
