import React, { useState } from 'react';
import { getColumnProps, getRowProps, Grid } from 'react-flexbox-grid';
import styled, { css } from 'styled-components';
import { Flex, MediaQ } from 'ui-utils';

export const PageWrapper = styled(Grid)`
  width: 100%;
  scroll-behavior: smooth;
  max-width: 100% !important;
  ${props =>
    props.fullWidth &&
    css`
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: 100% !important;
    `}
`;

export const CtaWrapper = styled.div`
  padding: 0.5rem;
  position: fixed;
  ${props => (props.bottomNav && css ? `bottom: 54px;` : props.affiliatePartner ? `bottom: 56px;` : `bottom: 0;`)}
  left: 0;
  display: flex;
  flex: 0 0 100%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  z-index: 10;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.125);
  ${MediaQ('768px')`
    display: none;
`};
`;

export const ColWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props =>
    props.justifyContent && `justify-content: ${props.justifyContent};`}
`;

export const MobileOnlyWrapper = styled.div`
  display: none;
  @media only screen and (max-device-width: 480px) {
    display: block;
    width: 100%;
  }
`;

export const NotMobileWrapper = styled.div`
  display: none;
  @media only screen and (min-device-width: ${props =>
      props.minWidth || '480px'}) {
    display: block;
  }
`;

export const StickyMobileWrapper = styled.div`
  // position: sticky;
  bottom: ${props => props.affiliatePartner ? '56px' : '0px'};
  left: 0;
  right: 0;
  height: 48px;
  display: none;
  @media only screen and (max-device-width: 480px) {
    display: flex;
    width: 100%;
  }
`;

export const ModalWrapper = styled.div`
  height: ${props => (props.height ? props.height : '100px')};
  width: ${props => (props.width ? props.width : '300px')};
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  min-width: 40%;
  padding: 3rem 1rem;
`;

export const SimpleHero = styled.div`
  background-image: url(${props => props.backgroundImage});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
  min-height: 400px;
  width: 100%;
  ${Flex('column nowrap', 'center', 'center')}
`;

export const GridWrapper = styled(Grid)`
  ${props =>
    props.fullWidth &&
    css`
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: 100% !important;
    `}
  max-width: 100% !important;
  padding-top: ${props => props.paddingTop ?? '0'} !important;
  padding-bottom: ${props => props.paddingBottom ?? '0'} !important;
  ${props =>
    props.border &&
    css`
      border: ${props.border ?? '1px solid transparent'};
    `}
`;

export const CheckoutPageWrapper = styled.div`
  width: 100%;
  background-color: #f3f4f5;
  padding: ${props => props.theme.layout.headerHeight} 0 1rem;
  min-height: 100vh;
  position: relative;
  .paymentOption {
    ${Flex('row wrap', 'center', 'space-between')}
    height: 100%;
  }
  .paymentOptionDetails {
    width: 100%;
    line-height: 1;
    span,
    img {
      display: block;
    }
    span {
      width: 100%;
    }
    span {
      font-size: 12px;
      color: #666;
      margin-bottom: 0.5rem;
    }
    img {
      height: 16px;
      width: auto;
      display: inline-block;
    }
  }

  .paymentOptionDetails.disabled {
    opacity: 0.45;
  }

  .test {
    .cta-button {
      display: none;
      ${MediaQ('768px')`
        display: flex;
      `}
    }
  }
`;

export const CustomWrapper = styled.div`
  width: 100%;
  background-color: #f3f4f5;
  min-height: 100vh;
`;

const wrapperTestStyles = props =>
  props.test
    ? css`
        border: 1px solid red;
      `
    : null;

export const CustomGridWrapper = styled(Grid)`
  ${wrapperTestStyles};
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%;
  max-width: 100% !important;
  ${MediaQ('992px')` 
  .container {
      width: 100% !important;
    }
  `};
`;

export const CustomColumnWrapper = styled.div`
  ${wrapperTestStyles};
  ${props =>
    props.xsMargin &&
    css`
      margin: ${props.xsMargin} !important;
    `}
  ${props =>
    props.xsPadding &&
    css`
      padding: ${props.xsPadding} !important;
    `}
`;

export const CustomRowWrapper = styled.div`
  margin-left: 0 !important;
  margin-right: 0 !important;
  ${wrapperTestStyles};
`;

export const CustomCol = props => {
  const colProps = getColumnProps(props);
  return (
    <CustomColumnWrapper {...colProps}>{props.children}</CustomColumnWrapper>
  );
};

export const CustomRow = props => {
  const rowProps = getRowProps(props);
  return <CustomRowWrapper {...rowProps}>{props.children}</CustomRowWrapper>;
};

export const ScreenWrapper = styled.div`
  ${props =>
    props.hasHeader &&
    css`
      padding-top: ${props.theme.layout.headerHeight};
    `}
`;
