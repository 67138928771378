import {
  LOAD_STORES,
  LOAD_STORES_FAILURE,
  LOAD_STORES_SUCCESS,
  RELOAD_STORES,
  RELOAD_STORES_FAILURE,
  RELOAD_STORES_SUCCESS,
  TOGGLE_LOADING_STATE,
  UPDATE_SCROLL_POSITION,
} from './constants';

export const loadStoresAction = ({ shopName, token }) => ({
  type: LOAD_STORES,
  payload: {
    shopName,
    token,
  },
});

export const reloadStoresAction = ({
  pageNo,
  shopName,
  totalStores,
  token,
  virtualOffset,
}) => ({
  type: RELOAD_STORES,
  payload: {
    pageNo,
    shopName,
    totalStores,
    token,
    virtualOffset,
  },
});

export const toggleLoadingState = ({ loadingState }) => ({
  type: TOGGLE_LOADING_STATE,
  payload: {
    loadingState,
  },
});

export const loadStoreSuccessAction = response => ({
  type: LOAD_STORES_SUCCESS,
  payload: {
    ...response,
  },
});

export const loadStoreFailureAction = response => ({
  type: LOAD_STORES_FAILURE,
  payload: {
    ...response,
  },
});

export const reloadStoreSuccessAction = response => ({
  type: RELOAD_STORES_SUCCESS,
  payload: {
    ...response,
  },
});

export const reloadStoreFailureAction = response => ({
  type: RELOAD_STORES_FAILURE,
  payload: {
    ...response,
  },
});

export const updateScrollPositionAction = ({ scrollTop }) => ({
  type: UPDATE_SCROLL_POSITION,
  payload: {
    scrollTop,
  },
});
