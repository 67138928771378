import { Placeholder } from 'components/Cards/styles';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import styled from 'styled-components';

import { Wrapper } from './styles';

const HeaderButtonsLoading = styled.div``;

const LoadingHeader = () => {
  // console.log('Loading Header...');
  return (
    <Wrapper>
      <div className="fluid-height">
        <Grid fluid className="fluid-height">
          <Row className="fluid-height">
            <Col xs={4} md={2} lg={2} className="flex centered">
              <Placeholder height="50px" width="100%" />
            </Col>

            <Col
              xsOffset={5}
              xs={3}
              sm={3}
              md={2}
              mdOffset={2}
              lg={2}
              lgOffset={2}
              className="flex centered end"
            >
              <Placeholder height="50px" width="50px" circle />
              <HeaderButtonsLoading />
            </Col>
          </Row>
        </Grid>
      </div>
    </Wrapper>
  );
};

export default LoadingHeader;
