export const FETCH_PRODUCT_DETAILS = 'PRODUCT/FETCH_PRODUCT_DETAILS';
export const FETCH_SIMILAR_PRODUCTS = 'PRODUCT/FETCH_SIMILAR_PRODUCTS';
export const PRODUCT_DETAILS_FETCH_SUCCESSFUL =
  'PRODUCT/PRODUCT_DETAILS_FETCH_SUCCESSFUL';
export const PRODUCT_DETAILS_FETCH_ERRORED =
  'PRODUCT/PRODUCT_DETAILS_FETCH_ERRORED';
export const SIMILAR_PRODUCTS_FETCH_SUCCESSFUL =
  'PRODUCT/SIMILAR_PRODUCTS_FETCH_SUCCESSFUL';
export const SIMILAR_PRODUCTS_FETCH_ERRORED =
  'PRODUCT/SIMILAR_PRODUCTS_FETCH_SUCCESSFUL';
export const UPDATE_SIMILAR_PRODUCTS = 'PRODUCT/UPDATE_SIMILAR_PRODUCTS';
export const UPDATE_SIMILAR_PRODUCTS_SUCCESSFUL =
  'PRODUCT/UPDATE_SIMILAR_PRODUCTS_SUCCESSFUL';
