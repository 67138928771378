import { captureException } from '@sentry/react';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API_BASE_URL } from 'utils/constants';
import requestForm from 'utils/requestForm';

import {
  loadingFailed,
  loadingSuccess,
  reloadingFailed,
  reloadingSuccess,
  toggleLoading,
  toggleReloading,
} from './actions';
import { LOAD_REVIEW_IMAGES, RELOAD_REVIEW_IMAGES } from './constants';

export function* loadReviewImages({ payload }) {
  const requestUrl = `${API_BASE_URL}/product/reviewimages`;

  yield put(toggleLoading(true));
  try {
    const { productId } = payload;

    const body = `productId=${productId}&pageNo=1`;
    const data = yield call(requestForm, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    });

    if (data.status === 'success') {
      data.pageNo = 1;
      yield put(loadingSuccess(data));
    } else {
      yield put(loadingFailed(data));
    }

    yield put(toggleLoading(false));
  } catch (err) {
    yield put(toggleLoading(false));
    captureException(new Error(err));
  }
}

export function* reloadReviewImages({ payload }) {
  const { pageNo, productId } = payload;

  const requestUrl = `${API_BASE_URL}/product/reviewimages`;

  yield put(toggleReloading(true));
  try {
    const body = `productId=${productId}&pageNo=${pageNo}`;
    const data = yield call(requestForm, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    });

    if (data.status === 'success') {
      if (data.productRatingImages && data.productRatingImages.length > 0) {
        data.pageNo = pageNo;
      } else {
        data.pageNo = null;
      }

      yield put(toggleReloading(false));
      yield put(reloadingSuccess(data));
    } else {
      yield put(toggleReloading(false));
      yield put(reloadingFailed(data));
    }
  } catch (err) {
    yield put(toggleReloading(false));
    yield put(reloadingFailed({ mssg: err.mssg }));
    captureException(new Error(err));
  }
}

export function* watchLoadReviewImages(payload) {
  yield takeLatest(LOAD_REVIEW_IMAGES, loadReviewImages);
}

export function* watchReloadReviewImages(payload) {
  yield takeLatest(RELOAD_REVIEW_IMAGES, reloadReviewImages);
}
