import {
  CLEAR_SHOP_DATA,
  RELOAD_HOME_PAGE_FEED,
  UPDATE_HOME_PAGE_FEED_PRODUCT,
  UPDATE_SHOPLANDING_PRODUCTS,
} from 'utils/constants';

export const reloadHomeFeedAction = ({
  homeFeedPageNo,
  shopName,
  token,
  virtualOffset,
  totalProducts,
}) => ({
  type: RELOAD_HOME_PAGE_FEED,
  payload: {
    homeFeedPageNo,
    shopName,
    token,
    virtualOffset,
    totalProducts,
  },
});

export const updateHomeFeedProductsAction = payload => ({
  type: UPDATE_HOME_PAGE_FEED_PRODUCT,
  payload,
});

export const updateShopLandingAction = payload => ({
  type: UPDATE_SHOPLANDING_PRODUCTS,
  payload,
});

export const clearShopDataAction = () => ({
  type: CLEAR_SHOP_DATA,
});

export const updateScrollPositionAction = ({ scrollTop }) => ({
  type: 'shop/UPDATE_SCROLL_POSITION',
  payload: {
    scrollTop,
  },
});
