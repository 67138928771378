// import { connectRouter } from 'connected-react-router';
import { produce } from 'immer';
import { combineReducers } from 'redux';
import addressReducer from 'screens/Address/reducer';
import cartReducer from 'screens/Cart/reducer';
import categoriesReducer from 'screens/Categories/reducer';
import shopReducer from 'screens/Home/reducer';
// import { homepageStates } from 'screens/HomeNew';
import ordersReducer from 'screens/MyOrders/reducer';
import paymentReducer from 'screens/Payment/reducer';
import productReducer from 'screens/Product/reducer';
import productReviewImagesReducer from 'screens/ProductReviewImages/reducer';
import productReviewsReducer from 'screens/ProductReviews/reducer';
import orderStatusReducer from 'screens/ReversePickupStatus/reducer';
import { signInStates } from 'screens/SignInVerification';
import storeReducer from 'screens/StoreProducts/reducer';
import storesReducer from 'screens/Stores/reducer';
import walletReducer from 'screens/Wallet/reducer';
import {
  HIDE_TOAST_SUCCESS,
  HOMEPAGE_STATES,
  LOAD_USER_STATE_FAILED,
  LOAD_USER_STATE_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SET_SIGNIN_PHONE,
  SET_IS_WEBVIEW,
  RESET_SHOP_LOADING_INITIAL_STATE_SUCCESS,
  SET_HOME_FIRST_LOAD,
  SET_HOME_LOADING_STATE,
  SET_LISTING_FIRST_LOAD,
  SET_LOADING_STATE,
  SET_SCROLL_POSITION_SUCCESS,
  SET_SHOW_PRODUCT_BOTTOM_SHEET,
  SET_SHOW_PRODUCT_BOTTOM_SHEET_FAILURE,
  SET_SHOW_PRODUCT_BOTTOM_SHEET_SUCCESS,
  SHOW_TOAST_SUCCESS,
  TOGGLE_ATTRIBUTES_FILTER_SUCCESS,
  TOGGLE_PAGE_LOADING,
  TOGGLE_SHOP_LOADING,
  TOGGLE_SHOP_RELOADING,
  TOGGLE_SIDEBAR,
  TOGGLE_SIGN_IN_LOADER,
  TOGGLE_SORT,
  TOGGLE_OPEN_WHATSAPP,
  SET_APP_SECRETS,
} from 'utils/constants';

const initialUserState = {
  userState: {
    cartCount: 0,
    shopList: [],
    isAuthenticated: false,
    affiliatePartner: null,
    isLoggedOut: false,
    profileDetails: {},
    glance: {},
    whatsAppMobile: null,
    isWhatsapp: false,
    signinPhone: null,
  },
  error: undefined,
  sort: 'recency',
  showLoader: false,
  showHome: true,
  showFreeCodDelivery: true,
  codAvailable: true,
  freeShipping: true,
  currentPage: 'unset',
  slow: false,
  isWebview: false,
};

const initialUIState = {
  isShowSidebar: false,
  isShowAttributesFilter: false,
  isPageLoading: true,
  isShowSort: false,
  isShopLoading: true,
  isShopReloading: false,
  loadingState: 'loading',
  scrollPos: 0,
  isShowProductBottomSheet: false,
  selectedProduct: null,
  isShowToast: false,
  homeFirstLoadState: false,
  listingFirstLoadState: false,
  homeLoadingState: HOMEPAGE_STATES.loading,
  isSigningIn: signInStates.LOADING,
  openWhatsapp: false,
  secrets: {},
};

// User Reducer
export const userReducer = produce((draft, action) => {
  const { payload, type, phone, isWebview } = action;

  switch (type) {
    case LOAD_USER_STATE_SUCCESS: {
      // console.log('load user state...', payload);
      // console.log('Payload', payload);
      if (payload.user) {
        // console.log(payload);
        draft.userState = payload.user;
        draft.userState.isLoggedOut = false;
        draft.userState.cartCount = payload.cartCount;
        draft.userState.shopList = payload.shops;
      } else {
        draft.userState.cartCount = payload.cartCount;
        draft.userState.shopList = payload.shops;
      }
      draft.userState.profileDetails = payload.profileDetails;
      if(payload.glance){
        draft.userState.glance = payload.glance;
      }
      return draft;
    }

    case LOAD_USER_STATE_FAILED: {
      return draft;
    }

    case LOGIN_SUCCESS: {
      draft.userState = payload.user;
      draft.userState.cartCount = payload.cartCount;
      draft.userState.shopList = payload.shops;
      draft.userState.isLoggedOut = false;
      return draft;
    }

    case LOGOUT_SUCCESS: {
      draft.userState.isAuthenticated = false;
      draft.userState.isLoggedOut = true;
      return draft;
    }

    case SET_SIGNIN_PHONE: {
      draft.userState.signinPhone = phone;
      return draft;
    }

    case SET_IS_WEBVIEW: {
      draft.isWebview = isWebview;
      return draft;
    }

    default:
      return draft;
  }
}, initialUserState);

// Ui Reducer
export const uiReducer = produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_SIDEBAR:
      draft.isShowSidebar = payload.isShowSidebar;
      return draft;

    case RESET_SHOP_LOADING_INITIAL_STATE_SUCCESS: {
      draft.homeFirstLoadState = false;
      return draft;
    }

    // eslint-disable-next-line no-fallthrough
    case TOGGLE_ATTRIBUTES_FILTER_SUCCESS:
      draft.isShowAttributesFilter = payload.isShowAttributesFilter;
      return draft;

    // eslint-disable-next-line no-fallthrough
    case TOGGLE_PAGE_LOADING:
      draft.isPageLoading = payload.isPageLoading;
      return draft;

    // eslint-disable-next-line no-fallthrough
    case TOGGLE_SORT:
      draft.isShowSort = payload.isShowSort;
      return draft;

    // eslint-disable-next-line no-fallthrough
    case TOGGLE_SHOP_LOADING:
      draft.isShopLoading = payload.isShopLoading;
      return draft;

    case TOGGLE_SHOP_RELOADING:
      draft.isShopReloading = payload.isShopReloading;
      return draft;

    case SET_SCROLL_POSITION_SUCCESS: {
      draft.scrollPos = payload.scrollPos;
      return draft;
    }

    case SET_SHOW_PRODUCT_BOTTOM_SHEET: {
      draft.isShowProductBottomSheet = payload.isShowProductBottomSheet;
      draft.selectedProduct = payload.selectedProduct;
      return draft;
    }

    case SET_SHOW_PRODUCT_BOTTOM_SHEET_SUCCESS: {
      return draft;
    }

    case SET_SHOW_PRODUCT_BOTTOM_SHEET_FAILURE: {
      return draft;
    }

    case SET_LOADING_STATE: {
      draft.loadingState = payload.loadingState;
      return draft;
    }

    case SET_HOME_LOADING_STATE: {
      draft.homeLoadingState = payload.homeLoadingState;
      return draft;
    }

    case SET_HOME_FIRST_LOAD: {
      draft.homeFirstLoadState = payload.homeFirstLoadState;
      return draft;
    }

    case SET_LISTING_FIRST_LOAD: {
      draft.listingFirstLoadState = payload.listingFirstLoadState;
      return draft;
    }

    case SHOW_TOAST_SUCCESS: {
      draft.isShowToast = true;
      return draft;
    }

    case HIDE_TOAST_SUCCESS: {
      draft.isShowToast = false;
      return draft;
    }

    case TOGGLE_SIGN_IN_LOADER: {
      draft.isSigningIn = payload.isSigningIn;
      return draft;
    }

    case TOGGLE_OPEN_WHATSAPP: {
      draft.openWhatsapp = payload.openWhatsapp;
      return draft;
    }

    case SET_APP_SECRETS: {
      draft.secrets = payload.secrets;
      return draft;
    }

    default:
      return draft;

    // no default
  }
}, initialUIState);

const createReducers = () => {
  return combineReducers({
    user: userReducer,
    shop: shopReducer,
    product: productReducer,
    cart: cartReducer,
    address: addressReducer,
    ui: uiReducer,
    payment: paymentReducer,
    orderStatus: orderStatusReducer,
    orders: ordersReducer,
    productReviews: productReviewsReducer,
    productReviewImages: productReviewImagesReducer,
    categories: categoriesReducer,
    stores: storesReducer,
    store: storeReducer,
    wallet: walletReducer,
  });
};

export default createReducers;
