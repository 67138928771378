import {
  LOAD_STORE,
  LOAD_STORE_FAILURE,
  LOAD_STORE_SUCCESS,
  RELOAD_STORE,
  RELOAD_STORE_FAILURE,
  RELOAD_STORE_SUCCESS,
  TOGGLE_LOADING_STATE,
  UPDATE_SCROLL_POSITION,
  UPDATE_STORE_PRODUCT,
} from './constants';

export const loadStoreAction = ({ shopName, storeId, token }) => ({
  type: LOAD_STORE,
  payload: {
    shopName,
    storeId,
    token,
  },
});

export const reloadStoreAction = ({ pageNo, shopName, storeId, token }) => ({
  type: RELOAD_STORE,
  payload: {
    pageNo,
    shopName,
    storeId,
    token,
  },
});

export const toggleLoadingState = ({ loadingState }) => ({
  type: TOGGLE_LOADING_STATE,
  payload: {
    loadingState,
  },
});

export const loadStoreSuccessAction = response => ({
  type: LOAD_STORE_SUCCESS,
  payload: {
    ...response,
  },
});

export const loadStoreFailureAction = response => ({
  type: LOAD_STORE_FAILURE,
  payload: {
    ...response,
  },
});

export const reloadStoreSuccessAction = response => ({
  type: RELOAD_STORE_SUCCESS,
  payload: {
    ...response,
  },
});

export const reloadStoreFailureAction = response => ({
  type: RELOAD_STORE_FAILURE,
  payload: {
    ...response,
  },
});

export const updateScrollPositionAction = ({ scrollTop }) => ({
  type: UPDATE_SCROLL_POSITION,
  payload: {
    scrollTop,
  },
});

export const updateStoreProductAction = payload => ({
  type: UPDATE_STORE_PRODUCT,
  payload,
});
