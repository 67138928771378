import produce from 'immer';

import {
  GET_WALLET_TRANSACTIONS_SUCCESSFUL,
  GET_WALLET_TRANSACTIONS_FAILURE,
  RELOAD_WALLET_TRANSACTIONS_SUCCESSFUL,
  RELOAD_WALLET_TRANSACTIONS_FAILURE,
  SET_WALLET_LOADING_STATE,
} from './constants';

const initialState = {
  walletTransactionsList: [],
  userWallet: {},
  walletTransactionsError: '',
  walletLoadingState: 'loading',
};

const walletReducer = produce((draft, action) => {
  switch (action.type) {
    case GET_WALLET_TRANSACTIONS_SUCCESSFUL: {
      draft.walletTransactionsList = action.data.walletTransactionsList;
      draft.userWallet = action.data.userWallet;
      break;
    }
    case GET_WALLET_TRANSACTIONS_FAILURE: {
      draft.walletTransactionsError = action.error;
      break;
    }
    case RELOAD_WALLET_TRANSACTIONS_SUCCESSFUL: {
      action.data.walletTransactionsList.forEach(wallet =>
        draft.walletTransactionsList.push(wallet)
      );
      break;
    }
    case RELOAD_WALLET_TRANSACTIONS_FAILURE: {
      draft.walletTransactionsError = action.error;
      break;
    }
    case SET_WALLET_LOADING_STATE: {
      draft.walletLoadingState = action.walletLoadingState;
      break;
    }
  }
}, initialState);

export default walletReducer;
