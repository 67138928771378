import * as Sentry from '@sentry/react';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import addressWatcherSaga from 'screens/Address/saga';
import { updateCartSummary } from 'screens/Cart/actions';
import cartWatcherSaga from 'screens/Cart/saga';
import { watchLoadCategoriesData } from 'screens/Categories/sagas';
import {
  watchLoadShopStateSaga,
  watchNewSetSearchText,
  watchReloadExistingShop,
  watchReloadShopStateSaga,
  watchSetSearchText,
  watchSetSortBy,
  watchToggleAttributesFilter,
  watchToggleSort,
} from 'screens/Home/sagas';
import {
  // watchNewLoadShopStateSaga,
  // watchReloadHomeFeed,
  // watchUpdateHomeFeedProductUpdate,
  // watchUpdateShopLandingProducts,
  homeNewSagaWatcher,
} from 'screens/HomeNew/sagas';
import ordersWatcherSaga from 'screens/MyOrders/sagas';
import otpWatcherSaga from 'screens/Otp/saga';
import paymentWatcherSaga from 'screens/Payment/saga';
import productWatcherSaga from 'screens/Product/saga';
import { watchUpdateListingProducts } from 'screens/ProductListing/sagas';
import {
  watchLoadReviewImages,
  watchReloadReviewImages,
} from 'screens/ProductReviewImages/saga';
import {
  watchLoadReviews,
  watchReloadReviews,
} from 'screens/ProductReviews/sagas';
import orderStatusWatcherSaga from 'screens/ReversePickupStatus/saga';
import storeWatcher from 'screens/StoreProducts/saga';
import storesWatcher from 'screens/Stores/saga';
import walletsWatcherSaga from 'screens/Wallet/sagas';
import {
  API_BASE_URL,
  HIDE_TOAST,
  HIDE_TOAST_SUCCESS,
  LOAD_USER_STATE,
  LOAD_USER_STATE_FAILED,
  LOAD_USER_STATE_SUCCESS,
  LOGIN,
  LOGOUT,
  LOGOUT_SUCCESS,
  NAME_CHANGED_SUCCESSFULLY,
  RESET_SHOP_LOADING_INITIAL_STATE,
  RESET_SHOP_LOADING_INITIAL_STATE_SUCCESS,
  SET_SCROLL_POSITION,
  SET_SCROLL_POSITION_SUCCESS,
  SET_SHOW_PRODUCT_BOTTOM_SHEET,
  SHOW_TOAST,
  SHOW_TOAST_SUCCESS,
  TOGGLE_PRODUCT_BOTTOM_SHEET,
  TOGGLE_SIGN_IN_LOADER,
} from 'utils/constants';
import request from 'utils/request';

import { showPageLoadingAction } from './actions';
import { userProfile } from './selectors';
import {decryptString} from 'utils/intransitUtils';
// import { getPage, getRequestOptionsForPage } from 'utils/helpers';

function* handleUserStateResponse(response, params) {
  // debugger;
  const {
    status,
    profile,
    cartCount,
    shops,
    isAuthenticated,
    profileDetails,
    affiliatePartner,
    affiliatePartnerData,
    whatsAppMobile,
    isWhatsapp,
  } = response;

  if (status === 'success') {
    if (profile) {
      // const { url } = profile;
      params.shopName = profile?.url ? profile?.url.split('/')[1] : '';
      yield put({
        type: LOAD_USER_STATE_SUCCESS,
        payload: {
          user: {
            ...profile,
            isAuthenticated,
            profileDetails,
            affiliatePartner: affiliatePartner || null,
            glance: affiliatePartnerData || {},
            whatsAppMobile: whatsAppMobile || null,
            isWhatsapp,
          },
          cartCount: cartCount || 0,
          shops: shops || [],
          profileDetails: profileDetails || {},
        },
      });
    } else {
      // console.log('user state success with profile!', profile);
      yield put({
        type: LOAD_USER_STATE_SUCCESS,
        payload: {
          cartCount: cartCount || 0,
          shops: shops || [],
          profileDetails: profileDetails || {},
          user: {
            isAuthenticated,
            affiliatePartner,
            glance: params.glance || {},
            whatsAppMobile,
            isWhatsapp,
          },
        },
      });
    }
    yield put(updateCartSummary({ itemCount: cartCount }));
  } else {
    console.log('user state fetch failed!');
    yield put({
      type: LOAD_USER_STATE_FAILED,
    });
  }
}

// function* forceLoginUser({ payload }) {
//   // debugger;
//   console.log({ payload });

//   const options = {
//     headers: {
//       'content-type': 'application/json',
//       apiVersion: '5',
//     },
//     method: 'POST',
//     body: JSON.stringify({
//       buyerMobile: payload.phone,
//       token: payload.token,
//     }),
//   };

//   const url = `${API_BASE_URL}/rest/user/validateToken`;

//   try {
//     const response = yield call(request, url, options);
//     yield put(showPageLoadingAction({ isPageLoading: false }));
//     if (response.result === 'success') {
//       yield* handleUserStateResponse(payload.userStateData, payload.params);
//     } else {
//       console.log('Force Login failed', response);
//       alert('force login failed ', response);
//       // yield put({
//       //   type: LOAD_USER_STATE_FAILED,
//       // });
//     }
//   } catch (error) {
//     yield put(showPageLoadingAction({ isPageLoading: false }));
//     alert('force login request failed');
//     console.log('ERROR in force Login', error);
//     Sentry.captureException(error);
//     // yield put({
//     //   type: LOAD_USER_STATE_FAILED,
//     // });
//   }
// }

export function* loadUserState({ payload }) {
  // debugger;
  const { params } = payload;
  yield put(showPageLoadingAction({ isPageLoading: true }));

  const requestOptions = {
    method: 'get',
    headers: {
      'content-type': 'application/json',
    },
    url: `${API_BASE_URL}/user/state`,
  };

  try {
    const apiStartTime = yield call(Date.now);


    const encData =  yield call(
      request,
      requestOptions.url,
      requestOptions
    );
    const userStateData = decryptString(encData);

    const apiEndTime = yield call(Date.now);
    console.log(
      'user state api latency',
      `${(apiEndTime - apiStartTime) / 1000}s`
    );

    const {
      status,
      profile,
      cartCount,
      shops,
      isAuthenticated,
      profileDetails,
    } = userStateData;

    if (status === 'success') {
      if (profile?.url) {
        // const { url } = profile;
        params.shopName = profile?.url ? profile?.url.split('/')[1] : '';
        // console.log('user state success with profile!', profile);
        yield put({
          type: LOAD_USER_STATE_SUCCESS,
          payload: {
            user: {
              ...profile,
              isAuthenticated,
              profileDetails,
            },
            cartCount: cartCount || 0,
            shops: shops || [],
            profileDetails: profileDetails || {},
          },
        });
        yield put(updateCartSummary({ itemCount: cartCount || 0 }));
      } else {
        // console.log('user state success with profile!', profile);
        yield put({
          type: LOAD_USER_STATE_SUCCESS,
          payload: {
            cartCount: cartCount || 0,
            shops: shops || [],
            profileDetails: profileDetails || {},
            user: {
              isAuthenticated,
            },
          },
        });
        yield put(updateCartSummary({ itemCount: cartCount || 0 }));
      }
    } else {
      console.log('user state fetch failed!');
      yield put({
        type: LOAD_USER_STATE_FAILED,
      });

      return;
    }

    yield put(showPageLoadingAction({ isPageLoading: false }));
    yield* handleUserStateResponse(userStateData, params);
    // if (status === 'success') {
    //   if (profile) {
    //     // const { url } = profile;
    //     params.shopName = profile?.url ? profile?.url.split('/')[1] : '';
    //     // console.log('user state success with profile!', profile);
    //     yield put({
    //       type: LOAD_USER_STATE_SUCCESS,
    //       payload: {
    //         user: {
    //           ...profile,
    //           isAuthenticated,
    //           profileDetails,
    //         },
    //         cartCount: cartCount || 0,
    //         shops: shops || [],
    //         profileDetails: profileDetails || {},
    //       },
    //     });
    //     yield put(updateCartSummary({ itemCount: cartCount }));
    //   } else {
    //     // console.log('user state success with profile!', profile);
    //     yield put({
    //       type: LOAD_USER_STATE_SUCCESS,
    //       payload: {
    //         cartCount: cartCount || 0,
    //         shops: shops || [],
    //         profileDetails: profileDetails || {},
    //         user: {
    //           isAuthenticated,
    //         },
    //       },
    //     });
    //     yield put(updateCartSummary({ itemCount: cartCount }));
    //   }
    // } else {
    //   console.log('user state fetch failed!');
    //   yield put({
    //     type: LOAD_USER_STATE_FAILED,
    //   });
    // }
  } catch (error) {
    yield put(showPageLoadingAction({ isPageLoading: false }));
    console.log('ERROR in loadUserState', error);
    Sentry.captureException(error);
    yield put({
      type: LOAD_USER_STATE_FAILED,
    });
  }
}

function* watchLoadUserState() {
  yield takeLatest(LOAD_USER_STATE, loadUserState);
}

function* setScrollPosition({ payload }) {
  yield put({
    type: SET_SCROLL_POSITION_SUCCESS,
    payload: {
      scrollPos: payload.scrollPos,
    },
  });
}

function* watchSetScrollPosition() {
  yield takeLatest(SET_SCROLL_POSITION, setScrollPosition);
}

function* toggleProductBottomSheet({ payload }) {
  // load product data & display bottom sheet
  yield put({
    type: SET_SHOW_PRODUCT_BOTTOM_SHEET,
    payload,
  });
}

function* watchShowProductBottomSheet() {
  yield takeLatest(TOGGLE_PRODUCT_BOTTOM_SHEET, toggleProductBottomSheet);
}

function* showToast() {
  yield put({
    type: SHOW_TOAST_SUCCESS,
  });
}

function* hideToast() {
  yield put({
    type: HIDE_TOAST_SUCCESS,
  });
}

function* watchShowToast() {
  yield takeLatest(SHOW_TOAST, showToast);
}

function* watchHideToast() {
  yield takeLatest(HIDE_TOAST, hideToast);
}

function* resetShopLoadingInitialState() {
  yield put({
    type: RESET_SHOP_LOADING_INITIAL_STATE_SUCCESS,
  });
}

function* watchResetShopLoadingInitialState() {
  yield takeEvery(
    RESET_SHOP_LOADING_INITIAL_STATE,
    resetShopLoadingInitialState
  );
}

function* watchLogin() {
  yield put({
    type: TOGGLE_SIGN_IN_LOADER,
    payload: {
      isSigningIn: true,
    },
  });
  // yield console.log('watch Login');
  yield takeEvery(LOGIN, loadUserState);
  yield put({
    type: TOGGLE_SIGN_IN_LOADER,
    payload: {
      isSigningIn: false,
    },
  });

  // const user = yield select(userProfile);
  // window.clevertap.profile.push({
  //   Site: {
  //     Identity: localStorage?.getItem('lqid') ?? '',
  //     Name: user.fullName,
  //     Phone: user.mobile,
  //   },
  // });
  // window.clevertap.event.push('msp login', {
  //   lqid: localStorage?.getItem('lqid') ?? '',
  //   Name: user.fullName,
  //   Phone: user.mobile,
  // });
}

function* logoutUser() {
  // debugger;
  yield put({
    type: TOGGLE_SIGN_IN_LOADER,
    payload: {
      isSigningIn: true,
    },
  });
  const url = `${API_BASE_URL}/rest/user/signout`;
  try {
    const response = yield call(request, url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        apiVersion: '5',
      },
    });

    // yield console.log({ response });
    if (response.result === 'success') {
      yield put({
        type: LOGOUT_SUCCESS,
      });
      // window.clevertap.event.push('msp user logged out');
    } else {
      console.log('logout failed!');
    }
    yield put({
      type: TOGGLE_SIGN_IN_LOADER,
      payload: {
        isSigningIn: false,
      },
    });
  } catch (error) {
    console.log('logout failed', error);
    Sentry.captureException(error);
    yield put({
      type: TOGGLE_SIGN_IN_LOADER,
      payload: {
        isSigningIn: false,
      },
    });
  }
}

function* composeLogout() {
  yield* logoutUser();
  yield* loadUserState({
    payload: { params: { shopName: '/', sortBy: 'recency' } },
  });

  // window.clevertap.event.push('msp logout', {
  //   lqid: localStorage?.getItem('lqid') ?? '',
  // });
}

function* changeUserName({ name, history }) {
  const url = `${API_BASE_URL}/rest/user/profile/edit`;
  const data = { fullName: name };

  try {
    const response = yield call(request, url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        apiVersion: '5',
      },
    });
    if (response.result === 'success') {
      yield* loadUserState({
        payload: { params: { shopName: '/', sortBy: 'recency' } },
      });
      yield call(history.push, '/profile');
    } else {
      console.log('name change failed');
    }
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
}

function* watchLogout() {
  // yield console.log('logging out...');
  yield takeEvery(LOGOUT, composeLogout);
  yield console.log('logged out!');
}

function* watchChangeUserName() {
  yield takeEvery(NAME_CHANGED_SUCCESSFULLY, changeUserName);
}

// function* watchLogin() {
//   yield
// }

// Watchers
function* rootSaga() {
  yield all([
    watchLoadUserState(),
    watchLogin(),
    watchLogout(),
    watchChangeUserName(),
    productWatcherSaga(),
    cartWatcherSaga(),
    addressWatcherSaga(),
    paymentWatcherSaga(),
    otpWatcherSaga(),
    orderStatusWatcherSaga(),
    ordersWatcherSaga(),
    walletsWatcherSaga(),
    watchSetSearchText(),
    watchLoadShopStateSaga(),
    homeNewSagaWatcher(),
    // watchNewLoadShopStateSaga(),
    watchResetShopLoadingInitialState(),
    // watchReloadHomeFeed(),
    // watchUpdateHomeFeedProductUpdate(),
    // watchUpdateShopLandingProducts(),
    watchUpdateListingProducts(),
    watchToggleAttributesFilter(),
    watchToggleSort(),
    watchSetSortBy(),
    watchReloadShopStateSaga(),
    watchLoadReviews(),
    watchReloadReviews(),
    watchLoadReviewImages(),
    watchReloadReviewImages(),
    watchReloadExistingShop(),
    watchSetScrollPosition(),
    watchShowProductBottomSheet(),
    watchNewSetSearchText(),
    watchLoadCategoriesData(),
    watchHideToast(),
    watchShowToast(),
    storesWatcher(),
    storeWatcher(),
  ]);
}

export default rootSaga;
