import Loading from 'components/Loader';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const AppShell = () => {
  const userState = useSelector(({ user }) => user.userState);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [shopLink, setShopLink] = useState('');

  useEffect(() => {
    // console.log('user state is changing...');
    if (userState?.url) {
      setLoading(false);
      setShopLink(userState.url);
    } else if (userState?.shopList?.length > 0) {
      setLoading(false);
      setShopLink(userState?.shopList[0]?.url);
    } else {
      const shopUrl = localStorage.getItem('shopUrl');
      if (shopUrl) {
        setLoading(false);
        setShopLink(userState?.shopList[0]?.url);
      }
    }
  }, [userState?.shopList, userState]);

  if (loading) {
    return <Loading />;
  }

  if (userState?.shopList?.length > 0 && !loading) {
    if (userState?.shopList?.length === 1) {
      console.log('has 1 shop');
      window.location.href = shopLink;
      return null;
    }

    if (userState?.shopList?.length > 1) {
      console.log('has 1 shop');
      console.log(`has ${userState.shopList.length} shops`);
      window.location.href = '/shop-list';
      return null;
    }

    if (userState?.url) {
      console.log('has atleast shop url');
      history.push(userState.url);
      return null;
    }
  }

  if (!userState?.shopList?.length && !userState.url && !loading && shopLink) {
    console.log('has localstorage shop url');
    history.push(shopLink);
    return null;
  }

  if (!userState?.shopList?.length && !userState?.url && !loading) {
    return (
      <div className='min-h-screen w-full px-2 pt-20'>
        <p className='text-center text-sm text-gray-676'>
          You haven&lsquo;t visited any shops recently.
        </p>
        <p>Please visit the shop / product link shared to you.</p>
      </div>
    );
  }

  return null;
};

export default AppShell;
