import produce from 'immer';

import {
  DELETE_ADDRESS,
  FETCH_SAVED_ADDRESSES,
  FETCH_SAVED_ADDRESSES_FAILED,
  FETCH_SAVED_ADDRESSES_SUCCESSFUL,
  GET_ADDRESS,
  GET_CITY_STATE,
  MANAGE_ADDRESS_LOADING,
  SAVE_ADDRESS,
  SELECT_ADDRESS,
  SET_PINCODE_UNSERVICEABLE,
  SET_NAME_ALREADY_EXIST,
  STORE_ADDRESS,
  SET_BUYER_ADDRESS,
} from './constants';

const initialState = {
  savedAddresses: null,
  toBeEdited: {},
  savedAddressesLoading: true,
  savedAddressesErrored: false,
  pincodeUnserviceable: false,
  nameAlreadyExist: '',
  actionLoading: false,
  cancelledList: [],
  allActive: true,
  manageAddressLoading: true,
  buyerAddress: {},
};

const addressReducer = produce((draft, action) => {
  switch (action.type) {
    case FETCH_SAVED_ADDRESSES: {
      draft.savedAddressesLoading = true;
      draft.savedAddressesErrored = false;
      return draft;
    }
    case FETCH_SAVED_ADDRESSES_SUCCESSFUL: {
      // console.log('The only time savedAddresses changed');
      draft.savedAddresses = action.addresses;
      draft.savedAddressesLoading = false;
      draft.actionLoading = false;
      draft.savedAddressesErrored = false;
      return draft;
    }

    case FETCH_SAVED_ADDRESSES_FAILED: {
      draft.savedAddressesLoading = false;
      draft.actionLoading = false;
      draft.savedAddressesErrored = true;
      return draft;
    }

    case SAVE_ADDRESS: {
      draft.actionLoading = true;
      draft.pincodeUnserviceable = false;
      draft.cancelledList = [];

      return draft;
    }

    case DELETE_ADDRESS: {
      draft.actionLoading = true;
      return draft;
    }

    case GET_ADDRESS: {
      draft.actionLoading = true;
      return draft;
    }

    case STORE_ADDRESS: {
      const {
        city,
        email,
        houseNo,
        id,
        landMark,
        mobile,
        name,
        pincode,
        state,
        streetAddress,
        clear,
        clearPin,
      } = action.addresses;
      if (clear) {
        draft.toBeEdited.city = '';
        draft.toBeEdited.email = '';
        draft.toBeEdited.houseNo = '';
        draft.toBeEdited.id = '';
        draft.toBeEdited.landMark = '';
        draft.toBeEdited.mobile = '';
        draft.toBeEdited.name = '';
        draft.toBeEdited.pincode = '';
        draft.toBeEdited.state = '';
        draft.toBeEdited.streetAddress = '';
      } else {
        draft.toBeEdited.city = clearPin ? '' : city || draft.toBeEdited.city;
        draft.toBeEdited.email = email || draft.toBeEdited.email;
        draft.toBeEdited.houseNo = houseNo || draft.toBeEdited.houseNo;
        draft.toBeEdited.id = id || draft.toBeEdited.id;
        draft.toBeEdited.landMark = landMark || draft.toBeEdited.landMark;
        draft.toBeEdited.mobile = mobile || draft.toBeEdited.mobile;
        draft.toBeEdited.name = name || draft.toBeEdited.name;
        draft.toBeEdited.pincode = pincode || draft.toBeEdited.pincode;
        draft.toBeEdited.state = clearPin
          ? ''
          : state || draft.toBeEdited.state;
        draft.toBeEdited.streetAddress =
          streetAddress || draft.toBeEdited.streetAddress;
      }

      return draft;
    }

    case GET_CITY_STATE: {
      draft.actionLoading = true;
      return draft;
    }

    case SET_PINCODE_UNSERVICEABLE: {
      draft.actionLoading = false;
      draft.pincodeUnserviceable = action?.payload?.pincodeUnserviceable;
      draft.cancelledList = action?.payload?.cancelledList;
      draft.allActive = action?.payload?.allActive;
      return draft;
    }

    case SET_NAME_ALREADY_EXIST: {
      draft.actionLoading = false;
      draft.nameAlreadyExist = action?.payload?.nameAlreadyExist ?? '';
      return draft;
    }

    case SELECT_ADDRESS: {
      draft.cancelledList = [];
      draft.pincodeUnserviceable = false;
      return draft;
    }

    case SET_BUYER_ADDRESS: {
      if(action.payload.name) {
        Object.assign(draft.buyerAddress,{...action.payload});
      } else {
        draft.buyerAddress = {};
      }
      return draft;
    }

    case MANAGE_ADDRESS_LOADING: {
      draft.manageAddressLoading = action.payload.manageAddressLoading;
      return draft;
    }
    // no default
  }
}, initialState);

export default addressReducer;
