import { put, takeEvery } from 'redux-saga/effects';
import {
  UPDATE_LISTING_PRODUCTS,
  UPDATE_LISTING_PRODUCTS_SUCCESS,
} from 'utils/constants';

function* updateListingProducts({ payload }) {
  yield put({
    type: UPDATE_LISTING_PRODUCTS_SUCCESS,
    payload,
  });
}

export function* watchUpdateListingProducts() {
  yield takeEvery(UPDATE_LISTING_PRODUCTS, updateListingProducts);
}
