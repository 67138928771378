// @flow
import styled, { css } from 'styled-components';

import GenericButton from './GenericButton';

export const FullWidthButton = styled(GenericButton)`
  width: 100%;
  ${props =>
    props.onlyMobile &&
    css`
      @media (min-width: 600px) {
        width: auto;
      }
    `}
`;

export default FullWidthButton;
