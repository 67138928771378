import {
  LOAD_REVIEW_IMAGES,
  LOAD_REVIEW_IMAGES_FAILED,
  LOAD_REVIEW_IMAGES_SUCCESS,
  RELOAD_REVIEW_IMAGES,
  RELOAD_REVIEW_IMAGES_FAILED,
  RELOAD_REVIEW_IMAGES_SUCCESS,
  TOGGLE_REVIEW_IMAGES_LOADING,
  TOGGLE_REVIEW_IMAGES_RELOADING,
} from './constants';

export const fetchReviewImages = productId => {
  return {
    type: LOAD_REVIEW_IMAGES,
    payload: {
      productId,
    },
  };
};

export const fetchNextReviewImages = (productId, pageNo) => {
  return {
    type: RELOAD_REVIEW_IMAGES,
    payload: {
      productId,
      pageNo,
    },
  };
};

export const loadingSuccess = data => {
  return {
    type: LOAD_REVIEW_IMAGES_SUCCESS,
    payload: {
      data,
    },
  };
};

export const loadingFailed = data => {
  return {
    type: LOAD_REVIEW_IMAGES_FAILED,
    payload: {
      data,
    },
  };
};

export const toggleLoading = isLoading => {
  return {
    type: TOGGLE_REVIEW_IMAGES_LOADING,
    payload: {
      isLoading,
    },
  };
};

export const reloadingSuccess = data => {
  return {
    type: RELOAD_REVIEW_IMAGES_SUCCESS,
    payload: {
      data,
    },
  };
};

export const reloadingFailed = data => {
  return {
    type: RELOAD_REVIEW_IMAGES_FAILED,
    payload: {
      data,
    },
  };
};

export const toggleReloading = isReloading => {
  return {
    type: TOGGLE_REVIEW_IMAGES_RELOADING,
    payload: {
      isReloading,
    },
  };
};
