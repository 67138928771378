import produce from 'immer';

import {
  ADD_RETURN_IMAGE,
  CLEAR_RETURN_IMAGE,
  DELETE_RETURN_IMAGE,
  GET_CANCEL_ORDER_REASONS_FAILURE,
  GET_CANCEL_ORDER_REASONS_SUCCESSFUL,
  GET_ORDER_DETAILS_FAILURE,
  GET_ORDER_DETAILS_SUCCESSFUL,
  GET_ORDERS_FAILURE,
  GET_ORDERS_SUCCESSFUL,
  GET_RETURN_ORDER_REASONS_FAILURE,
  GET_RETURN_ORDER_REASONS_SUCCESSFUL,
  RELOAD_ORDERS_FAILURE,
  RELOAD_ORDERS_SUCCESSFUL,
  RETURN_ORDER_POPUP_TOGGLE,
  SET_BANK_DETAILS,
  SET_IMAGE_LOADING_STATE,
  SET_ORDER_HELP_DETAILS,
  SET_ORDER_HELP_PROBLEM,
  SET_ORDERS_LOADING_STATE,
} from './constants';

const initialState = {
  ordersList: [],
  statusList: [],
  ordersListError: '',
  ordersLoadingState: 'loading',
  currentOrder: {},
  orderDetailsError: '',
  orderHelpDetails: {
    orderId: '',
    productImage: '',
    purchaseId: '',
  },
  orderHelpProblem: '',
  cancelReasons: [],
  returnReasons: [],
  returnImages: [],
  bankDetails: {},
  returnStatus: false,
  imageLoadingState: false,
};

const ordersReducer = produce((draft, action) => {
  switch (action.type) {
    case GET_ORDERS_SUCCESSFUL: {
      draft.ordersList = action.data.orders;
      draft.statusList = action.data.statusList;
      break;
    }
    case GET_ORDERS_FAILURE: {
      draft.ordersListError = action.error;
      break;
    }
    case RELOAD_ORDERS_SUCCESSFUL: {
      action.data.orders.forEach(order => draft.ordersList.push(order));
      break;
    }
    case RELOAD_ORDERS_FAILURE: {
      draft.ordersListError = action.error;
      break;
    }
    case SET_ORDERS_LOADING_STATE: {
      draft.ordersLoadingState = action.ordersLoadingState;
      break;
    }
    case GET_ORDER_DETAILS_SUCCESSFUL: {
      draft.currentOrder = action.currentOrder;
      break;
    }
    case GET_ORDER_DETAILS_FAILURE: {
      draft.orderDetailsError = action.error;
      break;
    }
    case SET_ORDER_HELP_DETAILS: {
      draft.orderHelpDetails.orderId = action.orderId;
      draft.orderHelpDetails.productImage = action.productImage;
      draft.orderHelpDetails.purchaseId = action.purchaseId;
      break;
    }
    case SET_ORDER_HELP_PROBLEM: {
      draft.orderHelpProblem = action.problem;
      break;
    }

    case GET_CANCEL_ORDER_REASONS_SUCCESSFUL: {
      draft.cancelReasons = action.reasons;
      break;
    }

    case GET_CANCEL_ORDER_REASONS_FAILURE: {
      draft.cancelError = action.error;
      break;
    }

    case GET_RETURN_ORDER_REASONS_SUCCESSFUL: {
      draft.returnReasons = action.reasons;
      break;
    }

    case GET_RETURN_ORDER_REASONS_FAILURE: {
      draft.returnError = action.error;
      break;
    }

    case SET_BANK_DETAILS: {
      draft.bankDetails = action.payload;
      break;
    }

    case ADD_RETURN_IMAGE: {
      console.log(action.fileData, action.returnOrderImageId);
      draft.returnImages.push({
        returnOrderImageId: action.returnOrderImageId,
        fileData: action.fileData,
      });
      break;
    }

    case DELETE_RETURN_IMAGE: {
      draft.returnImages = draft.returnImages.filter(
        item => item.returnOrderImageId !== action.returnOrderImageId
      );
      break;
    }

    case CLEAR_RETURN_IMAGE: {
      draft.returnImages = [];
      break;
    }

    case SET_IMAGE_LOADING_STATE: {
      draft.imageLoadingState = action.imageLoadingState;
      break;
    }

    case RETURN_ORDER_POPUP_TOGGLE: {
      draft.returnStatus = action.returnStatus;
      break;
    }
  }
}, initialState);

export default ordersReducer;
