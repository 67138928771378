import styled, { css } from 'styled-components';

const baseTextStyles = css`
  ${props => props.primary && `color: ${props.theme.colors.red};`}
  ${props => props.isSecondary && `color: ${props.theme.secondary.fontColor};`}
  ${props =>
    props.isDisabled && `color: ${props.theme.primary.disabledFontColor};`}
  ${props => props.color && `color: ${props.color};`}
  ${props => props.fontSize && `font-size: ${props.fontSize};`}
  ${props => props.lineHeight && `line-height: ${props.lineHeight};`}
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
  ${props =>
    props.ultraLight && `font-weight: ${props.theme.fontWeights.ultraLight};`}
  ${props => props.light && `font-weight: ${props.theme.fontWeights.light};`}
  ${props =>
    props.semiLight && `font-weight: ${props.theme.fontWeights.semiLight};`}
  ${props => props.normal && `font-weight: ${props.theme.fontWeights.normal};`}
  ${props =>
    props.semiBold && `font-weight: ${props.theme.fontWeights.semiBold};`}
  ${props => props.bold && `font-weight: ${props.theme.fontWeights.bold};`}
  ${props =>
    props.ultraBold && `font-weight: ${props.theme.fontWeights.extraBold};`}
  ${props => props.center && `text-align: center;`}
  ${props => props.margin && `margin: ${props.margin};`}
`;

export const baseHeaderStyles = css`
  line-height: 1.618;
  word-spacing: 2px;
  ${baseTextStyles};
`;

export const SpanText = styled.span`
  font-size: 1rem;
  ${baseTextStyles}
`;

export const Text = styled.p`
  font-size: 1rem;
  ${baseTextStyles}
`;

export const HeaderText = styled.h1`
  ${baseTextStyles};
  line-height: 1.618;
  word-spacing: 2px;
  color: ${props => props.color ?? '#2e2e2e'};
  span.blue {
    color: ${props => props.theme.colors.facebookBlue};
  }
`;

export const H1 = styled.h1`
  font-size: 4rem;
  ${baseHeaderStyles}
`;

export const H2 = styled.h2`
  font-size: 3rem;
  ${baseHeaderStyles}
`;

export const H3 = styled.h3`
  font-size: 2.4rem;
  ${baseHeaderStyles}
`;

export const ProductHeading = styled.h1`
  font-size: 2rem;
  ${baseTextStyles}
  font-weight: ${props => props.theme.fontWeights.semiBold};
`;

export const ProductDescription = styled.span`
  font-size: 1.2rem;
  ${baseTextStyles}
  font-weight: ${props => props.theme.fontWeights.normal}
`;

export const ProductCardTitle = styled.h3`
  ${baseTextStyles};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.618;
  margin-bottom: 0.5rem;
`;
export const ProductCardDescription = styled.p`
  ${baseTextStyles};
  font-size: 20px;
  color: #2b3240;
  font-weight: ${props =>
    props.theme.fontWeights.semiBold
      ? props.theme.fontWeights.semiBold
      : 'bold'};
`;

export const SectionHeader = styled(H3)`
  font-size: 20px;
  font-weight: ${props => props.theme.fontWeights.semiBold};
  ${baseTextStyles};
  margin: 1rem 0;
  color: #2e2e2e;
`;
