import {
  ADD_RETURN_IMAGE,
  CANCEL_ORDER,
  CLEAR_RETURN_IMAGE,
  DELETE_RETURN_IMAGE,
  DELETE_RETURN_ORDER_IMAGE,
  GET_BANK_DETAILS,
  GET_CANCEL_ORDER_REASONS,
  GET_CANCEL_ORDER_REASONS_FAILURE,
  GET_CANCEL_ORDER_REASONS_SUCCESSFUL,
  GET_ORDER_DETAILS,
  GET_ORDER_DETAILS_FAILURE,
  GET_ORDER_DETAILS_SUCCESSFUL,
  GET_ORDERS,
  GET_ORDERS_FAILURE,
  GET_ORDERS_SUCCESSFUL,
  GET_RETURN_ORDER_REASONS,
  GET_RETURN_ORDER_REASONS_FAILURE,
  GET_RETURN_ORDER_REASONS_SUCCESSFUL,
  RELOAD_ORDERS,
  RELOAD_ORDERS_FAILURE,
  RELOAD_ORDERS_SUCCESSFUL,
  RETURN_ORDER,
  RETURN_ORDER_POPUP_TOGGLE,
  SAVE_COD_REFUND_DETAILS,
  SET_BANK_DETAILS,
  SET_IMAGE_LOADING_STATE,
  SET_ORDER_HELP_DETAILS,
  SET_ORDER_HELP_PROBLEM,
  SET_ORDERS_LOADING_STATE,
  UPLOAD_RETURN_ORDER_IMAGE,
} from './constants';

export function getOrders() {
  return {
    type: GET_ORDERS,
  };
}

export function getOrdersSuccessful({ data }) {
  return {
    type: GET_ORDERS_SUCCESSFUL,
    data,
  };
}

export function getOrdersFailure({ error }) {
  return {
    type: GET_ORDERS_FAILURE,
    error,
  };
}

export function reloadOrders({ lastOrderId }) {
  return {
    type: RELOAD_ORDERS,
    lastOrderId,
  };
}

export function reloadOrdersSuccessful({ data }) {
  return {
    type: RELOAD_ORDERS_SUCCESSFUL,
    data,
  };
}

export function reloadOrdersFailure({ error }) {
  return {
    type: RELOAD_ORDERS_FAILURE,
    error,
  };
}

export function setOrdersLoadingState({ ordersLoadingState }) {
  return {
    type: SET_ORDERS_LOADING_STATE,
    ordersLoadingState,
  };
}

export function getOrderDetails({ orderId, return: isReturn }) {
  return {
    type: GET_ORDER_DETAILS,
    orderId,
    isReturn,
  };
}

export function getOrderDetailsSuccessful({ currentOrder }) {
  return {
    type: GET_ORDER_DETAILS_SUCCESSFUL,
    currentOrder,
  };
}

export function getOrderDetailsFailure({ error }) {
  return {
    type: GET_ORDER_DETAILS_FAILURE,
    error,
  };
}

export function setOrderHelpDetails({ orderId, productImage, purchaseId }) {
  return {
    type: SET_ORDER_HELP_DETAILS,
    orderId,
    productImage,
    purchaseId,
  };
}

export function setOrderHelpProblem({ problem }) {
  return {
    type: SET_ORDER_HELP_PROBLEM,
    problem,
  };
}

export function getCancelOrderReasons() {
  return {
    type: GET_CANCEL_ORDER_REASONS,
  };
}

export function getCancelOrderReasonsSuccessful({ reasons }) {
  return {
    type: GET_CANCEL_ORDER_REASONS_SUCCESSFUL,
    reasons,
  };
}

export function getCancelOrderReasonsFailure({ error }) {
  return {
    type: GET_CANCEL_ORDER_REASONS_FAILURE,
    error,
  };
}

export function getReturnOrderReasons({orderId}) {
  return {
    type: GET_RETURN_ORDER_REASONS,
    orderId,
  };
}

export function getReturnOrderReasonsSuccessful({ reasons }) {
  return {
    type: GET_RETURN_ORDER_REASONS_SUCCESSFUL,
    reasons,
  };
}

export function getReturnOrderReasonsFailure({ error }) {
  return {
    type: GET_RETURN_ORDER_REASONS_FAILURE,
    error,
  };
}

export function cancelOrder({ orderId, reasonCode }) {
  return {
    type: CANCEL_ORDER,
    orderId,
    reasonCode,
  };
}

export function returnOrder({ orderId, reasonCode }) {
  return {
    type: RETURN_ORDER,
    orderId,
    reasonCode,
  };
}

export function returnOrderPopupToggle({ returnStatus }) {
  return {
    type: RETURN_ORDER_POPUP_TOGGLE,
    returnStatus,
  };
}

export function uploadReturnOrderImage({ payload }) {
  return {
    type: UPLOAD_RETURN_ORDER_IMAGE,
    payload,
  };
}

export function addReturnImage({ returnOrderImageId, fileData }) {
  return {
    type: ADD_RETURN_IMAGE,
    returnOrderImageId,
    fileData,
  };
}

export function deleteReturnImage({ returnOrderImageId }) {
  return {
    type: DELETE_RETURN_IMAGE,
    returnOrderImageId,
  };
}

export function clearReturnImage() {
  return {
    type: CLEAR_RETURN_IMAGE,
  };
}

export function setImageLoadingState({ imageLoadingState }) {
  return {
    type: SET_IMAGE_LOADING_STATE,
    imageLoadingState,
  };
}

export function deleteReturnOrderImage({ returnOrderImageId }) {
  return {
    type: DELETE_RETURN_ORDER_IMAGE,
    returnOrderImageId,
  };
}

export function setBankDetails({ payload }) {
  return {
    type: SET_BANK_DETAILS,
    payload,
  };
}

export function getBankDetails() {
  return {
    type: GET_BANK_DETAILS,
  };
}

export function saveCODRefundDetails({ payload, orderId, reasonCode, random }) {
  return {
    type: SAVE_COD_REFUND_DETAILS,
    payload,
    orderId,
    reasonCode,
    random,
  };
}
