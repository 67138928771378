import produce from 'immer';
import { CLEAR_CART } from 'utils/constants';

import {
  ADD_PRODUCT,
  ADD_PRODUCT_FAILED,
  ADD_PRODUCT_SUCCESSFUL,
  FETCH_ITEMS,
  FETCH_ITEMS_FAILED,
  FETCH_ITEMS_SUCCESSFUL,
  UPDATE_CART_SUMMARY,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_FAILED,
  UPDATE_PRODUCT_SUCCESSFUL,
} from './constants';

const initialState = {
  codAvailable: null,
  codUnAvailableText: '',
  shoppingList: {
    shoppingListItems: [],
  },
  banner: '',
  itemCount: null,
  fetchLoading: true,
  fetchError: null,
  actionLoading: false,
  actionError: null,
  createToast: false,
};

// TODO Set correct error messages
const cartReducer = produce((draft, action) => {
  const { type, data } = action;

  switch (type) {
    case FETCH_ITEMS: {
      draft.fetchLoading = true;
      draft.fetchError = null;
      draft.createToast = false;
      break;
    }
    case FETCH_ITEMS_SUCCESSFUL: {
      // debugger;
      // console.log('FETCH_ITEMS_SUCCESSFUL =============>', action.data);

      // console.log(action);
      if (data) {
        draft.last_cod_order_present = data.last_cod_order_present;
        draft.last_cod_order_id = data.last_cod_order_id;
      }

      if (data?.banner) {
        draft.banner = data.banner;
      }

      if (data && data?.shoppingCart?.shoppingList) {
        draft.shoppingList = data.shoppingCart.shoppingList;
        draft.itemCount = data.shoppingCart.itemCount;
        draft.codAvailable = data.shoppingCart.codAvailable;
        draft.codUnAvailableText = data.shoppingCart.codUnAvailableText;
        draft.fetchLoading = false;
        draft.fetchError = null;
      } else if (data?.shoppingCart?.itemCount) {
        draft.itemCount = data.shoppingCart.itemCount;
        draft.fetchLoading = false;
        draft.fetchError = null;
      } else {
        draft.fetchLoading = false;
        draft.fetchError = 'Error loading cart';
      }
      break;
    }
    case FETCH_ITEMS_FAILED: {
      // console.log('FETCH_ITEMS_FAILED =============>', action.data);
      draft.fetchLoading = false;
      draft.fetchError = data;
      break;
    }
    case ADD_PRODUCT: {
      draft.actionLoading = true;
      draft.actionError = null;
      break;
    }
    case ADD_PRODUCT_SUCCESSFUL: {
      // debugger;
      const { product, variantId, qty, itemCount } = action;
      const item = {
        resellingProduct: product,
        variantId,
        quantity: qty,
      };
      if (draft.shoppingList)
        if (draft.shoppingList.shoppingListItems) {
          draft.shoppingList.shoppingListItems.push(item);
        } else {
          draft.shoppingList.shoppingListItems = [item];
        }
      draft.shoppingList.totalValue += product.buyerPrice * qty;
      draft.shoppingList.itemCount = itemCount;
      draft.actionLoading = false;
      draft.actionError = null;
      draft.createToast = true;
      break;
    }
    case ADD_PRODUCT_FAILED: {
      // console.log('ADD_PRODUCT_FAILED =============>', action);
      draft.actionLoading = false;
      draft.actionError = 'Error message';
      break;
    }
    case UPDATE_PRODUCT: {
      draft.actionLoading = true;
      draft.actionError = null;
      break;
    }
    case UPDATE_PRODUCT_SUCCESSFUL: {
      // console.log('UPDATE_PRODUCT_SUCCESSFUL==========>', action);
      if (action.data && action.data.shoppingList) {
        draft.shoppingList = action.data.shoppingList;
        draft.itemCount = action.data.itemCount;
        draft.codAvailable = action.data.codAvailable;
        draft.codUnAvailableText = action.data.codUnAvailableText;
        draft.actionLoading = false;
        draft.actionError = null;
      } else {
        draft.actionError = 'Error loading cart';
      }
      break;
    }
    case UPDATE_PRODUCT_FAILED: {
      // console.log('UPDATE_PRODUCT_FAILED==========>', action);
      draft.actionLoading = false;
      draft.actionError = 'Error message';
      break;
    }
    case UPDATE_CART_SUMMARY: {
      // console.log('UPDATE_CART_SUMMARY =============>', action.shoppingCart);
      if (action.shoppingCart) {
        if (
          !(
            typeof action.shoppingCart.totalValue === 'undefined' ||
            action.shoppingCart.totalValue === null
          )
        ) {
          draft.shoppingList.totalValue = action.shoppingCart.totalValue;
        }
        if (
          !(
            typeof action.shoppingCart.totalProductValue === 'undefined' ||
            action.shoppingCart.totalProductValue === null
          )
        ) {
          draft.shoppingList.totalProductValue =
            action.shoppingCart.totalProductValue;
        }
        if (
          !(
            typeof action.shoppingCart.totalShippingCharges === 'undefined' ||
            action.shoppingCart.totalShippingCharges === null
          )
        ) {
          draft.shoppingList.totalShippingCharges =
            action.shoppingCart.totalShippingCharges;
        }
        if (action.shoppingCart.shoppingListItems) {
          draft.shoppingList.shoppingListItems =
            action.shoppingCart.shoppingListItems;
        }
        if (action.shoppingCart.walletDiscount) {
          draft.shoppingList.walletDiscount =
            action.shoppingCart.walletDiscount;
        }
      }
      draft.shoppingList.itemCount = action.shoppingCart.itemCount;
      draft.codAvailable = action.shoppingCart.codAvailable;
      draft.codUnAvailableText = action.shoppingCart.codUnAvailableText;
      draft.itemCount = action.shoppingCart.itemCount;
      break;
    }

    case CLEAR_CART: {
      draft = initialState;
      return draft;
    }
  }
}, initialState);

export default cartReducer;
