export const FETCH_SAVED_ADDRESSES = 'ADDRESS/SAVED_ADDRESSES';
export const FETCH_SAVED_ADDRESSES_SUCCESSFUL =
  'ADDRESS/SAVED_ADDRESSES_SUCCESSFUL';
export const FETCH_SAVED_ADDRESSES_FAILED =
  'ADDRESS/FETCH_SAVED_ADDRESSES_FAILED';

export const MANAGE_ADDRESS_LOADING = 'address/MANAGE_ADDRESS_LOADING';

export const SAVE_ADDRESS = 'ADDRESS/SAVE_ADDRESS';
export const EDIT_ADDRESS = 'ADDRESS/EDIT_ADDRESS';
export const SAVE_CHECKOUT_ADDRESS = 'ADDRESS/SAVE_CHECKOUT_ADDRESS';
export const DELETE_ADDRESS = 'ADDRESS/DELETE_ADDRESS';
export const GET_ADDRESS = 'ADDRESS/GET_ADDRESS';
export const STORE_ADDRESS = 'ADDRESS/STORE_ADDRESS';
export const SELECT_ADDRESS = 'ADDRESS/SELECT_ADDRESS';
export const SAVE_ADDRESS_AND_PROCEED = 'ADDRESS/SAVE_ADDRESS_AND_PROCEED';
export const SELECT_ADDDRESS_SUCCESSFUL = 'ADDRESS/SELECT_ADDDRESS_SUCCESSFUL';
export const SELECT_ADDDRESS_FAILED = 'ADDRESS/SELECT_ADDDRESS_FAILED';

export const SET_PINCODE_UNSERVICEABLE = 'ADDRESS/SET_PINCODE_UNSERVICEABLE';
export const SET_NAME_ALREADY_EXIST = 'ADDRESS/SET_NAME_ALREADY_EXIST';
export const GET_CITY_STATE = 'ADDRESS/GET_CITY_STATE';

export const SET_BUYER_ADDRESS = 'ADDRESS/SET_BUYER_ADDRESS';
