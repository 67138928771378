import styled from 'styled-components';
import { IconButton } from 'ui-components/Buttons';
import { Flex, MediaQ } from 'ui-utils';

export const Wrapper = styled.div`
  ${Flex('row nowrap', 'center', 'flex-start')};
  background: ${props => props.theme.colors.timerBackground};
  height: 20px;
  font-size: 11px;
  z-index: 1063;
  ${MediaQ('372px')`
    font-size: 12px;
  `}
  ${MediaQ('768px')`
    height: 25px;
    font-size: 13px;
    max-width: 100%;
    &.fixed-width {
      max-width: 200px;
    }
  `}

  ${props => props.hide && `display: none;`}
`;

export const Button = styled(IconButton)`
  background: ${props => props.theme.colors.ratingsBad};
  border-radius: 4px;
  height: 20px;
  padding: 0rem;
  min-width: 20px;
  width: 20px;
  color: white;

  ${MediaQ('768px')`
    height: 25px;
    width: 25px;
  `}
`;

export const Timer = styled.div`
  width: auto;
  text-align: left;
  padding: 0 0.25rem;
`;

export const TimerText = styled.p`
  font-weight: 11px;
  font-weight: 700;
  color: white;
  ${MediaQ('372px')`
    font-size: 12px;
  `}
  ${MediaQ('768px')`
   font-weight: 13px;
`}
`;
