import {
  FETCH_PRODUCT_DETAILS,
  FETCH_SIMILAR_PRODUCTS,
  PRODUCT_DETAILS_FETCH_ERRORED,
  PRODUCT_DETAILS_FETCH_SUCCESSFUL,
  SIMILAR_PRODUCTS_FETCH_ERRORED,
  SIMILAR_PRODUCTS_FETCH_SUCCESSFUL,
  UPDATE_SIMILAR_PRODUCTS,
} from './constants';

/*
  Fetches product details from the the productId
*/
export function fetchProductDetails({ productId, history, params }) {
  return {
    type: FETCH_PRODUCT_DETAILS,
    productId,
    history,
    params,
  };
}

/*
  Fetches similar products from the the productId
*/
export function fetchSimilarProducts({ productId, history }) {
  return {
    type: FETCH_SIMILAR_PRODUCTS,
    productId,
    history,
  };
}

export function productDetailsFetchSuccessful(data) {
  // console.log('Product details fetch succesful', data);
  return {
    type: PRODUCT_DETAILS_FETCH_SUCCESSFUL,
    data,
  };
}

export function productDetailsFetchErrored(data) {
  return {
    type: PRODUCT_DETAILS_FETCH_ERRORED,
    data,
  };
}

export function similarProductsFetchSuccessful(data) {
  return {
    type: SIMILAR_PRODUCTS_FETCH_SUCCESSFUL,
    data,
  };
}

export function similarProductsFetchErrored(data) {
  return {
    type: SIMILAR_PRODUCTS_FETCH_ERRORED,
    data,
  };
}

export const updateSimilarProductsAction = payload => ({
  type: UPDATE_SIMILAR_PRODUCTS,
  payload,
});
