// @flow
import styled from 'styled-components';

import {
  FacebookBlueButtonStyles,
  RedButtonStyles,
  WhatsappGreenButtonStyles,
} from './styles';

const GenericButton = styled.button`
  display: block;
  min-width: 88px;
  border-radius: 4px;
  border: 1px solid ${props => props && props.theme.colors.defaultBorderColor};
  background-color: none;
  height: ${props =>
    props && props.size && props.size === 'large' ? '50px' : '36px'};
  padding: 0 1rem;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 700;
  text-transform: capitalize;
  word-spacing: 2px;
  letter-spacing: 0.25px;
  outline: none;
  text-align: center;
  cursor: pointer;
  line-height: ${props => (props && props.size === 'large' ? '50px' : '36px')};
  ${props => props && props.color === 'red' && RedButtonStyles}
  ${props =>
    props && props.color === 'whatsappGreen' && WhatsappGreenButtonStyles}
  ${props =>
    props && props.color === 'facebookBlue' && FacebookBlueButtonStyles}
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1%;
    height: 1%;
    border: 2px solid #0003;
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
  }
  // &:focus:not(:active)::after {
  //   animation: ripple .5s ease-out;
  // }
`;

export default GenericButton;
