// @flow
import styled from 'styled-components';
import { Flex } from 'ui-utils';

import GenericButton from './GenericButton';

const CircularButton = styled(GenericButton)`
  width: 36px;
  height: 36px;
  boxshadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
  ${Flex('row nowrap', 'center', 'center')}
  border-radius: 50%;
  border-color: ${props => (props.selected ? 'red' : 'inherit')};
`;

export default CircularButton;
