// @flow

import 'typeface-open-sans';
import 'sanitize.css';

import { BreakpointProvider } from 'components/BreakpointProvider';
import ErrorBoundary from 'components/ErrorBoundary';
import { store } from 'config';
import React, { Suspense, useLayoutEffect } from 'react';
import { Provider } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';
import { ThemeProvider } from 'styled-components';
import { useLocalStorage } from 'utils/helpers';
import { v4 as uuidv4 } from 'uuid';

import AppRouter from './router';
import { GlobalStyle } from './styles';
import { theme } from './theme/index';
import InitializeComponent from 'components/InitializeComponent';


// export const { clevertap } = window;

// store.subscribe(() => console.log('Redux State', store.getState()));

const Toast = React.lazy(() => import('components/NewToast'));

const ComposedApp = () => {
  // const { user_auth_token, version } = useAppDataHook(window.location);
  const { get: getStorageItem, set: setStorageItem } = useLocalStorage();
  useLayoutEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const handleInstallEvent = event => {
    // Prevent Chrome <= 67 from automatically showing the prompt
    event.preventDefault();
    console.log('before install prompt event called!');

    window.isPWA = false;
    window.hasBeforeInstallPrompt = true;
    window.installPromptEvent = event;
    // debugger;
    if (window.localStorage?.getItem('install-data')) {
      debugger;
      window.localStorage.removeItem('install-data');
    }
  };

  useLayoutEffect(() => {
    window.isPWA = window.matchMedia('(display-mode: standalone)').matches;
    window.hasBeforeInstallPrompt = false;
    window.addEventListener('beforeinstallprompt', handleInstallEvent);
    return () => {
      window.removeEventListener('beforeinstallprompt', handleInstallEvent);
    };
  }, []);

  useLayoutEffect(() => {
    if (window.localStorage !== undefined && window.localStorage !== null) {
      let lqid = getStorageItem('lqid');

      if (!lqid) {
        lqid = uuidv4();
        setStorageItem('lqid', lqid);
        // clevertap.event.push('msp new user visit', {
        //   uniqueId: lqid,
        // });
      } else {
        // clevertap.event.push('msp existing user visit', {
        //   uniqueId: lqid,
        // });
      }
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div id='appMain' data-time={new Date()}>
          <GlobalStyle />
          <BreakpointProvider>
            <ErrorBoundary component={() => <div>Loading...</div>}>
              <InitializeComponent />
              <AppRouter />
              <Suspense fallback={<div className='toast-wrapper' />}>
                <Toast />
              </Suspense>
            </ErrorBoundary>
          </BreakpointProvider>
        </div>
      </ThemeProvider>
    </Provider>
  );
};

export default ComposedApp;
