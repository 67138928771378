import { CLEAR_CART } from 'utils/constants';

import {
  ADD_PRODUCT,
  ADD_PRODUCT_FAILED,
  ADD_PRODUCT_SUCCESSFUL,
  FETCH_ITEMS,
  FETCH_ITEMS_FAILED,
  FETCH_ITEMS_SUCCESSFUL,
  // UPDATE_ALL_PRODUCTS,
  UPDATE_CART_SUMMARY,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_FAILED,
  UPDATE_PRODUCT_SUCCESSFUL,
} from './constants';

export function fetchCartItems() {
  return {
    type: FETCH_ITEMS,
  };
}

export function fetchItemsSuccessful(data) {
  return {
    type: FETCH_ITEMS_SUCCESSFUL,
    data,
  };
}

export function fetchItemsFailed(data) {
  return {
    type: FETCH_ITEMS_FAILED,
    data,
  };
}

export function addProduct({
  product,
  qty,
  variantId,
  history,
  buyNow,
  from = 'cart',
  position,
  token,
}) {
  return {
    type: ADD_PRODUCT,
    product,
    qty,
    variantId,
    history,
    buyNow,
    from,
    position,
    token
  };
}

export function addProductSuccessful({ product, qty, variantId, itemCount }) {
  return {
    type: ADD_PRODUCT_SUCCESSFUL,
    product,
    qty,
    variantId,
    itemCount,
  };
}

export function addProductFailed(data) {
  return {
    type: ADD_PRODUCT_FAILED,
    data,
  };
}

export function updateProduct({ product, qty, variantId, updateType }) {
  return {
    type: UPDATE_PRODUCT,
    product,
    qty,
    variantId,
    updateType,
  };
}

export function updateProductSuccessful(data) {
  return {
    type: UPDATE_PRODUCT_SUCCESSFUL,
    data,
  };
}

export function updateProductFailed(data) {
  return {
    type: UPDATE_PRODUCT_FAILED,
    data,
  };
}

export function updateCartSummary(shoppingCart) {
  return {
    type: UPDATE_CART_SUMMARY,
    shoppingCart,
  };
}

export function clearCartAction() {
  return {
    type: CLEAR_CART,
  };
}
