/**
 * Gets the repositories of the user from Github
 */

import { call, put, takeLatest } from 'redux-saga/effects';
import { API_BASE_URL } from 'utils/constants';
import request from 'utils/request';

import {
  fetchOrdersFailed,
  fetchOrdersSuccessful,
  setReversePickupFailed,
  setReversePickupSuccessful,
} from './actions';
import { FETCH_ORDERS, SET_REVERSE_PICKUP } from './constants';

// const getOrders = () => {
//   return {
//     awbNo: 'R63832143656AEP',
//     orders: [
//       {
//         productTitle: 'Trendy Alloy Earrings',
//         imgUrl:
//           'https://d1ixo36kppfedg.cloudfront.net/faceview/b5i/gi/jd/a1f/imgs/1571143480518_90087984911208020529-xlg400x400.jpg?productId=P-3231421',
//         createdDate: '11 Nov, 2019',
//         orderId: 367199634,
//         id: 212443533,
//         status: 'Courier Attempted',
//       },
//     ],
//     customerName: 'Ranjan',
//     referenceId: 'wniBQd0omY',
//     status: 'success',
//   };
// };

export function* fetchOrders({ payload }) {
  const requestUrl = `${API_BASE_URL}/orders/reversePickup_attempt/${payload.referenceId}`;
  try {
    const responseData = yield call(request, requestUrl);
    // const responseData = yield(call(getOrders));
    if (responseData.status === 'success') {
      yield put(fetchOrdersSuccessful(responseData));
    } else {
      // console.log("~~~~~~~~~~~ERROR in fetching orders by referenceId", referenceId, '\nResponse data\n: ', responseData);
    }
  } catch (err) {
    // console.log(
    //   '~~~~~~~~~~~~~~~~~~~~~~~~~~Error~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~',
    //   err
    // );
    yield put(fetchOrdersFailed(err));
  }
}

export function* setReversePickupReAttemptDetail({ payload }) {
  const { referenceId, shouldRequestDelivery } = payload;

  const option = shouldRequestDelivery ? 'YES' : 'NO';
  const options = {
    method: 'POST',
    body: JSON.stringify({ referenceId, option }),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const requestURL = `${API_BASE_URL}/order/setReversePickupReAttemptDetail`;
  // const responseData = {"courierAttemptReject":true,"orderId":1109,"status":"success"};
  // const responseData = {"nextCourierAttemptDate":"14 Nov, 2018","status":"success"}

  // debugger;

  try {
    const responseData = yield call(request, requestURL, options);
    // console.log(responseData);
    yield put(setReversePickupSuccessful(responseData));
  } catch (err) {
    console.log('Error', err);
    yield put(setReversePickupFailed(err));
  }
}

export default function* orderStatusWatcherSaga() {
  yield takeLatest(FETCH_ORDERS, fetchOrders);
  yield takeLatest(SET_REVERSE_PICKUP, setReversePickupReAttemptDetail);
}
