import produce from 'immer';
import {
  LOAD_CATEGORIES_FAILURE,
  LOAD_CATEGORIES_SUCCESS,
} from 'screens/Categories/constants';

const initialState = {
  status: 'loading',
  categories: [],
  error: '',
  topProductCategories: [],
  profile: {},
};

export default produce((draft, action) => {
  switch (action.type) {
    case LOAD_CATEGORIES_SUCCESS: {
      draft.profile = action.payload.shop;
      draft.topProductCategories = action.payload.topProductCategories;
      draft.categories = action.payload.filters.categories;
      draft.virtualOffset = action.payload.virtualOffset;
      draft.status = 'success';
      return draft;
    }

    case LOAD_CATEGORIES_FAILURE: {
      draft.status = 'failure';
      return draft;
    }

    default: {
      return draft;
    }
  }
}, initialState);
