import styled from 'styled-components';
import { GenericButton } from 'ui-components/Buttons';
import { Flex } from 'ui-utils';

export const Wrapper = styled.div`
  ${Flex('row nowrap', 'center', 'flex-start')}
  span {
    font-size: ${props => props.theme.fontSizes.md};
    font-weight: ${props => props.theme.fontWeights.bold};
    color: ${props => props.theme.colors.defaultDarkText};
    text-transform: capitalize;
  }
`;

export const Button = styled(GenericButton)`
  border: none
  min-width: 48px;
  height: 48px;
  padding: 0;
`;
