import * as Sentry from '@sentry/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => {
  const userState = useSelector(({ user }) => user?.userState);
  const isAuthenticated = !!userState?.isAuthenticated;
  // const shopUrl = userState?.url ?? '/';
  const shopUrl = localStorage ? localStorage.getItem('prevRoute') : userState?.url ?? '/';

  return (
    <Route
      {...rest}
      render={routeProps => {
        try {
          if (Component) {
            return !isAuthenticated ? (
              <Component {...routeProps} />
            ) : (
                <Redirect to={shopUrl} />
              );
          }
        } catch (ex) {
          console.log(ex);
          Sentry.captureException(ex);
        }
      }

      }
    />
  );
};

export default PublicRoute;
