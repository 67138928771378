/**
 * Gets the repositories of the user from Github
 */

import { call, put, select, takeLatest } from 'redux-saga/effects'; //eslint-disable-line
import request from 'utils/request';
import requestForm from 'utils/requestForm';
import { API_BASE_URL } from 'utils/constants';
import * as Sentry from '@sentry/react';
import { clearCartAction } from 'screens/Cart/actions';
import {setCodSignin} from 'screens/Payment/actions';
import {
  resendOtpSuccessful,
  resendOtpFailed,
  checkOtpFailed,
  checkOtpSuccessful,
} from './actions';

// import { makeSelectProductId } from './selectors';
import { RESEND_OTP, CHECK_OTP } from './constants';

export function* resendOtp() {
  const requestUrl = `${API_BASE_URL}/payment/codresendotp`;
  try {
    const data = yield call(request, requestUrl);
    // console.log('After resending otp: ', data);
    yield put(resendOtpSuccessful(data));
  } catch (err) {
    // console.log(
    //   '~~~~~~~~~~~~~~~~~~~~~~~~~~Error~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~',
    //   err
    // );
    Sentry.captureException(new Error(err));
    yield put(resendOtpFailed(err));
  }
}

export function* checkOtp({ history, ...rest }) {
  // console.log(rest);
  // debugger;
  const requestUrl = `${API_BASE_URL}/payment/authorisecod`;
  try {
    const data = rest?.otp && `otp=${rest.otp}`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data || null,
    };
    const responseData = yield call(requestForm, requestUrl, options);
    // console.log('Otp verified: ', responseData);
    if (responseData.status === 'failed') {
      yield put(checkOtpFailed(responseData.message));
    } else {
      // console.log('SUCCESSFUL RESPONSE===========>\n', responseData);
      yield put(setCodSignin(false));
      yield put(clearCartAction());
      yield put(checkOtpSuccessful(responseData));
      yield call(history.push, { pathname: responseData.redirectUrl });
    }
  } catch (err) {
    // console.log(
    //   '~~~~~~~~~~~~~~~~~~~~~~~~~~Error~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~',
    //   err
    // );
    Sentry.captureException(new Error(err));
    yield put(checkOtpFailed(err));
  }
}

export default function* otpWatcherSaga() {
  yield takeLatest(RESEND_OTP, resendOtp);
  yield takeLatest(CHECK_OTP, checkOtp);
}
