import {
  DELETE_ADDRESS,
  EDIT_ADDRESS,
  FETCH_SAVED_ADDRESSES,
  FETCH_SAVED_ADDRESSES_FAILED,
  FETCH_SAVED_ADDRESSES_SUCCESSFUL,
  GET_ADDRESS,
  GET_CITY_STATE,
  MANAGE_ADDRESS_LOADING,
  SAVE_ADDRESS,
  SAVE_ADDRESS_AND_PROCEED,
  SAVE_CHECKOUT_ADDRESS,
  SELECT_ADDRESS,
  SET_PINCODE_UNSERVICEABLE,
  SET_NAME_ALREADY_EXIST,
  STORE_ADDRESS,
  SET_BUYER_ADDRESS,
} from './constants';

export const setMangeAddressLoading = manageAddressLoading => ({
  type: MANAGE_ADDRESS_LOADING,
  payload: {
    manageAddressLoading,
  },
});

export function fetchSavedAddresses(someCustomerData) {
  return {
    type: FETCH_SAVED_ADDRESSES,
    someCustomerData,
  };
}

export function fetchSavedAddressesSuccessful(addresses) {
  return {
    type: FETCH_SAVED_ADDRESSES_SUCCESSFUL,
    addresses,
  };
}

export function fetchSavedAddressesFailed(error) {
  return {
    type: FETCH_SAVED_ADDRESSES_FAILED,
    error,
  };
}

export function saveAddress({ data, history, random }) {
  return {
    type: SAVE_ADDRESS,
    data,
    history,
    random,
  };
}
export function saveCheckoutAddress({ data, history, directPayment , random}) {
  return {
    type: SAVE_CHECKOUT_ADDRESS,
    data,
    history,
    directPayment,
    random,
  };
}

export function editAddress({ data, history,random }) {
  return {
    type: EDIT_ADDRESS,
    data,
    history,
    random,
  };
}

export function deleteAddress(id) {
  return {
    type: DELETE_ADDRESS,
    id,
  };
}

export function getAddress(id) {
  return {
    type: GET_ADDRESS,
    id,
  };
}

export function storeAddress(addresses) {
  return {
    type: STORE_ADDRESS,
    addresses,
  };
}

export function selectAddress({ id, history }) {
  return {
    type: SELECT_ADDRESS,
    id,
    history,
  };
}

export function saveAddressAndProceed({ data, history }) {
  return {
    type: SAVE_ADDRESS_AND_PROCEED,
    data,
    history,
  };
}

export function setPincodeUnserviceable(payload) {
  return {
    type: SET_PINCODE_UNSERVICEABLE,
    payload,
  };
}

export function setNameAlreadyExist(payload) {
  return {
    type: SET_NAME_ALREADY_EXIST,
    payload,
  };
}

export function getCityAndState(pincode) {
  return {
    type: GET_CITY_STATE,
    pincode,
  };
}

export function setBuyerAddress(payload) {
  return {
    type: SET_BUYER_ADDRESS,
    payload,
  };
}