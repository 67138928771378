import produce from 'immer';
import { UPDATE_TYPES } from 'screens/Cart/constants';

import {
  FETCH_PRODUCT_DETAILS,
  FETCH_SIMILAR_PRODUCTS,
  PRODUCT_DETAILS_FETCH_ERRORED,
  PRODUCT_DETAILS_FETCH_SUCCESSFUL,
  SIMILAR_PRODUCTS_FETCH_ERRORED,
  SIMILAR_PRODUCTS_FETCH_SUCCESSFUL,
  UPDATE_SIMILAR_PRODUCTS_SUCCESSFUL,
} from './constants';

export const initialState = {
  productDetailsLoading: true,
  productDetailsErrored: false,
  similarProductsLoading: true,
  similarProductsErrored: false,
  erroMessage: null,
  productDetails: null,
  similarProducts: null,
};

const productReducer = produce((draft, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_DETAILS: {
      draft.productDetailsLoading = true;
      break;
    }
    case FETCH_SIMILAR_PRODUCTS: {
      draft.similarProductsLoading = true;
      break;
    }
    case PRODUCT_DETAILS_FETCH_SUCCESSFUL: {
      draft.productDetailsErrored = false;
      draft.productDetailsLoading = false;
      // console.log(`LOG ->: productReducer -> action.data`, action.data.product);
      draft.productDetails = action.data.product;
      break;
    }
    case PRODUCT_DETAILS_FETCH_ERRORED: {
      draft.productDetailsErrored = true;
      draft.productDetailsLoading = false;
      draft.productDetails = null;
      break;
    }
    case SIMILAR_PRODUCTS_FETCH_SUCCESSFUL: {
      draft.similarProductsErrored = false;
      draft.similarProductsLoading = false;
      draft.similarProducts = action?.data?.otherProducts ?? [];
      break;
    }
    case SIMILAR_PRODUCTS_FETCH_ERRORED: {
      draft.similarProductsErrored = true;
      draft.similarProductsLoading = false;
      draft.similarProducts = null;
      break;
    }

    case UPDATE_SIMILAR_PRODUCTS_SUCCESSFUL: {
      const { product, updateType } = action.payload;
      const existingProductId = draft?.similarProducts?.findIndex(
        p => p.id === product.id
      );

      if (existingProductId > -1) {
        draft.similarProducts[existingProductId].isProductInCart = !(
          updateType &&
          (updateType === UPDATE_TYPES.REMOVE_ALL_INACTIVE ||
            UPDATE_TYPES.REMOVE)
        );
      }
      return draft;
    }
    // no default
  }
}, initialState);

export default productReducer;
