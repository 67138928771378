import Icon from 'components/Icon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { getPage } from 'utils/helpers';

import { ButtonLink, Navbar, Wrapper } from './styles';

const navLinks = [
  {
    id: 1,
    path: '',
    icon: 'home',
    color: '#9397a3',
    label: 'Home',
    dataIcon: '',
  },
  {
    id: 2,
    path: '',
    icon: 'category',
    color: '#9397a3',
    label: 'Categories',
    dataIcon: '',
  },
  {
    id: 3,
    path: '/myorders',
    icon: 'orders',
    color: '#9397a3',
    label: 'My Orders',
  },
  {
    id: 4,
    path: '/profile',
    icon: 'account',
    color: '#9397a3',
    label: 'Profile',
    dataIcon: '',
  },
];

const BottomNavigation = ({ location }) => {
  const [navigationRoutes, setNavigationRoutes] = useState(navLinks);
  const { userState } = useSelector(({ user }) => user);
  const { profile } = useSelector(({ shop }) => shop);
  const [page, setPage] = useState(null);

  useEffect(() => {
    setPage(getPage(location));
  }, [location]);

  useEffect(() => {
    setNavigationRoutes(prevState => {
      const shopUrl = userState?.url
        ? userState.url
        : profile?.url
        ? profile.url
        : '';

      prevState[0].path = shopUrl;
      prevState[1].path = `${shopUrl}/categories`;
      return prevState;
    });
  }, [userState]);

  return (
    <Navbar id="bottomNav" affiliatePartner={userState.affiliatePartner}>
      <Wrapper>
        <ButtonLink
          to={{
            pathname: navigationRoutes[0].path,
            state: { resetPosition: true },
          }}
          activeClassName='nav-active'
          isActive={() => page === 'newShop'}>
          <Icon
            icon={navigationRoutes[0].icon}
            color={navigationRoutes[0].color}
            size='24px'
          />
          <p>{navigationRoutes[0].label}</p>
        </ButtonLink>
        {navigationRoutes.map(
          (navLink, index) =>
            index > 0 && (
              <ButtonLink
                key={navLink.id}
                activeClassName='nav-active'
                to={{
                  pathname: navLink.path,
                }}
                exact={navLink.exact}>
                <Icon
                  icon={navLink.icon}
                  dataIcon={navLink.dataIcon}
                  color={navLink.color}
                  size='24px'
                />
                <p>{navLink.label}</p>
              </ButtonLink>
            )
        )}
      </Wrapper>
    </Navbar>
  );
};

export default withRouter(BottomNavigation);
