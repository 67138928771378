/**
 * Gets the repositories of the user from Github
 */

import * as Sentry from '@sentry/react';
import { setHomeLoadingState } from 'config/actions';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { toggleProductBottomSheetAction } from 'screens/Home/actions'; //eslint-disable-line
import {
  updateHomeFeedProductsAction,
  updateShopLandingAction,
} from 'screens/HomeNew/actions';
import { updateSimilarProductsAction } from 'screens/Product/actions';
import { updateListingProductsAction } from 'screens/ProductListing/actions';
import { updateStoreProductAction } from 'screens/StoreProducts/actions';
import {
  API_BASE_URL,
  HOMEPAGE_STATES,
  SET_LOADING_STATE,
} from 'utils/constants';
import { getPage } from 'utils/helpers';
import request from 'utils/request';
import requestForm from 'utils/requestForm';

import {
  addProductFailed,
  addProductSuccessful,
  fetchItemsFailed,
  fetchItemsSuccessful,
  updateProductFailed,
  updateProductSuccessful,
} from './actions';
// import { makeSelectProductId } from './selectors';
import {
  ADD_PRODUCT,
  FETCH_ITEMS,
  UPDATE_PRODUCT,
  UPDATE_TYPES,
} from './constants';

export function* fetchCartItems() {
  const url = `${API_BASE_URL}/checkout/cartdetails`;

  try {
    const data = yield call(request, url);
    // debugger;

    if (data.status === 'success') {
      yield put(
        fetchItemsSuccessful({
          shoppingCart: data?.shoppingCart ?? {},
          last_cod_order_present: data?.last_cod_order_present,
          last_cod_order_id: data?.last_cod_order_id,
          banner: data?.banner ?? '',
        })
      );
    } else {
      yield put(fetchItemsFailed('User is unauthorized'));
    }
  } catch (error) {
    Sentry.captureException(new Error(error));
    yield put(fetchItemsFailed(error));
  }
}

export function* addToCart({
  product,
  qty,
  variantId,
  history,
  buyNow,
  from,
  position,
  token,
}) {
  const page = getPage(history.location);
  if (from === 'outside') {
    yield put(toggleProductBottomSheetAction(false, null));
  }

  if (page === 'newShop') {
    yield put(
      setHomeLoadingState({
        homeLoadingState: HOMEPAGE_STATES.loadingWithoutFacet,
      })
    );
  }

  if (page === 'list') {
    yield put({
      type: SET_LOADING_STATE,
      payload: {
        loadingState: 'loadingWithoutFacet',
      },
    });
  }

  const payload = `resellingProductId=${product.id}&quantity=${qty}&variantId=${variantId}&token=${token}`;
  const options = {
    method: 'POST',
    body: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  const url = `${API_BASE_URL}/checkout/addtocart`;
  try {
    const apiResponse = yield call(requestForm, url, options);

    if (apiResponse.status === 'success') {
      yield put(
        addProductSuccessful({
          product,
          qty,
          variantId,
          itemCount: apiResponse.itemCount,
        })
      );

      const newPayload = {
        product,
        qty,
        variantId,
        itemCount: apiResponse.itemCount,
      };

      // yield put(updateShopLandingAction(newPayload));
      yield put(updateHomeFeedProductsAction(newPayload));
      yield put(updateListingProductsAction(newPayload));
      yield put(updateSimilarProductsAction(newPayload));
      yield put(updateStoreProductAction(newPayload));

      // update all array items

      if (page === 'list') {
        yield put({
          type: SET_LOADING_STATE,
          payload: {
            loadingState: 'loaded',
          },
        });
      }
      if (page === 'product') {
        // if (position === 'similarproducts') {
        // }
      }

      if (page === 'newShop') {
        yield put(setHomeLoadingState({ homeLoadingState: 'loaded' }));
      }

      console.log({ newPayload });

      yield call(toast.success, 'Product added to cart', {
        position: toast.POSITION.TOP_CENTER,
        toastId: 'success_toast',
        autoClose: 2000,
      });
    }

    if (buyNow) {
      yield call(history.push, { pathname: '/checkout/cart' });
    }
  } catch (err) {
    if (from === 'outside') {
      yield put(toggleProductBottomSheetAction(false, null));
    }
    console.log(new Error(err));
    Sentry.captureException(new Error(err));
    yield put(addProductFailed(err));
  }
}

export function* updateProductInCart({ product, variantId, qty, updateType }) {
  let data;
  let requestUrl;

  switch (updateType) {
    case UPDATE_TYPES.REMOVE:
      data = {
        itemId: product.itemId,
      };
      requestUrl = `${API_BASE_URL}/checkout/deletefromcart`;
      break;
    case UPDATE_TYPES.SIZE:
      data = {
        itemId: product.itemId,
        variantId,
      };

      requestUrl = `${API_BASE_URL}/checkout/updatesize`;

      break;
    case UPDATE_TYPES.QTY:
      data = {
        itemId: product.itemId,
        quantity: qty,
      };
      requestUrl = `${API_BASE_URL}/checkout/updatequantity`;
      break;
    case UPDATE_TYPES.REMOVE_ALL_INACTIVE:
      data = {};
      requestUrl = `${API_BASE_URL}/checkout/removeositems`;
      break;
    default:
      break;
  }
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const responseData = yield call(request, requestUrl, options);
    yield put(updateProductSuccessful(responseData.shoppingCart));

    const newPayload = {
      product,
      variantId,
      qty,
      updateType,
    };
    yield put(updateShopLandingAction(newPayload));
    yield put(updateHomeFeedProductsAction(newPayload));
    yield put(updateListingProductsAction(newPayload));
    yield put(updateSimilarProductsAction(newPayload));
    yield put(updateStoreProductAction(newPayload));

    if (updateType === UPDATE_TYPES.REMOVE) {
      yield call(toast.error, 'Product removed', {
        position: toast.POSITION.TOP_CENTER,
        toastId: 'product_removed',
        autoClose: 2000,
      });
    }

    if (updateType === UPDATE_TYPES.REMOVE_ALL_INACTIVE) {
      yield call(toast.error, 'Inactive products removed', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }

    if (updateType === UPDATE_TYPES.SIZE) {
      yield call(toast.info, 'Product size updated', {
        position: toast.POSITION.TOP_CENTER,
        toastId: 'size_updated',
        autoClose: 2000,
      });
    }

    if (updateType === UPDATE_TYPES.QTY) {
      yield call(toast.info, 'Product quantity updated', {
        position: toast.POSITION.TOP_CENTER,
        toastId: 'quantity_updated',
        autoClose: 2000,
      });
    }
  } catch (err) {
    Sentry.captureException(new Error(err));
    yield put(updateProductFailed(err));
  }
}

export default function* cartWatcherSaga() {
  yield takeLatest(FETCH_ITEMS, fetchCartItems);
  yield takeLatest(ADD_PRODUCT, addToCart);
  yield takeLatest(UPDATE_PRODUCT, updateProductInCart);
}
