export const LOAD_STORES = 'store/LOAD_STORES';
export const LOAD_STORES_SUCCESS = 'stores/LOAD_STORES_SUCCESS';
export const LOAD_STORES_FAILURE = 'stores/LOAD_STORES_FAILURE';

export const RELOAD_STORES = 'store/RELOAD_STORES';
export const RELOAD_STORES_SUCCESS = 'stores/RELOAD_STORES_SUCCESS';
export const RELOAD_STORES_FAILURE = 'stores/RELOAD_STORES_FAILURE';

export const TOGGLE_LOADING_STATE = 'stores/TOGGLE_LOADING_STATE';

export const UPDATE_SCROLL_POSITION = 'stores/UPDATE_SCROLL_POSITION';

export const pageStates = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  RELOADING: 'RELOADING',
  RELOADED: 'RELOADED',
};
